import ReactDOM from 'react-dom'
import * as _redux from './setup'
import store from './setup/redux/Store'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import {Chart, registerables} from 'chart.js'

import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {Provider} from 'react-redux'

Chart.register(...registerables)

document.body.style.fontFamily = 'SF Pro Display'

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
