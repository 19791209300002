import React, {useState} from "react";


export const CreateAbonement: React.FC<{innerParkings: any, setSelectedInnerParking: any, selectedInnerParking: any  }> = props => {
    console.log(props.innerParkings)
    return (
        <>

                <div className='d-flex flex-column gap-3 mt-10'>
                    {
                        props.innerParkings.map((item: any ) =>  (
                            <div className={`d-flex flex-column w-100 sf_pro_medium `}
                                 onClick={() => props.setSelectedInnerParking(item)}
                                style={{
                                height: '71px',
                                padding: '10px',
                                background: 'white',
                                border: `1px solid ${props.selectedInnerParking === item ? '#157FFF' : '#8193AE'}`,
                                borderRadius: '16px',
                                color: `${!props.selectedInnerParking ? '#051C3F' : props.selectedInnerParking === item ? '#157FFF' : '#8193AE' }`
                            }}>
                                <span>{item.name}</span>
                                <span></span>
                            </div>
                        ))
                    }
                </div>

        </>
    );
};