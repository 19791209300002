import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {setLanguage, useLang} from '../../../_metronic/i18n/Metronici18n'
import globusIcon from '../../assets/globus.png'
import arrowDown from '../../assets/down-arrow.png'
import './Languages.css'


type Props = {
  onLanguage: () => void
}
const Languages: FC<Props> = ({onLanguage}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  const intl = useIntl()
  const lang = useLang()
  console.log("Lang: ", lang)

  // const currentLanguage = languages.find((x: any) => x.lang === lang)

  return (
      <div className="language-picker">
        <button
            className="language-picker__button"
            onClick={onLanguage}
        >
          <img src={globusIcon} />
          <span>{ lang ? lang.toUpperCase() : "ENG"}</span>
          <img src={arrowDown} />

        </button>

      </div>
  )
}

export {Languages}
