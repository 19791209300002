/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import arrowLeft from '../../../assets/ArrowLeft.svg'
import searchIcon from '../../../assets/search_icon.svg'
import emptySearch from '../../../assets/empty-search.png'
import successfulMsg from '../../../assets/successfulMsg.png'
import {useIntl} from "react-intl";
import {Languages} from "../../../modules/languages/Languages";
import {useLang} from "../../../../_metronic/i18n/Metronici18n";
import {getCityList} from "../../../../setup/axios/AxiosParking";
import {
    cityIdAction,
    cityLatAction,
    cityLngAction,
    citySelectAction,
    countryIdAction
} from "../../../../setup/redux/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {useLocation, useNavigate} from "react-router-dom";

interface Props{
    setPrevPage: any
}
export const ShowCityList: FC<Props> = ({setPrevPage}) => {
    const intl = useIntl()
    const lang = useLang()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const state: any = location.state;
    const [showSuccessfulMsg, setShowSuccessfulMsg] = useState(false)
    const [showCityList, setShowCityList] = useState(false)
    const [showIcon, setShowIcon] = useState(false)
    const storeCityId = useSelector((state: RootState) => state.cityId)
    const [filteredItems, setFilteredItems] = useState<{ id: number, name: string, longitude: string, latitude: string, countryId: number, countryCode: string }[]>([]);
    const [selectedCity, setSelectedCity] = useState(0)
    const [cities, setCities] = useState([{id: 6, name: 'New-York', longitude: '-74.168852', latitude: '40.733170', countryId: 2,countryCode: 'US'}])
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        getCityList(lang ? lang : "ru").then((resp) => {
            resp.data.map((item: any) => {
                const {name, id, countryId} = item
                if(localStorage.getItem('city') === name){
                    console.log("City with name " + name + " is already selected")
                    setSelectedCity(id)
                }else if (Number(id) === Number(storeCityId)) {

                    dispatch(citySelectAction(name))
                    dispatch(countryIdAction(countryId))
                }
            })
            const sortedItems = resp.data.sort((a: any, b: any) =>
                a.name.localeCompare(b.name)
            );
            setCities(sortedItems)
            setFilteredItems(sortedItems)
            setShowCityList(true)
        })

    }, []);


    function toggleModal() {
        setShowModal(!showModal);
    }

    const handleSearchTextChange = (event: any) => {
        const filteredItems = cities.filter(item =>
            item.name.toLowerCase().startsWith(event.target.value.toLowerCase())
        );
        setFilteredItems(filteredItems);
        filteredItems.length >= 1  ? setShowCityList(true) : setShowCityList(false)
    };
    const selectCityHandler = (city: any) => {
        dispatch(cityIdAction(city.id.toString()))
        setSelectedCity(city.id)
        dispatch(cityLatAction(city.latitude))
        dispatch(cityLngAction(city.longitude))
        console.log("Selected city: ", city)
        // setShowSuccessfulMsg(true)
    }
    const buttonHandler = () => {
        if(selectedCity && !showSuccessfulMsg && !state){
            setShowSuccessfulMsg(true)
            return null;
        }
        if(!state){
            navigate('/add-car', {state: {fromParkingInfo: false, fromCarsList: false}})
        }
        if(state && state.fromWhere === 'ProfilePage'){
            navigate('/home-page')
        }

    }
    return (
        <>
                <div className={`d-flex flex-column ${showSuccessfulMsg && 'justify-content-between'}`} style={{ height: '100%',padding:' 0 10px'}}>
                    {
                        showSuccessfulMsg ?
                            <>
                                <div className='d-flex justify-content-end'>
                                    {/*<Languages onLanguage={toggleModal} />*/}
                                </div>

                                <div className='d-flex flex-column align-items-center'>
                                    <img src={successfulMsg} />
                                    <div className='d-flex flex-column align-items-center'>
                                        <span className='authorization_title'>{intl.formatMessage({id: 'CITY.SUCCESSFUL'})}</span>
                                        <span className='authorization_description mt-5'>{intl.formatMessage({id: 'CITY.CHANGE'})}</span>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={arrowLeft} style={{width:'15px', height:'21px',position: 'absolute', left: '10px'}}
                                     onClick={() => {
                                            console.log("Type of: ", typeof(setPrevPage))
                                            if(typeof(setPrevPage) === 'string'){
                                                navigate('/home-page', {state: {returnTo: 'ProfilePage'}})
                                            }
                                            setPrevPage(false)
                                        }
                                    } />

                                    <div className='d-flex  align-items-center ' style={{padding:'20px 0'}}>
                                        <span className='back-header-title'>{intl.formatMessage({id: 'CITY.ADD'})}</span>
                                    </div>
                                </div>
                                <div className="search-container" onClick={() => setShowIcon(true)}>
                                    <input type="text" placeholder={intl.formatMessage({id: 'SEARCH'})} className="search-input" onChange={(e) => handleSearchTextChange(e)} style={{backgroundImage: `url(${showIcon ? '' : searchIcon})`}} />
                                </div>

                                <div className={`d-flex flex-column ${!showCityList && 'align-items-center justify-content-center'}`} style={{height:'80%'}} >
                                    {
                                        showCityList ?
                                            <div className='d-flex flex-column' style={{padding: '10px 0'}}>
                                                <span className='add_city_description' style={{color: '#051C3F', marginBottom:'10px'}}>{intl.formatMessage({id: 'CITY.LIST'})}:</span>
                                                {
                                                    filteredItems.map(city =>
                                                            <span className={`${selectedCity === city.id && 'highlighted_color'} city mt-5`}  onClick={() => selectCityHandler(city)}>
                                                        {city.name}
                                                    </span>
                                                    )
                                                }
                                            </div>
                                            :
                                            <div className='d-flex flex-column align-items-center gap-3'>
                                                <img src={emptySearch}  />
                                                <span className='add_city_description'>{intl.formatMessage({id: 'CITY.UNFORTUNATELY'})}</span>
                                            </div>
                                    }

                                </div>
                            </>
                    }
                    <button
                        // className={`${showCityList || showSuccessfulMsg ? 'primary-button' : 'secondary-button'}  mb-3`}
                        className={`${selectedCity ? 'primary-button' : 'secondary-button'} mb-3`}
                        disabled={selectedCity === 0}
                        onClick={() => buttonHandler()}
                    >
                        {showSuccessfulMsg ? intl.formatMessage({id: 'BTNS.CONTINUE'}) : intl.formatMessage({id: 'CITY.CHOOSE'})}
                    </button>

                </div>
        </>

    )
}
