/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Languages} from "../../modules/languages/Languages";
import mainImage from '../../assets/add-city/letUsKnow.png'
import arrowLeft from '../../assets/ArrowLeft.svg'
import {logEvent} from "firebase/analytics";
import {analytics} from "../../App";
import tags from "../../assets/eventTags";
import {
    cityLatAction,
    cityLngAction,
    currentLocationAction, geolocateControlTrigger,
    onboardingAction
} from "../../../setup/redux/actions/actions";
import {useIntl} from "react-intl";
import {ShowCityList} from "./components/ShowCityList";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getCityList} from "../../../setup/axios/AxiosParking";
import {useLang} from "../../../_metronic/i18n/Metronici18n";
// import '../../../index.css'
// type Props = {
//     form?: string,
//     qr?: boolean,
//     city?: any,
//     parkingUid?: any
// }

export const AddCity: FC = () => {
    const intl = useIntl()
    const lang: any = useLang()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(true);
    const [showCityList, setShowCityList] = useState(false)
    function toggleModal() {
        setShowModal(!showModal);
    }
    function handleGeolocation(){
        dispatch(geolocateControlTrigger('triggered'))

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                dispatch(currentLocationAction(position.coords.latitude, position.coords.longitude))
                dispatch(cityLatAction(position.coords.latitude))
                dispatch(cityLngAction(position.coords.longitude))
                getCityList(lang).then((resp: any) => {
                    console.log("Cities: ", resp)
                    resp.data.map((city: any) => {
                        let myLat = position.coords.latitude.toString().substring(0,2)
                        let myLng = position.coords.longitude.toString().substring(0,2)
                        let respLat = city.latitude.substring(0,2);
                        let respLng = city.longitude.substring(0,2);
                        console.log(myLat + " " + respLat + " : " + myLng + " " + respLng)
                        if(myLat === respLat && myLng === respLng){
                            console.log("City of geoloc: ", city)
                            localStorage.setItem('cityId', city.id)
                            navigate('/add-car', {state: {fromParkingInfo: false, fromCarsList: false}})
                        }
                    })
                })

            }, function(positionError){
                alert(`ERROR(${positionError.code}): ${positionError.message}`)
            },  {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            });
        }else {
            alert("Geolocation is not supported by this browser.");
        }
    }
    return (
        <>
            {!showCityList ?
                <div className='d-flex flex-column' style={{ height: '100%',padding:' 0 20px'}}>

                    <div className='d-flex flex-column align-items-center justify-content-between' style={{height:'100%'}}>
                        <img src={mainImage} className='w-300px h-300px mt-15' />
                        <div className='d-flex flex-column' style={{gap:'10px'}}>
                            <span className='authorization_title text-center'>
                                {intl.formatMessage({id: 'CITY.TITLE'})}
                            </span>
                                    <span className='add_city_description text-center'
                                    >
                                {intl.formatMessage({id: 'CITY.DESCRIPTION'})}
                            </span>
                        </div>
                        <div className='d-flex flex-column gap-3' style={{width: '100%'}}>
                            <button className='primary-button' style={{height:'56px'}} onClick={() => handleGeolocation() }>{intl.formatMessage({id: 'CITY.SHARE'})}</button>
                            <button className='manual-button'  onClick={() => setShowCityList(true)}>{intl.formatMessage({id: 'CITY.MANUALLY'})}</button>
                            <span
                                onClick={() => navigate('/home-page')}
                                style={{
                                    color: "#B0B5D0",
                                    fontSize: '16px',
                                    marginBottom: "36px",
                                    marginLeft: "16px",
                                }}

                                className='cursor-pointer me-4 d-flex justify-content-center align-items-center'
                            >
                        {intl.formatMessage({id: 'AUTH.BTN.SKIP'})}
                      </span>
                        </div>
                    </div>
                </div>
                :
                <ShowCityList setPrevPage={setShowCityList} />
            }
        </>
    )
}
