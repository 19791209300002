import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import {RootState} from '../../../../../../../setup'
import {chooseParkingFormAction} from '../../../../../../../setup/redux/actions/actions'
import carIcon from '../../../../../../assets/car.svg'
import cardIcon from '../../../../../../assets/card.svg'

import './ParkingBottomMenuNavigation.css'

const ParkingBottomMenuNavigation: FC = () => {
  const intl = useIntl()

  const dispatch = useDispatch()

  const activeMenu = useSelector((state: RootState) => state.chooseParkingForm)

  return (
    <div className='w-100 h-100 d-flex justify-content-between flex-row ps-3 pe-3'>
      <div
        className={
          activeMenu === 'fastPaymentMenu'
            ? `Menu__navigation_block_active`
            : `Menu__navigation_block_disabled `
        }
        onClick={() => {
          dispatch(chooseParkingFormAction('fastPaymentMenu'))
        }}
      >
        <span className='Menu__navigation_font mb-1'>
          {intl.formatMessage({id: 'NAVIGATION.PARKING-PAYMENT'})}
        </span>
        <img src={carIcon} alt='car icon' className='Menu__navigation_icon_car mt-1' />
      </div>
      <div
        className={
          activeMenu === 'abonementNumberParkingMenu'
            ? `Menu__navigation_block_active `
            : `Menu__navigation_block_disabled `
        }
        onClick={() => {
          dispatch(chooseParkingFormAction('abonementNumberParkingMenu'))
        }}
      >
        <span className='Menu__navigation_font'>
          {intl.formatMessage({id: 'NAVIGATION.SUBSCRIPTION-PAYMENT'})}
        </span>
        <img src={cardIcon} alt='card icon' className='Menu__navigation_icon_card mt-1' />
      </div>
    </div>
  )
}

export {ParkingBottomMenuNavigation}
