import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {isMobile} from 'react-device-detect'

import {KaspiPay} from '../../../modules/forms/payment-methods-form/components/KaspiPay'
import {RootState} from '../../../../setup'

import '../../../modules/forms/payment-methods-form/PaymentMethodsForm.css'
import {useIntl} from 'react-intl'
import {axiosPaymentLinks} from '../../../../setup/axios/AxiosPaymentLinks'

import {
    axiosUserCardDelete,
    axiosUserCards,
    axiosUserCardSave,
    axiosUserInfo,
    axiosUserPayzeCardDelete
} from '../../../../setup/axios/AxiosUser'

import masterCardLogo from '../../../assets/mastercard.svg'
import visaCardLogo from '../../../assets/visa.svg'
import ArrowRight from '../../../assets/ArrowRight.svg'
import { VisaPay } from '../../../modules/forms/payment-methods-form/components/VisaPay'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {getParkingList} from "../../../../setup/axios/AxiosParking";
import {useLang} from "../../../../_metronic/i18n/Metronici18n";
import visaIcon from '../../../assets/payment-page/visa_card.png'
import './index.css'
import {LoadingAnim} from "../../../modules/Loader";
import {currentPaymentMethod} from "../../../../setup/redux/actions/actions";

const apiBaseUrl = process.env.REACT_APP_API_PARQOUR

const apiBaseUrlMob = process.env.REACT_APP_API_MOBILE


type Props = {
    offer?: boolean
}

const Cards: FC<Props> = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const lang = useLang()
    const loggedIn = useSelector((state: RootState) => state.token)
    const [loading, setLoading] = useState(false)
    const [kaspiLink, setKaspiLink] = useState('')
    const [cardPayment, setCardPayment] = useState(false)
    const [cardLoader, setCardLoader] = useState(false)
    const [payzeCardId, setPayzeCardId] = useState('0')
    const [cardsModalShow, setCardsModalShow] = useState(false)

    const [cardsData, setCardsData] = useState<any[]>([])

    const [errorMessage, setErrorMessage] = useState('')
    const [cardsId, setCardsId] = useState('')
    const [cardsModalHeader, setCardsModalHeader] = useState('')
    const [cardIcon, setCardIcon] = useState('visa')
    const [paymentProvider, setPaymentProvider] = useState<any>([])
    const [visaDicsount, setVisaDicsount] = useState('')
    const [userId, setUserId] = useState('0')
    const [selected, setSelected] = useState({})
    const storeCarNumber = useSelector((state: RootState) => state.unauthCarAdd)

    const parkingGuid = useSelector((state: RootState) => state.parkingGuid)
    useEffect(() => {
        axiosUserInfo().then((resp) => {
            if (resp.code === 200) {
                setUserId(resp.message.id)
            }
            if (resp.code !== 200) {
                setErrorMessage('error')
            }
        })

        axiosPaymentLinks(parkingGuid)
            .then((resp: any) => {
                if (resp.code === 200) {
                    if (resp.data.link) {
                        setKaspiLink(resp.data.link + '=' + storeCarNumber)
                    }
                    if (resp.data.cardType) {
                        setVisaDicsount(resp.data.cardType)
                    }
                    if (resp.data.providerSet) {
                        resp.data.providerSet.map((providerSetItem:any) => {
                            if (providerSetItem === 'PITECH') {

                                setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                            }
                            if (providerSetItem === 'STRIPE') {
                                setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                            }
                            if (providerSetItem === 'NAMBA') {
                                setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                            }
                            if (providerSetItem === 'PAYZE') {
                                setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                            }
                        })
                    }
                }
                if (resp.code === 400) {
                    console.log('error')
                }
            })
            .catch((err) => {
                console.log(err)
            })

        if (loggedIn) {
            userCardsFunc()
        }
        getParkingList(lang).then((resp: any) => {
            console.log(resp)
        })
    }, [])

    useEffect(() => {
        if (paymentProvider.includes('PITECH')) {
            setCardPayment(true)
        }
    }, [paymentProvider])

    const userCardsFunc = () => {
        setLoading(true)
        axiosUserCards().then((resp) => {
            if (resp.code === 200) {
                if (resp.message.length > 0) {
                    setCardsData(resp.message)
                    console.log("Card: ", resp.message)
                    dispatch(currentPaymentMethod(resp.message[0]))
                    setSelected(resp.message[0])
                }
                if (resp.message.length === 0) {

                    axios.get(apiBaseUrlMob + '/user/payment/cards/all', {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    }).then((resp) => {
                        console.log("Payze card: ", resp.data[0])
                        setCardsData(resp.data)
                        setSelected(resp.data[0])
                        dispatch(currentPaymentMethod(resp.data[0]))

                    })
                    setCardsData([])
                }
                setLoading(false)
            }
            if (resp.code !== 200) {
                axios.get(apiBaseUrlMob + '/user/payment/cards/all', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then((resp) => {
                    console.log("Payze card: ", resp.data[0])
                    setCardsData(resp.data)
                    setSelected(resp.data[0])
                    dispatch(currentPaymentMethod(resp.data[0]))

                })
                setLoading(false)
                setCardsData([])
                setErrorMessage('error')
            }
        })
    }


    const cardSaveFunc = (payze: boolean) => {
        console.log("Is payze? ", payze)
        console.log("USEER ID: ", userId)
        if(payze){
            axiosUserCardSave(userId,`${window.location.origin}/saveCard-success`, `${window.location.origin}/saveCard-error`, true).then((resp) => {
                if (resp.code === 200) {
                    window.open(resp.message, '_self')
                }
                if (resp.code !== 200) {
                    setErrorMessage('error')
                }
            })
        }else{
            const successUrl = `${window.location.origin}/saveCard-success`
            const errorUrl = `${window.location.origin}/saveCard-error`
            axiosUserCardSave(userId ,successUrl, errorUrl, false).then((resp) => {
                if (resp.code === 200) {
                    window.open(resp.message, '_self')
                }
                if (resp.code !== 200) {
                    setErrorMessage('error')
                }
            })
        }

    }
    const handleDelete = (cardId: string) => {
        if(paymentProvider.includes('PITECH')){
            axiosUserCardDelete(cardId).then((resp:any) => {
                if(resp.code === 200){
                    let cards = cardsData.filter((card: any) => card.cardId !== cardId);
                    setCardsData(cards)
                }else{
                    alert("Somethings goes wrong...")
                }
            })
        }
        if(paymentProvider.includes('PAYZE')){
            axiosUserPayzeCardDelete(cardId).then((resp: any) => {
                if(resp.code === 200){
                    let cards = cardsData.filter((card: any) => card.cardId !== cardId);
                    setCardsData(cards)
                }else{
                    alert("Somethings goes wrong...")
                }
            })
        }


    }
    return (
        <div className='d-flex flex-column w-100 h-100 align-items-center mt-10 gap-5'>
            {
                loading ? <LoadingAnim />
                    :
                    (
                        <>
                            {
                                cardsData && cardsData.map((card: any) => (
                                    <div className={`card ${card === selected && 'highlighted_card'}`} onClick={() => {
                                        setSelected(card)
                                        dispatch(currentPaymentMethod(card))
                                    }}>
                                        <div className="card-content">
                                            <div style={{
                                                width:'38px',
                                                height:'30px',
                                                background:'#F2F4F7',
                                                borderRadius:'6px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent:'center'
                                            }}>
                                                <img src={visaCardLogo} />
                                            </div>
                                            {card.cardMask ?
                                                <h4 className={`mt-5 ${card === selected ? 'highlighted_number' : 'default_number'}`}>{card.cardMask.startsWith('4') ? 'Visa' : 'Mastercard'} {card.cardMask}</h4>
                                                :
                                                <h4 className={`mt-5 ${card === selected ? 'highlighted_number' : 'default_number'}`}>{card.maskedPan.startsWith('4') ? 'Visa' : 'Mastercard'} {card.maskedPan}</h4>
                                            }


                                        </div>
                                        <div className="delete-card" onClick={() => handleDelete(card.cardId)}>
                                            <svg width="18" height="20" viewBox="0 0 18 20" fill="#ADB9C9" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 16C7.26522 16 7.51957 15.8946 7.70711 15.7071C7.89464 15.5196 8 15.2652 8 15V9C8 8.73478 7.89464 8.48043 7.70711 8.29289C7.51957 8.10536 7.26522 8 7 8C6.73478 8 6.48043 8.10536 6.29289 8.29289C6.10536 8.48043 6 8.73478 6 9V15C6 15.2652 6.10536 15.5196 6.29289 15.7071C6.48043 15.8946 6.73478 16 7 16ZM17 4H13V3C13 2.20435 12.6839 1.44129 12.1213 0.87868C11.5587 0.316071 10.7956 0 10 0H8C7.20435 0 6.44129 0.316071 5.87868 0.87868C5.31607 1.44129 5 2.20435 5 3V4H1C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6H2V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V6H17C17.2652 6 17.5196 5.89464 17.7071 5.70711C17.8946 5.51957 18 5.26522 18 5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4ZM7 3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H10C10.2652 2 10.5196 2.10536 10.7071 2.29289C10.8946 2.48043 11 2.73478 11 3V4H7V3ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H5C4.73478 18 4.48043 17.8946 4.29289 17.7071C4.10536 17.5196 4 17.2652 4 17V6H14V17ZM11 16C11.2652 16 11.5196 15.8946 11.7071 15.7071C11.8946 15.5196 12 15.2652 12 15V9C12 8.73478 11.8946 8.48043 11.7071 8.29289C11.5196 8.10536 11.2652 8 11 8C10.7348 8 10.4804 8.10536 10.2929 8.29289C10.1054 8.48043 10 8.73478 10 9V15C10 15.2652 10.1054 15.5196 10.2929 15.7071C10.4804 15.8946 10.7348 16 11 16Z"
                                                      fill={selected === card ? '#FF5F00' : '#ADB9C9'}/>
                                            </svg>
                                        </div>
                                    </div>
                                ))
                            }
                            <button className="add-card-btn mt-10" onClick={() => {
                                setLoading(true)
                                paymentProvider.includes('PAYZE') ? cardSaveFunc(true) : paymentProvider.includes('PITECH') && cardSaveFunc(false)
                            }
                            }>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/gsvg">
                                    <path d="M12 6V18M6 12H18" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                                {intl.formatMessage({id: 'CARDS.ADD-NEW-CARD'})}</button>
                        </>
                    )
            }


        </div>
    )
}

export {Cards}
