import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import {
  directDebt,
  getBillingInfo,
  getBillingToken,
  namba,
  pitech,
  woopayPayment,
  discount,
  lastPayment
} from "../endpoints";
import customAxios from "./CustomAxios";


let orderIdGenerator = () => {
const date = new Date()
return String(date.getDate()) + String(date.getFullYear()) + String(date.getHours()) + String(date.getMinutes()) + String(date.getSeconds()) + String(date.getMilliseconds())
}

let idGenerator = () => {
  return uuidv4();
};


const apiBaseUrl = 'https://api.parqour.app'



export const axiosBillingTokenFunc: any = async () => {
  const data = new URLSearchParams();
  data.append("grant_type", "client_credentials")

  let config:any = {
    method: 'post',
    url: apiBaseUrl + getBillingToken,
    headers: { 
      'Authorization': 'Basic bW9iaWxlOjhtSlJdLW00VCd3OG4hWT0=',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data : data
  }
  
  try {
    const response:any = await axios(config)
    localStorage.setItem('billingToken', response.data.access_token)
    return response.data.access_token
  } catch (err) {
    console.log(err)
}}

export const billingInfoFunc:any = (token:string, plateNumber: string, parkingGuid: string, serviceId: string) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", "AWSELB=A3DB135702664E3D6DB1017C189B3EE2672977A8E5CCBE9C506AF5794295DD7C5F6931B099AA8E2C5B141F5FDD721B1B9100BF3E305A916145FA7BBE5E482E8F511C0003D2; AWSELBCORS=A3DB135702664E3D6DB1017C189B3EE2672977A8E5CCBE9C506AF5794295DD7C5F6931B099AA8E2C5B141F5FDD721B1B9100BF3E305A916145FA7BBE5E482E8F511C0003D2");
 
  const raw = JSON.stringify({
    "command": "check",
    "account": plateNumber,
    "txn_id": idGenerator(),
    "parking_uid": parkingGuid,
    "service_id": serviceId
  });
  console.log("RAW: ", raw);

  const requestOptions:object = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch(apiBaseUrl + getBillingInfo, requestOptions)
    .then((response) => response.json())
    .then((result) => {
        if (result.error) {
          return {
            result: result,
            code: 401
          }
        } else {
          return {
            result: result,
            code: 200
          }
        }
      })
    .catch((error) => {
      console.log(error)
    });
}

export const stripePaymentFunc:any = (plateNumber:string, parkingId:string, successUrl:string, errorUrl:string) => {
  
  let data:any = {
    plateNumber,
    parkingId,
    successUrl,
    errorUrl
  }

  return axios.post('https://payment.dev.parqour.app/stripe/payment', data).then((resp) => {

    return {
      code: resp.status,
      message: resp.data
    }
  }).catch((err) => {
    return {
      code: err.response.status,
      message: 'error'
    }
    })
}
export const payzePaymentFunc:any = (plateNumber:string, parkingUid:string,callback:string) => {
  let data:any = {
    plateNumber,
    parkingUid,
    callback
  }
  return axios.post(apiBaseUrl + '/uz/payze/send', data).then((resp) => {
    console.log("PAYZE SEND RESPONSE: ", resp.data)
    return {
      code: resp.status,
      message: resp.data.message
    }
  }).catch(err => {
    console.log("PAYZE RESPONSE ERROR: ", err)
    return {
      code: err.status,
      message: 'error'
    }
  })
}

export const pitechPaymentFunc:any = (plateNumber:string, parkingId: string, successUrl:string, errorUrl:string) => {
  
  let data:any = {
    plateNumber,
    parkingId,
    successUrl,
    errorUrl
  }
  
  let config:any = {
    method: 'post',
    url: pitech,
    data
  }
  return customAxios(config).then((resp) => {
    return {
      code: resp.status,
      message: resp.data.message
    }
    }).catch((err) => {
          return {
           code: err.response.status,
           message: 'error'
         }})
}

export const nambaPaymentFunc:any = (plateNumber:any, parkingUid:any, cardType:any, userId: any, returnUrl: any) => {
  let data:any = {
    plateNumber,
    parkingUid,
    cardType,
    userId,
    returnUrl
  }

  let config:any = {
    method: 'post',
    url: "https://api.parqour.app/kg/namba/send",
    data
  }
  return customAxios(config).then((resp) => {
    return {
      code: resp.status,
      message: resp.data.message
    }
  }).catch((err) => {
    return {
      code: err.response.message,
      message: 'error'
    }
  })

}

export const axiosSavedCardPayment = (
  plateNumber: string,
  parkingId: string,
  errorUrl: string,
  successUrl: string,
  cardsId: string) => {

  const data = {
    plateNumber,
    parkingId,
    errorUrl,
    successUrl,
    cardsId
  }
  
  let config: any = {
    method: 'post',
    url: directDebt,
    data
  }

  return customAxios(config).then((resp) => {
    return {code: resp.status, message: resp.data.message}
  }).catch((err) => {
    return {code: err.response.status, message: 'error'}
  })
}
export const axiosSavedPayzePayment = (
  userId: string,
  plateNumber: string,
  parkingUid: string,
  cardId: string,
) => {
  const data = {
    userId,
    plateNumber,
    parkingUid,
    cardId,
    callback: `${apiBaseUrl}/payment/payze/result`,
    successUrl: `${apiBaseUrl}/payment-success`,
    errorUrl: `${apiBaseUrl}/payment-error`
  }
  return axios.post(apiBaseUrl + '/payment/payze/pay/withSavedCard', data, {
    headers: {
      'Authorization': `Basic ${localStorage.getItem('accessToken')}`
    }
  }).then((resp) => {
    console.log("Saved payze payment", resp.data)
    return {code: resp.status, message: resp.data.message}
  }).catch((error) => {
    return {code: error.response.status, message: 'error'}
  })

}

export const woopayFunc:any = (token: string, amount:string, orderId:any, plateNumber: string ) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("orderId", orderIdGenerator());
  urlencoded.append("currency", "398");
  urlencoded.append("amount", amount);
  urlencoded.append("abonentId", "321");
  urlencoded.append("phoneNumber", "+377777777");
  urlencoded.append("backlink", 'https://testproject-347706.uc.r.appspot.com/payment-success');
  urlencoded.append("failurebacklink", 'https://testproject-347706.uc.r.appspot.com/payment-error');
  urlencoded.append("email", "me@woopay.com");
  urlencoded.append("language", "rus");
  urlencoded.append("parking", "DEMO");
  urlencoded.append("plate", plateNumber);
  

  const requestOptions:object = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };

  return fetch(apiBaseUrl + woopayPayment, requestOptions)
    .then(response =>response.text())
    .then(result => {
      if (result[0] === '{'){
        return {
          status: 'error',
          response: 'error'}
      } else {
        return {
          status: 'success',
          response: result
        }
      }
    })
    .catch(error => {
      return (error)
    });
}
export const axiosLastPayment = () => {



  let config: any = {
    method: 'get',
    url: lastPayment,

  }

  return customAxios(config).then((resp) => {
    return {code: resp.status, message: resp.data}
  }).catch((err) => {
    return {code: err.response.status, message: 'error'}
  })
}