/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import './ParkingInfo.css'
import carIcon from '../../assets/add-car/selectCarIcon.png'
import starIcon from '../../assets/star.png'
import {axiosUserCars} from "../../../setup/axios/AxiosUser";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {ArrowRightSvg} from "../../assets/svg/ArrowRightSvg";
import arrowPng from '../../assets/svg/arrows.png'
import primaryArrowPng from '../../assets/svg/primaryArrow.png'
import primaryIconCar from '../../assets/CarIcon.png'
import {useDispatch} from "react-redux";
import {unauthCarAdd} from "../../../setup/redux/actions/actions";

type Props = {
    selectedCar: any
    setSelectedCar: any
    setSelectCar: any,
    fromWhere: string
}
export const SelectCar: FC<Props> = ({selectedCar, setSelectedCar, setSelectCar, fromWhere}) => {
    const [cars, setCars] = useState([])
    const navigate = useNavigate()
    const intl = useIntl()
    const dispatch = useDispatch()
    console.log("From where: ", fromWhere)
    async function getUserCars() {
        try {
            const response: any = await axiosUserCars();
            console.log(response.message)
            let cars = response.message
            cars.sort((a:any,b:any) => b.defaultCar - a.defaultCar)
            setCars(cars); // Property 'data' is now recognized by TypeScript
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUserCars();
    }, []);


    return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap:'10px',
                marginTop: '10px'
            }}>
                {
                    cars.map((car: any) => (
                            <div className={`car_button  ${selectedCar.carName === car.carName && 'selected_border'}`}
                                 onClick={() => {
                                     localStorage.setItem('carNumber', car.plateNumber)
                                     localStorage.setItem('carName', car.carName)
                                    setSelectedCar({
                                        carName: car.carName,
                                        plateNumber: car.plateNumber
                                    })
                                     dispatch(unauthCarAdd(car.plateNumber))

                                }
                            }>
                                <div className='d-flex justify-content-between align-items-center' onClick={() => setSelectCar(false)}>
                                    <img src={selectedCar.carName === car.carName ? primaryIconCar : carIcon} />
                                    <div style={{display: 'flex', flexDirection:'column', gap: '5px'}}>
                                        <span className={`title ${car.defaultCar && 'default_color'}`}>{car.carName}</span>
                                        <span className={'plateNumber'}>{car.plateNumber}</span>
                                    </div>
                                </div>

                                <div style={{display: 'flex', flexDirection:'column', justifyContent:'space-between',  height: '80%', padding: '5px 10px'}}>
                                    <div className='d-flex justify-content-end'>
                                        {
                                            car.defaultCar && (
                                                <img style={{width: '17px', height: '17px'}} src={starIcon} />
                                            )
                                        }
                                    </div>
                                    {/*<div className='fw-normal'></div>*/}
                                    <span  className={`d-flex align-items-center fw-normal gap-1 highlighted_info ${car.defaultCar ? '' : 'unselected_color'}`} onClick={() => navigate('/edit-car', {state: {car: car, fromWhere: fromWhere}})}>{intl.formatMessage({id: 'MORE'})} <img src={car.defaultCar ? primaryArrowPng : arrowPng} /> </span>
                                </div>
                            </div>

                    ))
                }
            </div>

    )
}
