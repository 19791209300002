import React from "react";

export const ParkingIcon: React.FC<{ color: string }> = (props) => {
    return (
        <>
            <svg width="22" height="22" viewBox="0 0 22 22" fill='none' xmlns="http://www.w3.org/2000/svg">
                <path d="M7 5H11.8602C13.0911 5 14.0874 5.37699 14.8491 6.13098C15.6164 6.88496 16 7.87734 16 9.10811C16 10.3333 15.6053 11.3257 14.816 12.0852C14.0267 12.8392 13 13.2162 11.736 13.2162H9.50046V17H7V5ZM9.50046 6.98753V11.2536H11.1895C11.9071 11.2536 12.4646 11.0679 12.862 10.6965C13.2594 10.325 13.4581 9.79834 13.4581 9.11642C13.4581 8.43451 13.2594 7.9106 12.862 7.5447C12.4701 7.17325 11.9154 6.98753 11.1978 6.98753H9.50046Z" fill={props.color}/>
                <rect x="1" y="1" width="20" height="20" rx="2" stroke={props.color} stroke-width="2"/>
            </svg>

        </>
    );
};