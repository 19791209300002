import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { axiosPaymentHistory } from '../../../../../setup/axios/AxiosUser'
import arrowLeft from '../../../../assets/ArrowLeft.svg'
import { LoaderForm } from '../../../../modules/forms/billing-form/components/BillingLoaderForm'


import './ProfilePaymentHistory.css'

const ProfilePaymentHistory:FC = () => {
    const [errorMessage, setErrorMessage] = useState('')
    const [loader, setLoader] = useState(true)

    const [paymentData, setPaymentData] = useState<any>([])
    const [parking, setParking] = useState('')
    const [plateNumber, setPlateNumber] = useState('')
    const [date, setDate] = useState('2022-09-15T06:21:46.802Z')
    const [amount, setAmount] = useState('')

    const intl = useIntl()
    const navigate = useNavigate()

    useEffect(() => {
        axiosPaymentHistory(1).then((resp) => {
            setLoader(false)
            if (resp.code === 200) {
                setPaymentData(resp.message)
            } 
            if (resp.code !== 200) {
                setErrorMessage('something went wrong')
            }
        })
    }, [])

    const paymentHistoryFunc = () => {
        return paymentData.map((payment: any) => {
            const {orderId, plateNumber, parkingName, amount, createdAt, paid} = payment

            let dateObj = new Date(createdAt)
            let month = String(dateObj.getUTCMonth() + 1); //months from 1-12
            let day = String(dateObj.getUTCDate());
            let year = String(dateObj.getUTCFullYear());
            let hours = String(dateObj.getHours())
            let minutes = String(dateObj.getMinutes())
            if (Number(month) < 10) {
                month = String('0' + String(month))
            }
            if (Number(day) < 10) {
                day = String('0' + String(day))
            }
            let date = `${day}.${month}.${year} ` + `${hours}:${minutes}`

            
            if (paid) {
                return (
                    <div className='w-100 d-flex flex-column align-items-center bg-white p-4 mt-3 mb-3' key={orderId} style={{fontSize: '20px', borderRadius: '12px'}}>
                        <div className='d-flex flex-row justify-content-between w-100'>
                            <span style={{fontWeight: '600'}}>{parkingName}</span>
                            <span style={{fontWeight: '600'}}>{plateNumber}</span>
                    </div>
                        <div className='d-flex flex-row justify-content-between w-100'>
                            <span>{intl.formatMessage({id: 'PROFILE.PAYMENT-DATE'})}</span>
                            <span>{date}</span>
                        </div>
                        {amount && <div className='d-flex flex-row justify-content-between w-100'>
                            <span>{intl.formatMessage({id: 'PROFILE.PAYMENT-AMOUNT'})}</span>
                            <span>{amount}</span>
                        </div>}
                    </div>)
            }
        })
    }

    return <div className='ProfilePaymentHistory__parent__div'>
        <div className='ProfilePaymentHistory__content__div'>
        <div className='w-100 mt-5 mb-5 h-25px d-flex align-items-center'>
          <div
            className='d-flex cursor-pointer  align-items-center justify-content-center h-30px w-30px'
            onClick={() => {
              navigate('/profile')
            }}
          >
            <img src={arrowLeft} alt='icon' />
          </div>
        </div>
        {loader && <div className='mt-10'><LoaderForm /></div>}
        {!loader && !errorMessage &&
         <div className='w-100 d-flex flex-column align-items-center p-5'>
            {paymentHistoryFunc()}    
        </div>
        }
        {errorMessage === 'something went wrong' && 
            <div className='w-100 d-flex justify-content-center p-5'>
                <span style={{fontSize: '20px', color: 'red'}}>{intl.formatMessage({id: 'LOGIN.ERROR.SOMETHING-WENT-WRONG'})}</span>
            </div>}
        </div>
    </div>
}

export {ProfilePaymentHistory}