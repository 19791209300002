import arrowLeft from "../../assets/ArrowLeft.svg";
import './PaymentCoupon.css'
import {useEffect, useState} from "react";
import {axiosLastPayment} from "../../../setup/axios/AxiosPayments";
import {LoadingAnim} from "../../modules/Loader";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
export const PaymentCoupon: React.FC<{  }> = props => {
    const intl = useIntl()
    const navigate = useNavigate()
    const [data, setData] = useState({
        plateNumber: undefined,
        parking: {
            name: undefined
        },
        createdAt: '2023-04-06T09:14:37.576756',
        amount: undefined
    })
    useEffect(() => {
        async function lastPayment(){
            await axiosLastPayment().then((resp: any) => {
                console.log("Response of last payment: ", resp)
                setData(resp.message)
            })
        }
        lastPayment()
    }, []);

    return (
        <>
            <div style={{padding: '20px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                {
                    !data ? <div className='d-flex justify-content-center'><LoadingAnim /></div> :
                        (
                            <div className='payment_coupon'>
                                <div className='d-flex align-items-center justify-content-between' >
                                    <div className='d-flex  align-items-center justify-content-center' style={{padding:'20px 0'}}>
                                        <span className='back-header-title'>{intl.formatMessage({id: 'PAYMENT.INFO'})}</span>
                                    </div>
                                </div>
                                <div className='d-flex flex-column gap-6'>
                                    <div className='d-flex flex-column '>
                                        <label>{intl.formatMessage({id: 'СAR.PLATE_NUMBER'})}: </label>
                                        <span>{data.plateNumber}</span>
                                    </div>
                                    <div className='d-flex flex-column '>
                                        <label>{intl.formatMessage({id: 'BILLING.BILLING-FORM.PARKING'})}: </label>
                                        <span>{data.parking.name} </span>
                                    </div>
                                    <div className='d-flex gap-5'>
                                        <div className='d-flex flex-column '>
                                            <label>{intl.formatMessage({id: 'PAYMENT.DATE'})} </label>
                                            <span>{(new Date(data.createdAt)).toISOString().split('T')[0].split('-').reverse().join('.')}</span>
                                        </div>
                                        <div className='d-flex flex-column '>
                                            <label>{intl.formatMessage({id: 'PAYMENT.TIME'})} </label>
                                            <span>{(new Date(data.createdAt)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</span>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column '>
                                        <label>{intl.formatMessage({id: 'PAYMENT.AMOUNT'})} </label>
                                        <span>{data.amount} ₸</span>
                                    </div>
                                    {/*<div className='d-flex gap-5'>*/}
                                    {/*    <div className='d-flex flex-column '>*/}
                                    {/*        <label>Дата заезда: </label>*/}
                                    {/*        <span>25 декабря 2022</span>*/}
                                    {/*    </div>*/}
                                    {/*    <div className='d-flex flex-column '>*/}
                                    {/*        <label>Время выезда: </label>*/}
                                    {/*        <span>23:19</span>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>
                            </div>
                        )
                }

                <div className='d-flex flex-column gap-3'>
                    <button className='primary-button' onClick={() => navigate('/home-page')}>{intl.formatMessage({id: 'BTNS.CONTINUE'})}</button>
                    {/*<button className='secondary-button'>Продолжить</button>*/}
                </div>
            </div>
        </>
    );
};