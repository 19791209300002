const parkingNameFromStorage = localStorage.getItem('parkingName')

const addParkingNameReducer = (
    state:any = parkingNameFromStorage ? parkingNameFromStorage : null,
    action:any
) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_PARKING_NAME":
            return payload;
        default:
            return state;
    }
};

export {addParkingNameReducer}