import axios from "axios"
import { paymentLinks } from "../endpoints"

const mobileDevUrl = process.env.REACT_APP_API_MOBILE

export const axiosPaymentLinks = (guid: any) => {
  return axios.post(
    'https://api.parqour.app/mobile' + paymentLinks + guid,
  {}, 
  {headers:{Authorization: 'Basic YWRtaW46MTIzNDU2X0FiMTcwMzIx'}}
  ).then((resp: any) => {
    return {code: resp.status, data: resp.data[0]}
  })
  .catch((err) => {
    console.log(err)
    return {code: 400, data: 'error'}
  })
}
