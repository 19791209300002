const carNumberFromStorage = localStorage.getItem('carNumber')
const carNameFromStorage = localStorage.getItem('carName')
const carReducer = (
    state = carNumberFromStorage && carNameFromStorage ? {plateNumber: carNumberFromStorage, carName: carNameFromStorage} : {plateNumber: '', carName: ''},
    action: any
) => {
    const {type, payload} = action
    switch (type) {
        case 'SET_CAR':
            return payload
        default:
            return state
    }
}

export {carReducer}