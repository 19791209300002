import {FC} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {RiArrowDownSFill} from 'react-icons/ri'
import masterCard from '../../../assets/mastercard.svg'
import visa from '../../../assets/visa.svg'
import arrowRight from '../../../assets/ArrowRight.svg'

type Props = {
  cardsFunc?: any
  cardsModalHeader: any
  setModalShow: any
  modalShow: any
  cardIcon: any
  anotherCardPaymentFunc: any
  setCardLoader: any
}

const SavedCardModal: FC<Props> = ({
  cardsFunc,
  cardsModalHeader,
  setModalShow,
  modalShow,
  cardIcon,
  anotherCardPaymentFunc,
  setCardLoader,
}) => {
  const intl = useIntl()

  return (
    <div className='d-flex h-100 w-100 justify-content-center ParkingListModal__bg p-3'>
      <div
        onClick={() => {
          setModalShow(true)
        }}
        className='h-100 w-100 d-flex align-items-center position-relative rounded cursor-pointer'
      >
        {cardIcon === 'mastercard' && (
          <img style={{width: '35px'}} src={masterCard} alt={'card icon'} />
        )}
        {cardIcon === 'visa' && <img style={{width: '35px'}} src={visa} alt={'card icon'} />}
        <span className='ms-4' style={{fontSize: '16px'}}>
          {intl.formatMessage({id: 'USER.CARDS.CARD'})} {cardsModalHeader}
        </span>
        <span
          className='position-absolute end-0'
          style={{borderRadius: '8px', padding: '4px', fontSize: '20px'}}
        >
          <RiArrowDownSFill />
        </span>
      </div>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
          {cardsFunc()}
          <div
            style={{borderRadius: '14px'}}
            className='w-100 h-40px d-flex flex-row justify-content-between align-items-center border border-secondary'
            onClick={() => {
              anotherCardPaymentFunc()
              setModalShow(false)
              setCardLoader(true)
            }}
          >
            <span className='ms-8' style={{fontSize: '16px', fontWeight: '500'}}>
              {intl.formatMessage({id: 'BILLING.ANOTHER.CARD'})}
            </span>
            <img src={arrowRight} className='me-5' alt='arrow icon' />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export {SavedCardModal}
