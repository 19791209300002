const abonStartTimeFromStorage = localStorage.getItem('abonStartTime')

const abonStartTimeReducer = (
    state:any = abonStartTimeFromStorage ? abonStartTimeFromStorage : null,
    action:any
) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_ABON_START_TIME":
            return payload;
        default:
            return state;
    }
};

export {abonStartTimeReducer}