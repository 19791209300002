import {FC} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {GoLocation} from 'react-icons/go'
import ArrowRight from '../../../assets/ArrowRight.svg'

import './ParkingListModal.css'

type Props = {
  parking: string
  parkingListFunc: any
  modalShow: boolean
  setModalShow: any
}

const ParkingListModal: FC<Props> = ({parking, parkingListFunc, modalShow, setModalShow}) => {
  const intl = useIntl()
  return (
    <div className='d-flex h-100 w-100 justify-content-center ParkingListModal__bg p-3'>
      <div
        onClick={() => {
          setModalShow(true)
        }}
        className='h-100 w-100 d-flex align-items-center position-relative rounded cursor-pointer'
      >
        <GoLocation style={{fontSize: '24px', color: '#127FEE'}} />
        {parking ? (
          <span className='ParkingListModal__fontStyle ps-5'>{parking}</span>
        ) : (
          <span className='ParkingListModal__fontStyle ps-5'>
            {intl.formatMessage({id: 'PAYMENT.CHOOSE-PARKING.CHOOSE-PARKING'})}
          </span>
        )}
        <img
          className='me-2 position-absolute end-0 ParkingListModal__icon_fs_big'
          src={ArrowRight}
        />
      </div>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='ParkingListModal__dialog_position'
      >
        <Modal.Header
          closeButton
          onClick={() => {
            setModalShow(false)
          }}
        ></Modal.Header>
        <Modal.Body className='ParkingListModal__modal_body'>{parkingListFunc()}</Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  )
}

export {ParkingListModal}
