import { logEvent } from 'firebase/analytics'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import { useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import { onboardingAction } from '../../../../setup/redux/actions/actions'
import { analytics } from '../../../App'
import tags from '../../../assets/eventTags'

import './SliderComponent.css'

type Props = {
  imgSrc: any
  textContent: any
  headerContent: any,
  qr?: boolean,
  city?:any,
  parkingUid:any
}

const LastSlide: FC<Props> = ({imgSrc, headerContent, textContent, qr, city, parkingUid}) => {
  const intl = useIntl()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  return (
    <div
      className=' w-100 d-flex flex-column justify-content-center'
    >
      <div className='d-flex flex-column w-100' style={{height: '90%'}}>
        <img alt='slider image' src={imgSrc} style={{maxWidth: '320px', maxHeight:'250px',objectFit:'contain', margin: '0 auto'}} />
        <div className='d-flex flex-column  w-100'>
          <span className='SliderComponent__header'>{headerContent}</span>
          <p className='SliderComponent__content w-100 d-flex justify-content-center mt-8 '>
            {textContent}
          </p>
        </div>
        {/*<button*/}
        {/*  className='h-45px w-100 justify-content-center btn d-flex align-items-center'*/}
        {/*  style={{borderRadius: '14px', background: '#127FEE', color: 'white'}}*/}
        {/*  onClick={() => {*/}
        {/*    logEvent(analytics, tags.onboarding_finished);*/}
        {/*    qr ? navigate("/auth", {replace: true, state: {qr: true, city: city, parkingUid: parkingUid}}) : navigate("/auth")*/}
        {/*    dispatch(onboardingAction({onboard: 'true', city: city, parkingUid: parkingUid}))*/}
        {/*    */}
        {/*  }}*/}
        {/*>*/}
        {/*  {intl.formatMessage({id: 'ONBOARDING.BEGIN'})}*/}
        {/*</button>*/}
      </div>
    </div>
  )
}

export {LastSlide}
