import {FC} from 'react'

const Offer: FC = () => {
  return (
    <div className='d-flex flex-column w-100 h-100 fw-5 '>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
        }}
      >
        <strong>
          <span style={{fontSize: '15px', color: 'black'}}>ПРАВИЛА ПОЛЬЗОВАНИЯ</span>
        </strong>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{fontSize: '15px', color: 'black'}}>Версия от 01.07.2022 года.</span>
        </strong>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <span style={{fontSize: '15px', color: 'black'}}>
          Настоящие правила распространяют своё действие на всех лиц (далее - «
          <strong>Пользователь</strong>»),&nbsp;
        </span>
        <span style={{fontSize: '15px', color: 'black'}}>использующих&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>ресурс</span>
        <span style={{fontSize: '15px', color: 'black'}}>ы</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;ТОО «</span>
        <span style={{fontSize: '15px', color: 'black'}}>Smart</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>Parking</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>Technologies</span>
        <span style={{fontSize: '15px', color: 'black'}}>
          », БИН 180640014343 (далее – «<strong>Компания</strong>»), в том числе&nbsp;
        </span>
        <span style={{fontSize: '15px', color: 'black'}}>веб</span>
        <span style={{fontSize: '15px', color: 'black'}}>-сайт&nbsp;</span>
        <a href='https://parqour.com/'>
          <span style={{fontSize: '15px'}}>https://parqour.com/</span>
        </a>
        <span style={{fontSize: '15px', color: 'black'}}>, мобильно</span>
        <span style={{fontSize: '15px', color: 'black'}}>е</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;приложени</span>
        <span style={{fontSize: '15px', color: 'black'}}>е</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;«</span>
        <span style={{fontSize: '15px', color: 'black'}}>Parqour</span>
        <span style={{fontSize: '15px', color: 'black'}}>
          » &nbsp;(далее - «<strong>Ресурсы</strong>»). &nbsp;
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <span style={{fontSize: '15px', color: 'black'}}>Настоящие правила совместно&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>c</span>
        <span style={{fontSize: '15px', color: 'black'}}>
          &nbsp;предложениями юридических лиц и/или индивидуальных предпринимателей (далее - «
          <strong>Продавец</strong>»), размещенных на Ресурсах Компании, являются офертой Продавца в
          адреса Пользователя в соответствии с пунктом 5 статьи 395 Гражданского кодекса Республики
          Казахстан. &nbsp;
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <span style={{fontSize: '15px', color: 'black'}}>
          Компания предоставляет Пользователям и Продавцам интернет-площадку для оплаты парковочных
          услуг, оказываемых Продавцами Пользователям (далее – «<strong>Услуги</strong>»).&nbsp;
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{fontSize: '15px', color: 'black'}}>Основные положения:</span>
        </strong>
      </p>
      <ol style={{listStyleType: 'decimal', marginLeft: '0cmundefined'}}>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Акцептом оферты Продавца является факт оплаты Услуг Пользователем в соответствии с
            пунктом 3 статьи 396 Гражданского кодекса Республики Казахстан. Акцепт является полным и
            безоговорочным. Совершая действия по акцепту Оферты, Пользователь подтверждает свою
            правоспособность и дееспособность, а также свое законное право вступать в договорные
            отношения с Продавцом.&nbsp;
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Предмет, условия и срок действия договора, заключаемого между Продавцом и Покупателем,
            устанавливаются в соответствующем Приложении к нему (условия оплаты парковки, описанные
            на Ресурсах Компании). Приложения являются неотъемлемой частью настоящих Правил.&nbsp;
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Полномочия и функции Компании ограничиваются размещением на ресурсах Компании условий
            сделки от имени Продавца, а также предоставлением возможности оплаты Услуг Продавца
            через платежные платформы партнеров Компании – поставщиков платежных услуг. Права и
            обязанности по договору возникают непосредственно у Пользователя и Продавца. Все
            претензии к предмету и условиям договора предъявляются Продавцу.&nbsp;
          </span>
        </li>
      </ol>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{fontSize: '15px', color: 'black'}}>Персональные данные Пользователя</span>
        </strong>
      </p>
      <ol style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Пользователь согласен с тем, что Компанией используются и обрабатываются персональные
            данные Пользователя.
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Компания использует персональную информацию Пользователя в том числе:
          </span>
        </li>
      </ol>
      <ul style={{listStyleType: 'disc', marginLeft: '11.3px'}}>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            для регистрации Пользователя на ресурсах Компании;
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            для выполнения своих обязательств перед Пользователем;
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            для оценки и анализа работы ресурсов Компании;
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            для проведения анализа и измерения скачивания Пользователями мобильного приложения,
            статистики на Ресурсах Компании, а также их поведения внутри Ресурсов Компании и т.д.;
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>для у</span>
          <span style={{fontSize: '15px', color: '#222222'}}>
            лучшения качества Ресурсов, удобства их использования, разработка и развитие Ресурсов
            Компании, устранения технических неполадок или проблем с безопасностью.
          </span>
        </li>
      </ul>
      <ol style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Компания обязуется не разглашать полученную от Пользователя информацию. Не считается
            нарушением предоставление Компанией информации аффилированным лицам, агентам и третьим
            лицам, действующим на основании договора с Компанией, для исполнения обязательств перед
            Пользователем. Не считается нарушением обязательств разглашение информации в
            соответствии с обоснованными и применимыми требованиями закона. Компания вправе
            использовать технологию «cookies». «Cookies» не содержат конфиденциальную информацию и
            не передаются третьим лицам. Компания получает информацию об ip-адресе посетителя
            ресурсов Компании. Данная информация не используется для установления личности
            Пользователя, за исключением случаев мошеннических действий Пользователя.
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Пользователь несет ответственность за достоверность передаваемых Компании персональных
            данных.
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Пользователь дает согласие Компании на запись телефонных разговоров для повышения
            качества обслуживания и подтверждения устных заявлений Пользователя.
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Зарегистрировавшись на Ресурсах Компании, Пользователь дает свое согласие на получение
            обновленной информации, информационных сообщений посредством SMS, электронной почты, а
            также пуш уведомлений, телефонных звонков и других способов коммуникации.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{fontSize: '15px', color: 'black'}}>Платежи</span>
        </strong>
      </p>
      <ol style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Ресурсы подключены к интернет-эквайрингу, и Пользователи могут осуществлять оплаты
            банковской картой&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>Visa</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;или&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>Mastercard</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            . После введения регистрационного номерного знака и нажатия кнопки «оплатить»
            открывается виджет (защищенное окно) с платежной страницей, где Пользователь вводит
            данные своей банковской карты. Для дополнительной аутентификации держателя карты
            используется протокол 3-
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>D</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>Secure</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            . Если Банк-эмитент Пользователя поддерживает данную технологию, Пользователь будет
            перенаправлен на его сервер для прохождения дополнительной идентификации. Информацию о
            правилах и методах дополнительной идентификации Пользователь может уточнить в Банке,
            выдавшем платежную карту.
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Онлайн-оплата осуществляется в соответствии с правилами Международных платежных
            систем&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>Visa</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;и&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>MasterCard</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            &nbsp;на принципах соблюдения конфиденциальности и безопасности совершения платежа, для
            этого используются самые актуальные методы проверки, шифрования и передачи данных по
            закрытым каналам связи.&nbsp;
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Ввод данных банковской карты осуществляется в виджете (защищенном окне) на платежной
            странице
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            банков и/или платежных организаций. В поля на платежной странице требуется ввести номер
            карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>CVV</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>2 для&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>VISA</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;или&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>CVC</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>2 для&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>MasterCard</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            ). Все необходимые данные отображены на поверхности банковской карты.&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>CVV</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>2/&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>CVC</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            2 — это трёхзначный код безопасности, находящийся на оборотной стороне карты.
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Далее в том же окне откроется страница банка-эмитента Пользователя для ввода 3-
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>D</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>Secure</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            &nbsp;кода. В случае, если у Пользователя не настроен статичный 3-
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>D</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>Secure</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            , он будет отправлен на номер телефона Пользователя посредством&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>SMS</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            &nbsp;или иным способом, предусмотренным банком-эмитентом. Если 3-
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>D</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>Secure</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            &nbsp;код не пришел Пользователю, то Пользователю следует обратиться в банк-эмитент. 3-
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>D</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>Secure</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            &nbsp;— это самая современная технология обеспечения безопасности платежей по картам в
            сети интернет. Позволяет однозначно идентифицировать подлинность держателя карты,
            осуществляющего операцию, и максимально снизить риск мошеннических операций по карте.
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Совершая действия по подключению сервиса хранения платежной карты, Пользователь
            подтверждает свое согласие на хранение информации в системе третьих лиц, нести
            самостоятельную полную ответственность за использование сервиса хранения платежной
            карты, все риски, связанные с возможным использованием третьими лицами логина, пароля,
            ответа на контрольный вопрос, номера карты, использование автоматической авторизации,
            рекуррентных платежей, в том числе при пересылки сообщения полученного на электронную
            почту и иной информации.
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Случаи отказа в совершении платежа:
          </span>
        </li>
      </ol>
      <ul style={{listStyleType: 'disc', marginLeft: '11.3px'}}>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            банковская карта не предназначена для совершения платежей через интернет, о чем можно
            узнать, обратившись в Банк-эмитент;
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на
            платежной карте можно узнать, обратившись в банк, выпустивший платежную карту;&nbsp;
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            данные банковской карты введены неверно;
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            истек срок действия банковской карты. Срок действия карты, как правило, указан на
            лицевой стороне карты (это месяц и год, до которого действительна карта). Подробнее о
            сроке действия карты Пользователь может узнать, обратившись в банк-эмитент.
          </span>
        </li>
      </ul>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: 'normal',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{fontFamily: '"Times New Roman",serif'}}>Гарантии безопасности</span>
        </strong>
      </p>
      <ol style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Банки и платежные организации, привлекаемые для обработки платежей на Ресурсах,
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            защищают и обрабатывают данные платежных карт по стандарту безопасности&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>PCI</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>DSS</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            &nbsp;3.2.1. Передача информации в платежный шлюз происходит с применением технологии
            шифрования&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>SSL</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            . Дальнейшая передача информации происходит по закрытым банковским сетям, имеющим
            наивысший уровень надежности.
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Данные карт Пользователей не передаются Компании или иным третьим лицам, не
            задействованным в обработке платежа. Для дополнительной аутентификации держателя карты
            используется протокол 3-
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>D</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>Secure</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>.</span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            В случае наличия вопросов по совершенному платежу Пользователи могут обратиться в службу
            поддержки клиентов платежного сервиса.
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Предоставляемая Пользователями персональная информация (имя, адрес, телефон,&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>e</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>-</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>mail</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            , номер платежной карты) является конфиденциальной и не подлежит разглашению. Данные
            платежной карты передаются только в зашифрованном виде и не сохраняются у
            Компании.&nbsp;
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Безопасность обработки интернет-платежей гарантирует поставщик платежной услуги. Все
            операции с платежными картами происходят в соответствии с требованиями&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>VISA</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>International</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>,&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>MasterCard</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            &nbsp;и других платежных систем. При передаче информации используются специализированные
            технологии безопасности карточных онлайн-платежей, обработка данных ведется на
            безопасном высокотехнологичном сервере процессинговой компании.
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Оплата платежными картами безопасна, потому что:
          </span>
        </li>
      </ol>
      <ul style={{listStyleType: 'disc', marginLeft: '11.3px'}}>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Система авторизации гарантирует покупателю, что платежные реквизиты его платежной карты
            (номер, срок действия,&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>CVV</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>2/</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>CVC</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            2) не попадут в руки мошенников, так как эти данные не хранятся на сервере авторизации и
            не могут быть похищены.
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Покупатель вводит свои платежные данные непосредственно в системе
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            платежного провайдера, а не на Ресурсах Компании.
          </span>
        </li>
      </ul>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{fontSize: '15px', color: 'black'}}>Возврат денег</span>
        </strong>
      </p>
      <ol style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Для возврата денежных средств на банковскую карту необходимо заполнить заявление о
            возврате денежных средств и отправить его вместе с приложением копии документа,
            удостоверяющего личность, по адресу
          </span>
          <span style={{}}>&nbsp;</span>
          <a href='mailto:support@parqour.com'>
            <span style={{fontFamily: '"Times New Roman",serif'}}>support</span>
            <span style={{fontFamily: '"Times New Roman",serif'}}>@</span>
            <span style={{fontFamily: '"Times New Roman",serif'}}>parqour</span>
            <span style={{fontFamily: '"Times New Roman",serif'}}>.</span>
            <span style={{}}>com</span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif'}}>.</span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Рассмотрение заявления и в случае его удовлетворения возврат денежных средств будет
            осуществлен на банковскую карту в течение 14 рабочих дней со дня получения заявления и
            необходимых документов Компанией.
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Для возврата денежных средств по операциям, проведенным с ошибками, необходимо
            обратиться с письменным заявлением и приложением копии документа, удостоверяющего
            личность, и чеков/квитанций, подтверждающих ошибочное списание. Данное заявление
            необходимо направить по адресу
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <a href='mailto:support@parqour.com'>
            <span style={{fontFamily: '"Times New Roman",serif'}}>support</span>
            <span style={{fontFamily: '"Times New Roman",serif'}}>@</span>
            <span style={{fontFamily: '"Times New Roman",serif'}}>parqour</span>
            <span style={{fontFamily: '"Times New Roman",serif'}}>.</span>
            <span style={{}}>com</span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif'}}>.</span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            Срок рассмотрения Заявления и возврата денежных средств начинает исчисляться с момента
            получения Компанией Заявления и рассчитывается в рабочих днях без учета
            праздников/выходных дней.
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Возврат средств за услуги осуществляется на карту Пользователя, с которой был
            осуществлен платеж за Услуги или иными способами, согласованными между Пользователем и
            Продавцом.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{fontSize: '15px', color: 'black'}}>Несанкционированный доступ</span>
        </strong>
      </p>
      <ol style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Пользователь несет ответственность за несанкционированное получение доступа к личному
            кабинету Пользователя третьими лицами и обеспечивает конфиденциальность данных доступа к
            личному кабинету. Пользователь несет ответственность за все действия и последствия
            использования личного кабинета Пользователя, включая случаи несанкционированного
            доступа, а также добровольной передачи Пользователем данных для доступа к личному
            кабинету Пользователя третьим лицам. При этом все действия в рамках или с использованием
            личного кабинета Пользователя считаются произведенными самим Пользователем. Пользователь
            самостоятельно несет ответственность за сохранность своих данных при смене номера
            телефона, обеспечивающего доступ к личному кабинету Пользователя.&nbsp;
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Пользователь обязан немедленно уведомить Компанию о любом случае несанкционированного
            доступа к личному кабинету Пользователя и/или о любом нарушении/подозрениях о нарушении
            конфиденциальности. В целях безопасности, Пользователь обязан самостоятельно
            осуществлять безопасное завершение работы по окончании каждой сессии работы в личном
            кабинете. Компания не отвечает за возможную потерю данных и любые другие последствия.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{fontSize: '15px', color: 'black'}}>Заключительные положения</span>
        </strong>
      </p>
      <ol style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Компания имеет право в любой момент изменять условия настоящих Правил в одностороннем
            порядке без предварительного согласования с Пользователями, обеспечивая при этом
            публикацию измененных условий на Ресурсах Компании. Компания не несет ответственности за
            сетевые атаки или взлом Ресурсов Компании (в том числе, но не ограничиваясь), сбои в
            работе Ресурсов Компании, вызванные техническими причинами.&nbsp;
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение
            обязательств на время действия непреодолимой силы. Под непреодолимой силой понимаются
            чрезвычайные и непреодолимые обстоятельства, препятствующие исполнению своих
            обязательств Сторонами. К ним относятся стихийные явления; воздействие ядерного взрыва,
            радиации (ионизирующего излучения) или радиоактивного заражения материалами ядерного
            оружия, производственных и научно-исследовательских работ или радиоактивными отходами;
            войны, военные вторжения, враждебные действия иностранного врага, военные маневры и
            связанные с ними мероприятия военного характера; гражданская война, вооруженный мятеж,
            народные волнения всякого рода, массовые беспорядки, насильственный захват или
            насильственное удержание власти; эпидемии, метеоусловия, террористические действия,
            забастовки, изъятия, конфискации, национализация и т.п. При этом общеизвестные события
            не нуждаются в каком-либо подтверждении.
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Все споры и разногласия, вытекающие из правоотношений по настоящей Оферте, разрешаются
            путем переговоров. В случае невозможности решения возникших споров в ходе переговоров,
            такие споры должны быть переданы на решение в суд Республики Казахстан, города Алматы с
            обязательным соблюдением претензионного порядка. Срок ответа на претензию составляет 15
            (пятнадцать) рабочих дней.
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            Пользователь имеет право обратиться к Компании или в суд за защитой своих нарушенных
            прав и законных интересов. Контактные данные (адреса и номера телефона) уполномоченного
            органа и субъектов досудебного урегулирования потребительских споров&nbsp;
          </span>
          <a href='https://www.gov.kz/memleket/entities/mti-kzpp/contacts?lang=ru'>
            <span style={{fontSize: '15px'}}>
              https://www.gov.kz/memleket/entities/mti-kzpp/contacts?lang=ru
            </span>
          </a>
          <span style={{fontSize: '15px', color: 'black'}}>.</span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            В случае возникновения вопросов, Пользователь может обратиться к Компании:
          </span>
        </li>
      </ol>
      <ul style={{listStyleType: 'disc', marginLeft: '11.3px'}}>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            по адресу: г. Алматы, ул. Байзакова, 280, Южная башня, 20 этаж;
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>E</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>-</span>
          <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>mail</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
          <a href='mailto:support@parqour.com' style={{color: 'black'}}>
            <span style={{fontFamily: '"Times New Roman",serif'}}>support</span>
            <span style={{fontFamily: '"Times New Roman",serif'}}>@</span>
            <span style={{fontFamily: '"Times New Roman",serif'}}>parqour</span>
            <span style={{fontFamily: '"Times New Roman",serif'}}>.</span>
            <span style={{}}>com</span>
          </a>
          <span style={{color: '#0563C1', textDecoration: 'underline'}}>
            <span style={{fontFamily: '"Times New Roman",serif'}}>;</span>
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>
            Телефон: +7 771 790 2464.&nbsp;
          </span>
        </li>
      </ul>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: 'normal',
          textAlign: 'justify',
        }}
      >
        <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: 'normal',
          textAlign: 'justify',
        }}
      >
        <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
      </p>
    </div>
  )
}

export {Offer}
