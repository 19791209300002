import arrowLeft from "../../assets/ArrowLeft.svg";
import {useNavigate} from "react-router-dom";
import carIcon from "../../assets/add-car/selectCarIcon.png";
import {useEffect, useState} from "react";
import {axiosUserCars} from "../../../setup/axios/AxiosUser";
import './MyCars.css'
import starIcon from "../../assets/star.png";
import {ArrowRightSvg} from "../../assets/svg/ArrowRightSvg";
import CarIcon from '../../assets/CarIcon.png';
import {useIntl} from "react-intl";
import primaryArrowPng from "../../assets/svg/primaryArrow.png";
import arrowPng from "../../assets/svg/arrows.png";
export const MyCars: React.FC<{  }> = props => {
    const navigate = useNavigate()
    const intl = useIntl()
    const [selectedCar, setSelectedCar] = useState({})
    const [cars, setCars] = useState([])

    async function getUserCars() {
        try {
            const response: any = await axiosUserCars();
            let cars = response.message
            cars.sort((a:any,b:any) => b.defaultCar - a.defaultCar)
            setCars(cars); // Property 'data' is now recognized by TypeScript
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUserCars();
    }, []);
    return (
        <>
            <div style={{padding: '20px',height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex  align-items-center' style={{padding:'20px 0'}}>
                        <img src={arrowLeft} style={{width:'15px', height:'21px', marginRight:'10px'}} onClick={() => navigate('/home-page', {state: {returnTo: 'ProfilePage'}})} />
                        <span className='back-header-title'>{intl.formatMessage({id: 'PROFILE.MY-CARS'})}</span>
                    </div>
                </div>
                <div className='button_wrapper responsive_width' style={{ height: '75%', bottom: '15%'}}>

                {/*<div className='my_cars_list'>*/}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '10px',
                        gap:'10px',
                    }}>
                        {
                            cars.map((car: any) => (
                                <div
                                    style={{position: 'relative'}}
                                    className={'car_button'} onClick={() => {
                                    setSelectedCar(car)
                                }
                                }>
                                    <div className='d-flex align-items-center'>
                                        <img src={CarIcon} />
                                        <div style={{display: 'flex', flexDirection:'column', gap: '5px'}}>
                                            <span className={'title default_color'}>{car.carName}</span>
                                            <span className={'plateNumber'}>{car.plateNumber}</span>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection:'column', justifyContent:'space-between',  height: '80%', padding: '5px 10px'}}>

                                        <div className='d-flex justify-content-end'>
                                            {
                                                car.defaultCar && (
                                                    <img style={{width: '17px', height: '17px'}} src={starIcon} />
                                                )
                                            }

                                        </div>
                                        <span
                                            style={{position: 'absolute', right: '10px', bottom: '16px', color: '#157FFF'}}
                                            onClick={() => navigate('/edit-car', {state: {car: car}})}
                                        >
                                            {intl.formatMessage({id: 'MORE'})} <img src={primaryArrowPng} />
                                        </span>
                                    </div>
                                </div>

                            ))
                        }
                    </div>
                {/*</div>*/}
                </div>
                <button
                    className='primary-button w-100'
                    onClick={() => navigate('/add-car', {state: {fromCarsList: true}})}
                >
                    {intl.formatMessage({id: 'CAR.ADD'})}
                </button>
            </div>
        </>
    );
};