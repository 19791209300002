import {FC} from 'react'
import {useIntl} from 'react-intl'
import calendarImg from '../../assets/calendar.svg'
import timepickerImg from '../../assets/timepicker.svg'

import './AbonementInformationMobile.css'

const AbonementMobileKz: FC = () => {
  const intl = useIntl()

  return (
    <div className='d-flex flex-column justify-content-center p-5'>
      <div className='d-flex flex-column w-100 mb-4'>
        <span className='AbonementInformationModal__text_big'>Абоненттік төлем-бұл:</span>
        <div className='d-flex flex-column AbonementInformationModal__text_small'>
          <p>1. Тұрақ орнын іздеуге уақытты үнемдеу</p>
          <p>2. Өзіңіз үшін ыңғайлы тарифті таңдау мүмкіндігі</p>
          <p>3. Паркоматтың күнделікті төлемінің орнына бір жолғы төлем</p>
          <p>4. Автокөлігіңіз үшін абонементті тиімді сатып алу</p>
          <p>
            Маңызды! Әрбір автотұрақ өз тарификациясын қояды. Толық ақпарат алу үшін таңдалған
            автотұрақ әкімшілігіне хабарласыңыз
          </p>
        </div>
      </div>
      <div className='d-flex flex-column w-100'>
        <span className='AbonementInformationModal__text_big'>Бұл қалай жұмыс істейді?</span>
        <span className='AbonementInformationModal__text_small mt-2'>
          1. Басталу күнін таңдаңыз
        </span>
        <div className='w-100 d-flex justify-content-center mt-4'>
          <img src={calendarImg} style={{width: '50%'}} alt={'Calendar image'} />
        </div>
        <span className='AbonementInformationModal__text_small mt-2'>
          2. Абонементтің басталу уақытын таңдаңыз
        </span>
        <div className='w-100 d-flex justify-content-center mt-4'>
          <img src={timepickerImg} style={{width: '50%'}} alt={'timepicker'} />
        </div>
        <span className='AbonementInformationModal__text_small'>3. Төлеңіз</span>
      </div>
    </div>
  )
}

export {AbonementMobileKz}
