import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import {
  addParkingNameAction,
  chooseParkingFormAction,
  unauthCarAdd,
  serviceIdAction,
  showPlateNumberHint,
} from '../../../../../../../../setup/redux/actions/actions'
import {AddCarInput} from '../../../inputs/AddCarInput'
import {RootState} from '../../../../../../../../setup'
import {AbonementInformationModal} from '../../../../../../modal/abonement-information/AbonementInformationModal'
import {ParkingListModal} from '../../../../../../modal/parking-list/ParkingListModal'
import {ParkingBottomMenuNavigation} from '../../navigation/ParkingBottomMenuNavigation'

import 'react-datepicker/dist/react-datepicker.css'
import './ParkingBottomMenuFastPayment.css'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../../../../../../App'
import tags from '../../../../../../../assets/eventTags'

type Props = {
  plateNumber: string
  setPlateNumber: any
  parking: string
  parkingListFunc: any
  modalShow: boolean
  setModalShow: any
  setChooseParkingForm: any
  abonement: boolean
  serviceId: any,
  city?: any
}

const ParkingBottomMenuFastPayment: FC<Props> = ({
  plateNumber,
  setPlateNumber,
  parking,
  parkingListFunc,
  modalShow,
  setModalShow,
  setChooseParkingForm,
  abonement,
  serviceId,
  city
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  console.log("CITY: ", city)
  const cityId = localStorage.getItem("cityId");
  const [btnActive, setBtnActive] = useState(true)
  const [abonementInfoModal, setAbonementInfoModal] = useState(false)
  const storeParkingGuid = useSelector((state: RootState) => state.parkingGuid)
  console.log("Parking UID: ", storeParkingGuid);
  const loggedIn = useSelector((state: RootState) => state.token)

  useEffect(() => {
    if(city){
      if (plateNumber && plateNumber.length > 3) {
        setBtnActive(false)
      } else {
        setBtnActive(true)
      }
    }else{
      if (parking && plateNumber && plateNumber.length > 3) {
        setBtnActive(false)
      } else {
        setBtnActive(true)
      }
    }
    
  }, [parking, plateNumber])

  const btnFuc = () => {
    console.log("Parking: ", parking)
    logEvent(analytics, tags.pay_button);
    dispatch(unauthCarAdd(plateNumber))
    
    dispatch(addParkingNameAction(parking))
    dispatch(serviceIdAction(serviceId))
    dispatch(showPlateNumberHint(false))
    if (abonement) {
      if (loggedIn) {
        dispatch(chooseParkingFormAction('abonementCalendarMenu'))
      }
      if (!loggedIn) {
        dispatch(chooseParkingFormAction('login'))
      }
    }
    if (!abonement) {
      setChooseParkingForm(false)
    }
  }

  return (
    <div className='w-100 h-100 bg-white d-flex flex-column position-relative '>
      {/* bottom menu navigation start */}
      <div className='w-100 h-70px mt-2 mb-4'>
        <ParkingBottomMenuNavigation />
      </div>
      {/* bottom menu navigation start */}

      {/* abonement info start */}
      {abonement && (
        <AbonementInformationModal
          setModalShow={setAbonementInfoModal}
          modalShow={abonementInfoModal}
        />
      )}
      {/* abonement info end */}

      {/* car number start */}
      <div className='w-100 h-70px d-flex flex-column mt-2 mb-2'>
        <div className='fw-bolder ps-3 mb-2 ParkingMenu__fontSize_big'>
          {intl.formatMessage({id: 'PAYMENT.CHOOSE-PARKING.ADD-CAR-INPUT'})}
        </div>
        <div className='w-100 h-50px rounded'>
          <AddCarInput
            plateNumber={plateNumber}
            setPlateNumber={setPlateNumber}
            placeholder={
              intl.formatMessage({
              id: cityId !== '7' ? 'PAYMENT.CHOOSE-PARKING.PLATE-NUMBER-PLACEHOLDER' : 'PAYMENT.CHOOSE-PARKING.PLATE-NUMBER-PLACEHOLDER-BISHKEK',
            })
          }
          />
        </div>
      </div>
      {/* car number end */}

      {/*parking modal start*/}
      {!city && (
        <div className='w-100 h-50px rounded ps-3 pe-3 mb-6 mt-4 ParkingList__mobile_list'>
          <ParkingListModal
            parking={parking}
            parkingListFunc={parkingListFunc}
            modalShow={modalShow}
            setModalShow={setModalShow}
          />
      </div>
      )}
      
      <div
        className='flex-column ParkingSidebar__div_parkingList bg-white ParkingList__web_list'
        style={{height: 'calc(100vh - 380px)'}}
      >
        {parkingListFunc()}
      </div>
      {/*parking modal end*/}
      {/* pay button start */}
      <div className='h-50px ps-3 pe-3 w-100 d-flex flex-column align-items-center justify-content-center' 
        style={{marginTop: `${city && '40px'}`}}
      >
        <button
          className='h-100 w-100 justify-content-center btn d-flex align-items-center fs-1'
          style={{borderRadius: '14px', background: '#127FEE', color: 'white'}}
          onClick={() => {
            btnFuc()
          }}
          disabled={btnActive}
        >
          {abonement
            ? intl.formatMessage({id: 'BTNS.CONTINUE'})
            : intl.formatMessage({id: 'BTNS.PAY'})}
        </button>
      </div>
      {/* pay button end */}
    </div>
  )
}

export {ParkingBottomMenuFastPayment}
