const abonStartDateFromStorage = localStorage.getItem('abonStartDate')

const abonStartDateReducer = (
    state:any = abonStartDateFromStorage ? abonStartDateFromStorage : null,
    action:any
) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_ABON_START_DATE":
            return payload;
        default:
            return state;
    }
};

export {abonStartDateReducer}