import { logEvent } from 'firebase/analytics'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import { analytics } from '../../../../App'

import arrowRight from '../../../../assets/ArrowRight.svg'
import tags from '../../../../assets/eventTags'
import kaspiLogo from '../../../../assets/kaspi-logo.svg'

import './PaymentResult.css'

type Props = {
  link: string
}

const KaspiPay: FC<Props> = ({link}) => {
  
  const intl = useIntl()
  return (
    <div className='d-flex flex-row justify-content-between w-100' onClick={() => logEvent(analytics, tags.kaspi_select)}>
      <div className='h-100 w-50px'>
        <img src={kaspiLogo} className='PaymentResult__img_kaspi' />
      </div>
      <a
        className='fs-5 fw-bolder w-100 h-100 justify-content-between d-flex align-items-center text-dark rounded cursor-pointer'
        href={link}
      >
        <div className='d-flex flex-column ps-4'>
          <span className=' PaymentResult__span_bigText'>Kaspi.kz</span>
          <span className='PaymentResult__span_smallText'>
            {intl.formatMessage({id: 'BILLING.PAYMENT-METHODS.PAYMENTS'})}
          </span>
        </div>{' '}
        <img src={arrowRight} className='PaymentResult__span_icon' />
      </a>
    </div>
  )
}

export {KaspiPay}
