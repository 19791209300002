
const geolocateControlReducer = (
    state:any = '',
    action:any 
) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_TRIGGER_GEOLOCATION":
            return payload;
        default:
            return state;
    }
};

export {geolocateControlReducer}