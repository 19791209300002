import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'

import {axiosCarAbonement} from '../../../../../setup/axios/AxiosAbonements'
import {AbonementDeleteModal} from '../../../../modules/modal/abonement-delete/AbonementDeleteModal'
import {BtnLoaderForm} from '../../../../modules/forms/billing-form/components/BillingLoaderForm'
import arrowLeft from '../../../../assets/ArrowLeft.svg'

import './ProfileAbonementList.css'

const ProfileAbonementList: FC = () => {
  const [plateNumber, setPlateNumber] = useState('')
  const [abonementList, setAbonementList] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [parkingGuid, setParkingGuid] = useState('')
  const [abonementId, setAbonementId] = useState('')

  const [loader, setLoader] = useState(false)

  const intl = useIntl()

  const navigate = useNavigate()

  const carAbonementListFunc = () => {
    console.log("Hi")
    setLoader(true)
    axiosCarAbonement(plateNumber).then((resp: any) => {
      setLoader(false)
      if (resp.code === 200) {
        if (resp.message.length > 0) {
          console.log(resp.message)
          resp.message.map((msg: any) => 
            msg.list.map((item: any) => console.log(item))
          )
          setAbonementList(resp.message)
        }
        if (resp.message.length === 0) {
          setErrorMessage('emptyAbon')
        }
      }
      if (resp.code !== 200 && resp.message === 'error') {
        setErrorMessage('error')
      }
    })
  }

  const scheduleFunc = (schedule: any) => {
    if (schedule !== "CUSTOM" && schedule !=="UNLIMITED") {
      return Object.entries(JSON.parse(schedule)).map((item: any, id) => {
        let startTime = item[1][0]
        let endTime = item[1][item[1].length - 1] + 1
        if (Number(startTime) < 10) {
          startTime = '0' + String(startTime)
        }
        if (Number(endTime) < 10) {
          endTime = '0' + String(endTime)
        }
        if (endTime === 24) {
          endTime = '00'
        }
        let weekDay = item[0]
        switch (weekDay) {
          case '0':
            weekDay = intl.formatMessage({id: 'ABONEMENT.MONDAY'})
            break
          case '1':
            weekDay = intl.formatMessage({id: 'ABONEMENT.TUESDAY'})
            break
          case '2':
            weekDay = intl.formatMessage({id: 'ABONEMENT.WEDNESDAY'})
            break
          case '3':
            weekDay = intl.formatMessage({id: 'ABONEMENT.THURSDAY'})
            break
          case '4':
            weekDay = intl.formatMessage({id: 'ABONEMENT.FRIDAY'})
            break
          case '5':
            weekDay = intl.formatMessage({id: 'ABONEMENT.SATURDAY'})
            break
          case '6':
            weekDay = intl.formatMessage({id: 'ABONEMENT.SUNDAY'})
            break
          default:
            weekDay = intl.formatMessage({id: 'ABONEMENT.MONDAY'})
            break
        }
        return (
          <div
            key={id}
            style={{fontSize: '16px', fontWeight: '400'}}
            className='d-flex w-150px flex-row justify-content-between'
          >
            <div>{weekDay}:</div>
            <div className='d-flex flex-row'>
              <span className='me-1'>
                {startTime} {intl.formatMessage({id: 'HOURS'})}
              </span>{' '}
              -{' '}
              <span className='ms-1'>
                {endTime} {intl.formatMessage({id: 'HOURS'})}
              </span>
            </div>
          </div>
        )
      })
    }
  }

  const abonementListRenderFunc = () => {
    if (abonementList) {
      return abonementList.map((item: any) => {
        // baurma
        const itemParkingGuid = item?.parkingUid

        return item?.list.map((abon: any) => {
          const itemAbonementId = abon.id
          return (
            <div
              className='w-100 h-200px d-flex flex-column mt-5 p-2 ProfileAbonementlist__radius bg-white'
              style={{fontSize: '16px'}}
              key={abon.id}
            >
              <div className='d-flex flex-column'>
                <div className='d-flex flex-row justify-content-between'>
                  <span className='ProfileAbonementlist__header'>
                    {intl.formatMessage({id: 'BILLING.BILLING-FORM.PARKING'})}
                  </span>
                  {abon.paid ? (
                    <span style={{fontWeight: '400', fontSize: '16px'}} className='text-success'>
                      {intl.formatMessage({id: 'MY.ABONEMENT.PAID'})}
                    </span>
                  ) : (
                    <span
                      style={{fontWeight: '400', fontSize: '16px'}}
                      className='text-danger cursor-pointer'
                      onClick={() => {
                        setParkingGuid(itemParkingGuid)
                        setAbonementId(itemAbonementId)
                        setModalShow(true)
                      }}
                    >
                      {intl.formatMessage({id: 'ABONEMENT.DELETE.CANCEL'})}
                    </span>
                  )}
                </div>
                <span className='ProfileAbonementlist__content'>{abon.parking}</span>
              </div>
              <div className='d-flex flex-column'>
                <span className='ProfileAbonementlist__header'>
                  {intl.formatMessage({id: 'ABONEMENT.SCHEDULE'})}
                </span>
                {abon.type === 'Все дни недели' && (
                  <span className='ProfileAbonementlist__content'>
                    {intl.formatMessage({id: 'ABONEMENT.ALL-WEEK-DAYS'})}
                  </span>
                )}
                {abon.customNumbers && scheduleFunc(abon.customNumbers)}
              </div>
              <div className='d-flex flex-column'>
                <span className='ProfileAbonementlist__header'>
                  {intl.formatMessage({id: 'ABONEMENT.END-TIME'})}
                </span>
                <span className='ProfileAbonementlist__time'>
                  {abon.begin} - {abon.end}
                </span>
              </div>
              <div className='d-flex flex-column'>
                <span className='ProfileAbonementlist__content'>
                  {abon.months} {intl.formatMessage({id: 'ABONEMENT.DAYS'})},{' '}
                  {intl.formatMessage({id: 'ABONEMENT.AMOUNT'})} - {abon.price}
                </span>
              </div>
            </div>
          )
        })
      })
    }
  }

  return (
    <div className='ProfileAbonementlist__parent__div'>
      <div className='ProfileAbonementlist__content__div p-2'>
        <AbonementDeleteModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          parkingGuid={parkingGuid}
          abonementId={abonementId}
          plateNumber={plateNumber}
          setAbonementList={setAbonementList}
          setParentErrorMessage={setErrorMessage}
        />
        <div className='w-100 mt-5 mb-5 h-25px d-flex align-items-center'>
          <div
            className='d-flex cursor-pointer  align-items-center justify-content-center h-30px w-30px'
            onClick={() => {
              navigate('/profile')
            }}
          >
            <img src={arrowLeft} alt='icon' />
          </div>
        </div>
        {/* car info block start */}
        <div className='fw-bolder mb-2 ParkingMenu__fontSize_big'>
          {intl.formatMessage({id: 'PAYMENT.CHOOSE-PARKING.ADD-CAR-INPUT'})}
        </div>
        <div className='w-100 d-flex flex-column mt-5 justify-content-center align-items-center'>
          <input
            className='w-100 h-50px ps-2 ProfileAbonementList__input__border'
            onChange={(e) => {
              setPlateNumber(e.target.value.toUpperCase())
            }}
            value={plateNumber}
            placeholder={intl.formatMessage({
              id: 'PAYMENT.CHOOSE-PARKING.PLATE-NUMBER-PLACEHOLDER',
            })}
          />
          {errorMessage === 'emptyAbon' && (
            <div className='w-100 mt-2' style={{color: 'red', fontSize: '20px'}}>
              {intl.formatMessage({id: 'ABONEMENT.NOT-FOUND'})}
            </div>
          )}
          {errorMessage === 'error' && (
            <div className='w-100 mt-2' style={{color: 'red', fontSize: '20px'}}>
              {intl.formatMessage({id: 'LOGIN.ERROR.SOMETHING-WENT-WRONG'})}
            </div>
          )}
          <button
            className='h-45px w-100 justify-content-center btn d-flex align-items-center mt-5'
            style={{borderRadius: '14px', background: '#127FEE', color: 'white'}}
            onClick={() => {
              if (!loader) {
                setErrorMessage('')
                setAbonementList([])
                carAbonementListFunc()
              }
            }}
            disabled={!plateNumber && loader}
          >
            {loader ? <BtnLoaderForm /> : intl.formatMessage({id: 'ABONEMENT.DELETE.GET'})}
          </button>
        </div>
        {/* car info block end */}
        {/* abon info block start */}
        {abonementList && (
          <div className='w-100 d-flex flex-column'>{abonementListRenderFunc()}</div>
        )}
        {/* abon info block end */}
      </div>
    </div>
  )
}

export {ProfileAbonementList}
