/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Languages} from "../../modules/languages/Languages";
import arrowLeft from '../../assets/ArrowLeft.svg'
import mainImage from '../../assets/add-car/mainPage.png'
import {logEvent} from "firebase/analytics";
import {analytics} from "../../App";
import tags from "../../assets/eventTags";
import {currentLocationAction, onboardingAction} from "../../../setup/redux/actions/actions";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {ShowCarForm} from "./components/ShowCarForm";
import ReactDOM from "react-dom";
import {LanguagePickerModal} from "../../modules/modal/language-picker/LanguagePickerModal";

export const AddCar: FC = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const fromParkingInfo: any = location.state;
    const [showCarForm, setShowCarForm] = useState(fromParkingInfo.fromCarsList || fromParkingInfo.fromParkingInfo ? true : false)
    const [showModal, setShowModal] = useState(false);
    console.log("From parking info: ", fromParkingInfo)
    function toggleModal() {
        setShowModal(!showModal);
    }
    return (
        <>
            {
                !showCarForm ?
                    <>
                        <div className='d-flex flex-column justify-content-between' style={{ height: '100%',padding:' 0 10px'}}>
                            <div className='d-flex justify-content-end'>
                                <Languages onLanguage={toggleModal} />
                            </div>
                            {showModal &&
                                ReactDOM.createPortal(
                                    <LanguagePickerModal onToggle={toggleModal} />,
                                    document.body
                                )}
                            <div className='d-flex flex-column align-items-center'>
                                <img src={mainImage} />
                                <div className='d-flex flex-column align-items-center' style={{gap:'10px'}}>
                            <span className='authorization_title text-center'>
                                {intl.formatMessage({id: 'CAR.TITLE'})}
                            </span>
                                    <span className='add_city_description text-center'
                                    >{intl.formatMessage({id: 'CAR.DESCRIPTION'})}
                            </span>
                                </div>
                            </div>
                            <div className='d-flex flex-column gap-3' style={{width: '100%'}}>
                                <button className='primary-button' onClick={() => setShowCarForm(true)}>{intl.formatMessage({id: 'CAR.ADD'})}</button>
                                <span
                                    onClick={() => navigate('/home-page', {state: {fromWhere: 'Parking'}})}
                                    style={{
                                        color: "#B0B5D0",
                                        fontSize: '16px',
                                        marginBottom: "36px",
                                        marginLeft: "16px",
                                    }}

                                    className='cursor-pointer me-4 d-flex justify-content-center align-items-center'
                                >
                        {intl.formatMessage({id: 'AUTH.BTN.SKIP'})}
                      </span>
                            </div>
                        </div>
                    </>
                    :
                    <ShowCarForm fromParkingInfo={fromParkingInfo ? fromParkingInfo.fromParkingInfo : false} parking={fromParkingInfo ? fromParkingInfo.parking : ''} fromCarsList={fromParkingInfo.fromCarsList} setShowCarForm={setShowCarForm} />
            }
        </>

    )
}
