import React, {FC, useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {useIntl} from 'react-intl'
import { Close } from '@material-ui/icons';
import {useLang, setLanguage} from "../../../../_metronic/i18n/Metronici18n";
import {isMobile} from "react-device-detect";

// import {setLanguage, useLang} from '../../../../_metronic/i18n/Metronici18n'



const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: isMobile ? '90%' : '350px',
        padding: '10px',
        background: "#FFFFFF",
        boxShadow: "0px 2.43056px 24.3056px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px"
    },
    body: {
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    closeButton: {
        padding: 0,
        minWidth: 'auto',
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.error.main
        }
    },
    button: {
        justifyContent: 'flex-start',
        width: '100%',
        color: '#cecdcd',
        backgroundColor: 'transparent',
        textTransform: 'capitalize',
        fontSize: '16px',
        fontFamily: 'SF Pro Display',
        '&:hover': {
            // backgroundColor: '#E9E9E9',
            color: '#157FFF',
            // transform: 'scale(1.1)',
            // transition: 'transform .2s ease-out'
        }
    },
    selected: {
        color: '#157FFF',
        // transform: 'scale(1.1)',
        // transition: 'transform .2s ease-out',
        // backgroundColor: '#E9E9E9',
    }
}));

type Props = {
    onToggle: () => void
}
export const LanguagePickerModal: FC<Props> = ({onToggle}) => {
    const intl = useIntl()
    const lang = useLang()
    const classes = useStyles();
    const [languages, setLanguages] = useState([
        {
            lang: 'en',
            name: intl.formatMessage({id: 'LANGUAGES.EN'}),
        },
        {
            lang: 'ru',
            name: intl.formatMessage({id: 'LANGUAGES.RU'}),
        },
        {
            lang: 'kk',
            name: intl.formatMessage({id: 'LANGUAGES.KZ'}),
        },
    ])
    const currentLanguage = languages.find((x: any) => x.lang === lang)
    const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
    const handleSelectLanguage = (language: any) => {
        setSelectedLanguage(language);
        console.log(language)
        setLanguage(language.lang)
        // onSelect(language);
    };
    useEffect(() => {
        // @ts-ignore
        const index = languages.findIndex((x: any) => x.lang === selectedLanguage.lang);
        const selectedLang = languages[index];
        const newLanguages = [...languages];
        newLanguages.splice(index, 1); // Remove selected language
        newLanguages.splice(Math.floor(newLanguages.length / 2), 0, selectedLang); // Insert at middle
        setLanguages(newLanguages);
        setSelectedLanguage(selectedLanguage);
    }, []);

    return (
        <Modal
            className={classes.modal}
            open={true}
            onClose={() => onToggle()}
        >
            <div className={classes.paper}>
                <div className={classes.header}>
                    <h1 id="simple-modal-title" style={{fontFamily: 'SF Pro Display'}}>{intl.formatMessage({id: 'TRANSLATOR.SELECT'})}</h1>
                    <Button className={classes.closeButton} onClick={onToggle}>
                        <Close />
                    </Button>
                </div>
                <div className={classes.body}>
                    {languages.map((language: any) => (
                        <Button
                            className={`${classes.button} ${selectedLanguage === language ? classes.selected : ''}`}
                            style={{ justifyContent: 'flex-start' }}
                            key={language.lang}
                            onClick={() => handleSelectLanguage(language)}
                        >
                            {language.name}
                        </Button>
                    ))}
                </div>
            </div>
        </Modal>
    );
}
