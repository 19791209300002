import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {Footer} from './components/Footer'
import {Header} from './components/header/Header'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'

import './MasterLayout.css'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-row flex-column-fluid'>
        <div
          className='d-flex flex-column flex-row-fluid MasterLayout__content_div'
          id='kt_wrapper'
        >
          <div className='MasterLayout__mobile_view w-100'>
            <Header />
          </div>
          <div id='kt_content' className='d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <div className='MasterLayout__mobile_view w-100'>
            <Footer />
          </div>
        </div>
      </div>
    </PageDataProvider>
  )
}

export {MasterLayout}
