const userLocationReducer = (
    state:any = null,
    action:any
) => {
    const {type, payload} = action
    switch (type) {
        case 'SET_USER_LOCATION':
            return payload
        default:
            return state 
    }
}

export {userLocationReducer}