import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import arrowRight from '../../../../assets/ArrowRight.svg'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {billingFormAction} from '../../../../../setup/redux/actions/actions'

import {BsCreditCard} from 'react-icons/bs'
import {CardLoaderForm} from '../../billing-form/components/BillingLoaderForm'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../../../App'
import tags from '../../../../assets/eventTags'
import axios from 'axios'
import { discount } from '../../../../../setup/endpoints'



type Props = {
  cardPayFunc?: any
  cardLoader?: boolean
  setCardLoader?: any
  visaLogo?: any
}
const apiBaseUrl = process.env.REACT_APP_API_PARQOUR

const VisaPay: FC<Props> = ({cardPayFunc, cardLoader, setCardLoader, visaLogo}) => {
  const intl = useIntl()
  const [data, setData] = useState<{[key: string]: any}>({});
  const dispatch = useDispatch()
  const loggedIn = useSelector((state: RootState) => state.token)
  const parkingGuid = useSelector((state: RootState) => state.parkingGuid)
  
  useEffect(() => {
    getDiscount("VISA", parkingGuid);
  }, [])
  const getDiscount: any = async (type: string, parkingUid: number) => {
    const response:any =  await axios.get(apiBaseUrl + discount, {
      params: {
        cardType: type,
        parkingUid: parkingUid
      }
    }).then(res => {
      setData(res.data);
    }).catch(err => console.log(err));
    
  }
 
  
  const payFunc = () => {
    if (loggedIn) {
      setCardLoader(true)
      cardPayFunc()
    } 
    else {
      dispatch(billingFormAction('auth'))
    }
  }

  return (
    <div className='w-100 h-100' onClick={() => {
      console.log("VISA Payment Selected");
      logEvent(analytics, tags.pay_by_card, {
        card: "VISA"
      })
    }}>
      {cardLoader ? (
        <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <div className='w-50px h-40px'>
            <CardLoaderForm />
          </div>
        </div>
      ) : (
        <div
          className='d-flex flex-row justify-content-between w-100'
          onClick={() => {
            if (!cardLoader) {
              payFunc()
            }
          }}
        >
          <div className='h-100 w-50px d-flex justify-content-center align-items-center'>
            <img src={visaLogo} style={{height: '35px', paddingRight: '5px', width: '45px', color: '#127FEE'}} />
          </div>
          <div className='fs-5 fw-bolder w-100 h-100 justify-content-between d-flex align-items-center text-dark rounded cursor-pointer'>
            <div className='d-flex flex-column ps-4'>
              <span className=' PaymentResult__span_bigText'>
                {intl.formatMessage({id: 'BILLING.PAYMENT-METHODS.CARD'})}
              </span>
              <span className='PaymentResult__span_smallText'>
                {intl.formatMessage({id: 'BILLING.PAYMENT-METHODS.PAYMENTS'})}
              </span>
              <span className='PaymentResult__span_smallText'>
                {intl.formatMessage({id: 'BILLING.PAYMENT-METHODS.VISA-DISCOUNT'}, {discount:  new Date(data.endDate) > new Date(Date.now())  && data.discount + "%"})}
              </span>
            </div>

            <img src={arrowRight} className='PaymentResult__span_icon' />
          </div>
        </div>
      )}
    </div>
  )
}

export {VisaPay}
