import {FC} from 'react'
import {useIntl} from 'react-intl'
import calendarImg from '../../assets/calendar.svg'
import timepickerImg from '../../assets/timepicker.svg'

import './AbonementInformationMobile.css'

const AbonementMobileRu: FC = () => {
  const intl = useIntl()

  return (
    <div className='d-flex flex-column justify-content-center p-5'>
      <div className='d-flex flex-column w-100 mb-4'>
        <span className='AbonementInformationModal__text_big'>Абонентская плата - это:</span>
        <div className='d-flex flex-column AbonementInformationModal__text_small'>
          <p>1. Экономия времени в поисках места на парковке</p>
          <p>2. Возможность выбрать удобный для Вас тариф</p>
          <p>3. Разовая оплата вместо каждодневных оплат у паркомата</p>
          <p>4. Выгодное приобретение абонемента для вашего авто</p>
          <p>
            ВАЖНО! Каждая парковка ставит свою тарификацию. Для подробной информации, обращаться к
            администрации выбранной парковки
          </p>
        </div>
      </div>
      <div className='d-flex flex-column w-100'>
        <span className='AbonementInformationModal__text_big'>Как это работает?</span>
        <span className='AbonementInformationModal__text_small mt-2'>1. Выберите дату начала</span>
        <div className='w-100 d-flex justify-content-center mt-4'>
          <img src={calendarImg} style={{width: '50%'}} alt={'Calendar image'} />
        </div>
        <span className='AbonementInformationModal__text_small mt-2'>
          2. Выберите время начала абонемента
        </span>
        <div className='w-100 d-flex justify-content-center mt-4'>
          <img src={timepickerImg} style={{width: '50%'}} alt={'timepicker'} />
        </div>
        <span className='AbonementInformationModal__text_small'>3. Оплатите</span>
      </div>
    </div>
  )
}

export {AbonementMobileRu}
