import {FC} from 'react'

const Privacy: FC = () => {
  return (
    <div className='d-flex flex-column w-100 h-100 fw-5 '>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: 'normal',
          background: 'white',
          verticalAlign: 'baseline',
        }}
      >
        <strong>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
            Политика конфиденциальности
          </span>
        </strong>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: 'normal',
          background: 'white',
          verticalAlign: 'baseline',
        }}
      >
        <strong>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
            Версия от 01.07.2022 года.
          </span>
        </strong>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <span style={{fontSize: '15px', color: 'black'}}>
          Настоящая политика конфиденциальности распространяет свое действие на всех лиц (
        </span>
        <span style={{fontSize: '15px', color: 'black'}}>далее&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>-&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>
          «<strong>Пользователь</strong>»
        </span>
        <span style={{fontSize: '15px', color: 'black'}}>)</span>
        <span style={{fontSize: '15px', color: 'black'}}>,&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>использующих&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>ресурс</span>
        <span style={{fontSize: '15px', color: 'black'}}>ы</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;ТОО «Smart</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>Parking</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>Technologies»</span>
        <span style={{fontSize: '15px', color: 'black'}}>
          , БИН 180640014343 (далее – «<strong>Компания</strong>»)
        </span>
        <span style={{fontSize: '15px', color: 'black'}}>, в том числе&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>веб</span>
        <span style={{fontSize: '15px', color: 'black'}}>-сайт&nbsp;</span>
        <a href='https://parqour.com/'>
          <span style={{fontSize: '15px'}}>https://parqour.com/</span>
        </a>
        <span style={{fontSize: '15px', color: 'black'}}>,&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>мобильно</span>
        <span style={{fontSize: '15px', color: 'black'}}>е</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;приложени</span>
        <span style={{fontSize: '15px', color: 'black'}}>е</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;«Parqour»</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>
          и иные ресурсы Компании (далее – «Ресурсы Компании»). &nbsp;
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: 'normal',
          textAlign: 'justify',
          background: 'white',
        }}
      >
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>Компания&nbsp;</span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>
          является собственником и оператором Базы персональных данных пользователей&nbsp;
        </span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>
          Ресурсов Компании.
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: 'normal',
          textAlign: 'justify',
        }}
      >
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>Настоящая&nbsp;</span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>п</span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>
          олитика описывает установленный&nbsp;
        </span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>Компанией&nbsp;</span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>
          порядок обработки персональных данных, собранных с помощью&nbsp;
        </span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>
          Ресурсов Компании&nbsp;
        </span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>
          и связанных с ним
        </span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>и</span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>
          &nbsp;услуг и инструментов. Во всех указанных случаях&nbsp;
        </span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>Компания&nbsp;</span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>
          обрабатывает персональные данные пользователей исключительно в рамках требований Закон
        </span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>а</span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>
          &nbsp;Республики Казахстан от 21 мая 2013 года № 94-V «О персональных данных и их защите».
          Данная Политика конфиденциальности разработана в соответствии с их нормами
        </span>
        <span style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>.</span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: 'normal',
          textAlign: 'justify',
          background: 'white',
          verticalAlign: 'baseline',
        }}
      >
        <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
          Использование Сайта означает выражение Пользователем безоговорочного согласия с Политикой
          и указанными условиями обработки информации.
        </span>
        <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>&nbsp;</span>
        <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
          Пользователь не должен пользоваться Сайтом, если Пользователь не согласен с условиями
          Политики.
        </span>
      </p>
      <ol className='decimal_type' style={{listStyleType: 'decimal', marginLeft: '1cmundefined'}}>
        <li>
          <strong>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
              Персональная информация Пользователей
            </span>
          </strong>
          <ol className='decimal_type' style={{listStyleType: 'decimal'}}>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
                Ресурсы Компании собирают
              </span>
              <span style={{fontFamily: '"Times New Roman",serif'}}>, получа</span>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>ю</span>
              <span style={{fontFamily: '"Times New Roman",serif'}}>т доступ и использу</span>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>ю</span>
              <span style={{color: '#222222'}}>
                т в определенных Политикой целях персональные данные Пользователей, техническую и
                иную информацию, связанную с Пользователями.
              </span>
            </li>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Техническая информация не является персональными данными. Компания использует файлы
                cookies, которые позволяют идентифицировать Пользователя.
              </span>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>&nbsp;</span>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Файлы cookies – это текстовые файлы, доступные Компании, для обработки информации об
                активности Пользователя, включая информацию о том, какие страницы посещал
                Пользователь и о времени, которое Пользователь провел на странице.
              </span>
              <span style={{color: '#222222'}}>&nbsp;</span>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
                Пользователь может отключить возможность использования файлов cookies в настройках
                браузера.
              </span>
            </li>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
                Под&nbsp;
              </span>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                технической информацией понимается&nbsp;
              </span>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
                в том числе&nbsp;
              </span>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
                информация, которая автоматически передается Компании в процессе использования Сайта
                с помощью установленного на устройстве Пользователя программного обеспечения
              </span>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
                , а также данные,&nbsp;
              </span>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                которые невозможно однозначно идентифицировать с конкретным Пользователем (например:
                статистика посещаемости сайта).
              </span>
            </li>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Под персональными данными Пользователя понимается информация, которую Пользователь
                предоставляет Компании при заполнении&nbsp;
              </span>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                регистрационных данных&nbsp;
              </span>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                и последующем использовании Сайта
              </span>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
                &nbsp;и&nbsp;
              </span>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                содержащую персональные данные Пользователя, а также информацию о действиях,
                совершаемых конкретным Пользователем на Ресурсах.
              </span>
            </li>
          </ol>
        </li>
      </ol>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: 'normal',
          textAlign: 'justify',
          background: 'white',
          verticalAlign: 'baseline',
        }}
      >
        <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
          Обязательная для предоставления Компании информация помечена специальным образом.
          <br />
          &nbsp;Иная информация предоставляется Пользователем на его усмотрение.
        </span>
      </p>
      <div
        style={{
          marginTop: '0cm',
          marginRight: '0cm',
          marginBottom: '8.0pt',
          marginLeft: '0cm',
          lineHeight: '107%',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        <ol style={{marginBottom: '0cm', listStyleType: 'decimal'}}>
          <li
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '8.0pt',
              marginLeft: '0cm',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{fontFamily: '"Times New Roman",serif'}}>
              Компания не проверяет достоверность информации, предоставляемой Пользователем, и не
              имеет возможности оценивать его дееспособность.
            </span>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>&nbsp;</span>
            <span style={{fontFamily: '"Times New Roman",serif'}}>
              Компания исходит из того, что Пользователь предоставляет достоверную персональную
              информацию о себе и поддерживает&nbsp;
            </span>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>её&nbsp;</span>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
              в актуальном состоянии.
            </span>
          </li>
        </ol>
      </div>
      <ol className='decimal_type' style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
        <li>
          <strong>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
              Цели обработки персональной информации Пользователей
            </span>
          </strong>
          <ol className='decimal_type' style={{listStyleType: 'undefined'}}>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Главная цель Компании при сборе персональных данных — предоставление&nbsp;
              </span>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
                Пользователям платформы для оплаты услуг парковки, предоставляемых Пользователям
                партнерами Компании.&nbsp;
              </span>
            </li>
          </ol>
        </li>
      </ol>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: '107%',
          textAlign: 'justify',
        }}
      >
        <span style={{fontSize: '15px', color: 'black'}}>
          Помимо вышеуказанной цели Компания&nbsp;
        </span>
        <span style={{fontSize: '15px', color: 'black'}}>использует персональн</span>
        <span style={{fontSize: '15px', color: 'black'}}>ые</span>
        <span style={{fontSize: '15px', color: 'black'}}>&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>данные&nbsp;</span>
        <span style={{fontSize: '15px', color: 'black'}}>Пользовател</span>
        <span style={{fontSize: '15px', color: 'black'}}>ей в целях</span>
        <span style={{fontSize: '15px', color: 'black'}}>:</span>
      </p>
      <ul style={{listStyleType: 'disc', marginLeft: '1cmundefined'}}>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            регистрации Пользователя на ресурсах&nbsp;
          </span>
          <span style={{fontSize: '15px', color: 'black'}}>Компании</span>
          <span style={{fontSize: '15px', color: 'black'}}>;</span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            выполнения своих обязательств перед Пользователем;
          </span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            оценки и анализа работы ресурсов&nbsp;
          </span>
          <span style={{fontSize: '', color: 'black'}}>Компании</span>
          <span style={{fontSize: '15px', color: 'black'}}>;</span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>
            проведения анализа и измерения скачивания Пользователями мобильного приложения
          </span>
          <span style={{fontSize: '15px', color: 'black'}}>,</span>
          <span style={{fontSize: '15px', color: 'black'}}>&nbsp;</span>
          <span style={{fontSize: '15px', color: 'black'}}>
            статистики на Ресурсах Компании,&nbsp;
          </span>
          <span style={{fontSize: '15px', color: 'black'}}>а также их поведения внутри&nbsp;</span>
          <span style={{fontSize: '15px', color: 'black'}}>Ресурсов Компании&nbsp;</span>
          <span style={{fontSize: '15px', color: 'black'}}>и т.д.</span>
        </li>
        <li>
          <span style={{fontSize: '15px', color: 'black'}}>у</span>
          <span style={{fontSize: '15px', color: '#222222'}}>лучшени</span>
          <span style={{fontSize: '15px', color: '#222222'}}>я</span>
          <span style={{fontSize: '15px', color: '#222222'}}>&nbsp;качества&nbsp;</span>
          <span style={{fontSize: '15px', color: '#222222'}}>Ресурсов</span>
          <span style={{fontSize: '15px', color: '#222222'}}>
            , удобства их использования, разработка и развитие&nbsp;
          </span>
          <span style={{fontSize: '15px', color: '#222222'}}>Ресурсов Компании</span>
          <span style={{fontSize: '15px', color: '#222222'}}>
            , устранения технических неполадок или проблем с безопасностью.
          </span>
          <ol className='decimal_type' style={{listStyleType: 'undefined'}}>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Компания использует техническую информацию обезличено в целях, указанных в пункте
                2.1.
              </span>
            </li>
          </ol>
        </li>
      </ul>
      <ol className='decimal_type' style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
        <li>
          <strong>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
              Условия и способы обработки персональной информации
            </span>
          </strong>
          <strong>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>&nbsp;</span>
          </strong>
          <strong>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
              Пользователей
            </span>
          </strong>
          <ol className='decimal_type' style={{listStyleType: 'undefined'}}>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Пользователь дает согласие на обработку своих персональных данных путём&nbsp;
              </span>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
                совершения действий (оплаты, регистрации, скачивания мобильного приложения) на
                Ресурсах Компании в целях указанных в настоящей Политике на весь срок использования
                Пользователем Ресурсов Компании и до момента достижения Компанией целей обработки
                персональных данных
              </span>
              <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>.</span>
            </li>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Обработка персональных данных Пользователя означает сбор, запись, систематизацию,
                накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
                передачу (распространение, предоставление, доступ), обезличивание, блокирование,
                удаление, уничтожение персональных данных Пользователя.
              </span>
            </li>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                В отношении персональной информации Пользователя сохраняется ее конфиденциальность,
                кроме случаев добровольного предоставления Пользователем информации о себе для
                общего доступа неограниченному кругу лиц.
              </span>
            </li>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Компания вправе передать персональную информацию Пользователя третьим лицам в
                следующих случаях:
              </span>
            </li>
          </ol>
        </li>
      </ol>
      <ul style={{listStyleType: 'disc', marginLeft: '1cmundefined'}}>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
            Пользователь выразил согласие на такие действия;
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
            Передача необходима для использования Пользователем определенной услуги&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
            на Ресурсах Компании&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>
            либо для исполнения определенного договора или соглашения с Пользователем;
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
            Передача уполномоченным органам государственной власти Республики Казахстан по
            основаниям и в порядке, установленным законодательством Республики Казахстан;
          </span>
        </li>
        <li>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
            В результате обработки персональной информации Пользователя путем ее обезличивания
            получены обезличенные статистические данные, передаются третьему лицу для проведения
            исследований, выполнения работ или оказания услуг по поручению Компании.
          </span>
        </li>
      </ul>
      <ol className='decimal_type' style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
        <li>
          <strong>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
              Меры, применяемые для защиты персональной информации Пользователя
            </span>
          </strong>
          <ol className='decimal_type' style={{listStyleType: 'undefined'}}>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Компания принимает необходимые и достаточные правовые, организационные и технические
                меры для защиты персональной информации Пользователя от неправомерного или
                случайного доступа, уничтожения, изменения, блокирования, копирования,
                распространения, а также от иных неправомерных действий с ней третьих лиц.
              </span>
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#222222'}}>
              Иные положения
            </span>
          </strong>
          <ol className='decimal_type' style={{listStyleType: 'undefined'}}>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Все споры и разногласия, вытекающие из правоотношений по настоящей Оферте,
                разрешаются путем переговоров. В случае невозможности решения возникших споров в
                ходе переговоров, такие споры должны быть переданы на решение в суд Республики
                Казахстан, города Алматы с обязательным соблюдением претензионного порядка. Срок
                ответа на претензию составляет 10 (десять) рабочих дней.
              </span>
            </li>
            <li>
              <span style={{fontSize: '15px', color: 'black'}}>Компания&nbsp;</span>
              <span style={{fontSize: '15px', color: 'black'}}>
                имеет право в любой момент изменять условия настоящ
              </span>
              <span style={{fontSize: '15px', color: 'black'}}>ей Политики&nbsp;</span>
              <span style={{fontSize: '', color: 'black'}}>
                в одностороннем порядке без предварительного согласования с Пользователями,
                обеспечивая при этом публикацию измененных условий на&nbsp;
              </span>
              <span style={{fontSize: '15px', color: 'black'}}>Р</span>
              <span style={{fontSize: '15px', color: 'black'}}>есурсах&nbsp;</span>
              <span style={{fontSize: '15px', color: 'black'}}>Компании</span>
              <span style={{fontSize: '15px', color: 'black'}}>.&nbsp;</span>
            </li>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Ресурсы могут содержать ссылки на другие сайты, не имеющие отношения к Компании и
                принадлежащие третьим лицам. Компания не несет ответственности за точность, полноту
                и достоверность сведений, размещенных на сайтах третьих лиц, и не берет на себя
                никаких обязательств по сохранению конфиденциальности информации, оставленной
                Пользователем на таких сайтах.
              </span>
            </li>
            <li>
              <span style={{fontFamily: '"Times New Roman",serif'}}>
                Компания делает все возможное для соблюдения настоящей политики конфиденциальности,
                однако, Компания не может гарантировать сохранность информации в случае воздействия
                факторов, находящихся вне влияния Компании, результатом действия которых станет
                раскрытие информации. Ресурсы и вся размещенная на них информация представлены по
                принципу «как есть” без каких-либо гарантий. Компания не несет ответственности за
                неблагоприятные последствия, а также за любые убытки, причиненные вследствие
                ограничения доступа к Ресурсам или вследствие посещения Ресурсов и использования
                размещенной на нем информации.
              </span>
            </li>
            <li>
              <span style={{fontSize: '15px', color: 'black'}}>
                Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение
                обязательств на время действия непреодолимой силы. Под непреодолимой силой
                понимаются чрезвычайные и непреодолимые обстоятельства, препятствующие исполнению
                своих обязательств&nbsp;
              </span>
              <span style={{fontSize: '15px', color: 'black'}}>С</span>
              <span style={{fontSize: '15px', color: 'black'}}>
                торонами. К ним относятся стихийные явления; воздействие ядерного взрыва, радиации
                (ионизирующего излучения) или радиоактивного заражения материалами ядерного оружия,
                производственных и научно-исследовательских работ или радиоактивными отходами;
                войны, военные вторжения, враждебные действия иностранного врага, военные маневры и
                связанные с ними мероприятия военного характера; гражданская война, вооруженный
                мятеж, народные волнения всякого рода, массовые беспорядки, насильственный захват
                или насильственное удержание власти; эпидемии, метеоусловия, террористические
                действия, забастовки, изъятия, конфискации, национализация и т.п. При этом
                общеизвестные события не нуждаются в каком-либо подтверждении.
              </span>
            </li>
            <li>
              <span style={{fontSize: '15px', color: 'black'}}>
                Пользователь имеет право обратиться к&nbsp;
              </span>
              <span style={{fontSize: '15px', color: 'black'}}>Компании&nbsp;</span>
              <span style={{fontSize: '15px', color: 'black'}}>
                или в суд за защитой своих нарушенных прав и законных интересов. Контактные данные
                (адреса и номера телефона) уполномоченного органа и субъектов досудебного
                урегулирования потребительских споров&nbsp;
              </span>
              <a href='https://www.gov.kz/memleket/entities/mti-kzpp/contacts?lang=ru'>
                <span style={{fontSize: '15px'}}>
                  https://www.gov.kz/memleket/entities/mti-kzpp/contacts?lang=ru
                </span>
              </a>
              <span style={{fontSize: '15px', color: 'black'}}>.</span>
            </li>
          </ol>
        </li>
      </ol>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: 'normal',
          textAlign: 'justify',
        }}
      >
        <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          marginTop: '0cm',
          marginBottom: '6.0pt',
          lineHeight: 'normal',
          textAlign: 'justify',
        }}
      >
        <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
      </p>
    </div>
  )
}

export {Privacy}
