import {FC} from 'react'
import {VscError} from 'react-icons/vsc'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {addParkingNameAction, unauthCarAdd} from '../../../../../setup/redux/actions/actions'

import './PaymentResult.css'
import errorIcon from "../../../../assets/error_frame.png";

const PaymentErrorForm: FC = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  return (
      <>
          <div className='d-flex flex-column p-10 pt-20 h-100' >
              <img src={errorIcon} />
              <div className='d-flex flex-column justify-content-between h-100'>
                  <div className='d-flex flex-column gap-3 align-items-center' style={{height: '40%'}}>
                      <span className='authorization_title'>{intl.formatMessage({id: 'ERROR.TITLE'})}</span>
                      <span className='authorization_description'>{intl.formatMessage({id: 'ERROR.DESCRIPTION'})}</span>
                  </div>

                  <div className='d-flex flex-column gap-3 mb-5' style={{height: '60%'}}>
                      <button className='primary-button' onClick={() => navigate('/home-page')}>{intl.formatMessage({id: 'ERROR.TRY.AGAIN'})}</button>
                      {/*<button className='secondary-button'>Изменить метод оплаты</button>*/}
                      <button className='secondary-button' onClick={() => navigate('/home-page')} >{intl.formatMessage({id: 'BTNS.CANCEL'})}</button>
                  </div>
              </div>

          </div>
      </>
    // <div className='PaymentResult__div_parent mt-10'>
    //   <div className='PaymentResult__div_content shadow'>
    //     <span className='PaymentResult__icon PaymentResult__icon_error'>
    //       <VscError />
    //     </span>
    //     <span className='PaymentResult__span_text text-center w-100'>
    //       {intl.formatMessage({id: 'PAYMENT.ERROR.ERROR'})}
    //     </span>
    //     <span>{intl.formatMessage({id: 'PAYMENT.ERROR.TRY-AGAIN'})}</span>
    //     <button
    //       className='mt-8 btn btn-primary'
    //       onClick={() => {
    //         dispatch(unauthCarAdd(''))
    //         dispatch(addParkingNameAction(''))
    //         navigate('/home-page')
    //       }}
    //     >
    //       {intl.formatMessage({id: 'BTNS.BACK'})}
    //     </button>
    //   </div>
    // </div>
  )
}

export {PaymentErrorForm}
