const cityLatFromStorage = localStorage.getItem('cityLat')

const cityLatReducer = (
    state:any = cityLatFromStorage ? cityLatFromStorage : 43.24764057651702,
    action:any
) => {
    const { type, payload } = action;

    !cityLatFromStorage && localStorage.setItem('cityLat', '43.24764057651702')

    switch (type) {
        case "SET_CITY_LAT":
            !cityLatFromStorage && localStorage.setItem('cityLat', payload)
            return payload;
        default:
            return state;
    }
};

export {cityLatReducer}