
const showPlateNumberHintReducer = (
  state = false,
  action: any
) => {
  const {type, payload} = action
  switch (type) {
    case 'SHOW_PLATE_NUMBER_HINT':
      return payload
    default:
      return state
  }
}

export {showPlateNumberHintReducer}