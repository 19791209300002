import {FC, useEffect} from 'react'
import {useSelector} from 'react-redux'

import {ParkingBottomMenuFastPayment} from './fast-payment/ParkingBottomMenuFastPayment'
import {ParkingBottomMenuAbonementCalendar} from '../abonement/abonement-calendar-menu/ParkingBottomMenuAbonementCalendar'
import {LoginRegistration} from '../../../../../../pages/authorization/components/login-registration/LoginRegistration'
import {RootState} from '../../../../../../../setup'

import '../ParkingMenu.css'

type Props = {
  plateNumber: string
  setPlateNumber: any
  parking: string
  parkingGuid: string
  parkingListFunc: any
  modalShow: boolean
  setModalShow: any
  setChooseParkingForm: any
  abonement: any
  setAbonement: any
  serviceId: any,
  city?: any
}

const ParkingBottomMenu: FC<Props> = ({
  plateNumber,
  setPlateNumber,
  parking,
  parkingListFunc,
  modalShow,
  setModalShow,
  setChooseParkingForm,
  abonement,
  setAbonement,
  serviceId,
  city
}) => {
  const activeMenu = useSelector((state: RootState) => state.chooseParkingForm)

  useEffect(() => {
    if (activeMenu === 'fastPaymentMenu') {
      setAbonement(false)
    }
    if (activeMenu === 'abonementNumberParkingMenu') {
      setAbonement(true)
    }
  }, [activeMenu])

  switch (activeMenu) {
    case 'fastPaymentMenu':
      return (
        <ParkingBottomMenuFastPayment
          modalShow={modalShow}
          setModalShow={setModalShow}
          plateNumber={plateNumber}
          setPlateNumber={setPlateNumber}
          parking={parking}
          parkingListFunc={parkingListFunc}
          setChooseParkingForm={setChooseParkingForm}
          serviceId={serviceId}
          abonement={abonement}
          city={city}
        />
      )
    case 'abonementCalendarMenu':
      return <ParkingBottomMenuAbonementCalendar setChooseParkingForm={setChooseParkingForm} />
    case 'login':
      return <LoginRegistration form={'abonementPage'} />
    default:
      return (
        <ParkingBottomMenuFastPayment
          modalShow={modalShow}
          setModalShow={setModalShow}
          plateNumber={plateNumber}
          setPlateNumber={setPlateNumber}
          parking={parking}
          parkingListFunc={parkingListFunc}
          setChooseParkingForm={setChooseParkingForm}
          serviceId={serviceId}
          abonement={abonement}
          city={city}
        />
      )
  }
}

export {ParkingBottomMenu}
