import {FC} from 'react'
import './Components.css'

export const LoaderForm: FC = () => {
  return (
    <div className='LoaderForm__content_div'>
      <div className='LoaderForm__loader border-top border-bottom border-primary '>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export const CardLoaderForm: FC = () => {
  return (
    <div className='LoaderForm__content_div'>
      <div className='CardLoaderForm__loader border-top border-bottom border-primary '>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export const BtnLoaderForm: FC = () => {
  return (
    <div className='LoaderForm__content_div'>
      <div className='BtnLoaderForm__loader border-top border-bottom border-primary '>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export const BtnLoaderFormBlue: FC = () => {
  return (
    <div className='LoaderForm__content_div'>
      <div className='BtnLoaderForm__loaderBlue border-top border-bottom border-primary '>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
