const tokenFromStorage = localStorage.getItem('accessToken')

const authReducer = (
    state:any = tokenFromStorage ? tokenFromStorage : null,
    action:any
) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_ACCESS_TOKEN":
            return payload;
        default:
            return state;
    }
};

export {authReducer}