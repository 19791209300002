import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'

import {useLayout} from '../../core'
import {toAbsoluteUrl} from '../../../helpers'

import {addParkingNameAction} from '../../../../setup/redux/actions/actions'
import {HeaderContent} from './components/header-content/HeaderContent'

import './Header.css'

export const Header = () => {
  const {classes, attributes} = useLayout()

  const dispatch = useDispatch()

  return (
    <div
      id='kt_header'
      className='Header-parent-div d-flex align-items-center align-items-stretch border-bottom border-secondary'
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        <div
          className='menu-item me-lg-1 mt-2'
          onClick={() => {
            dispatch(addParkingNameAction(''))
          }}
        >
          <Link className={clsx('py-3 ')} to={'/'}>
            <img
              className='h-50px logo Header-show'
              src={toAbsoluteUrl('/media/logos/parqour-logo-blue.svg')}
              alt='Parqour Logo'
            />
            <img
              className='h-50px logo SmallHeader-show'
              src={toAbsoluteUrl('/media/logos/parqour-logo-blue-small.svg')}
              alt='Parqour Logo'
            />
          </Link>
        </div>
        <div className='HeaderContent align-items-stretch  justify-content-end flex-lg-grow-1'>
          <HeaderContent div={'flex-row'} />
        </div>
      </div>
    </div>
  )
}
