import React from "react";

export const HistoryIcons: React.FC<{ color: string }> = (props) => {
    return (
        <>
            <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.175 12.8405C14.0995 11.9394 14.7291 10.7913 14.9841 9.54145C15.2392 8.29159 15.1082 6.99608 14.6078 5.81876C14.1074 4.64143 13.26 3.63516 12.1729 2.92719C11.0857 2.21922 9.80752 1.84134 8.5 1.84134C7.19248 1.84134 5.91431 2.21922 4.82714 2.92719C3.73996 3.63516 2.8926 4.64143 2.3922 5.81876C1.8918 6.99608 1.76084 8.29159 2.01587 9.54145C2.27091 10.7913 2.90049 11.9394 3.825 12.8405L8.5 17.3969L13.175 12.8405ZM8.5 20L2.48956 14.1421C1.30082 12.9835 0.491287 11.5074 0.163321 9.90041C-0.164645 8.29343 0.0036898 6.62774 0.647037 5.11399C1.29039 3.60025 2.37985 2.30643 3.77767 1.39614C5.17548 0.485861 6.81886 0 8.5 0C10.1811 0 11.8245 0.485861 13.2223 1.39614C14.6201 2.30643 15.7096 3.60025 16.353 5.11399C16.9963 6.62774 17.1646 8.29343 16.8367 9.90041C16.5087 11.5074 15.6992 12.9835 14.5104 14.1421L8.5 20ZM9.44444 8.2842H13.2222V10.1251H7.55556V3.68183H9.44444V8.2842Z" fill={props.color} />
            </svg>


        </>
    );
};