import { abonementCreate, abonementDelete, abonementTypes, carAbonement } from "../endpoints"
import customAxios from "./CustomAxios"

export const axiosGetAbonementInfo = (parkingGuid:string, locale:string) => {
    let config:any = {
        method: 'post',
        url: abonementTypes + parkingGuid + '?languageCode='+ locale,
      }

    return customAxios(config).then((resp) =>{
      return {code: resp.status, message: resp.data}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}

export const axiosAbonementCreate = (
  platenumber: string,
  parkingId: string,
  innerParkingId: string,
  typeId: string,
  dateStart: string
) => {

  let data:any = {
    platenumber,
    parkingId,
    innerParkingId,
    typeId,
    dateStart
  }

  let config:any = {
    method: 'post',
    url: abonementCreate,
    data
  }

  return customAxios(config).then((resp) => {
    return {code: resp.status, message: resp.data.result}
  }).catch((err) => {
    console.log(err)
    return {code: err.response.status, message: 'error'}
  })
}

export const axiosCarAbonement = (plateNumber:string) => {
  let config:any = {
    method: 'get',
    url: carAbonement + plateNumber
  }

  return customAxios(config).then((resp) => {
    return {code: resp.status, message: resp.data}
  }).catch((err) => {
    return {code: err.response.status, message: 'error'}
  })
}

export const axiosAbonementDelete = (parkingGuid: string, abonementId: string) => {
  let data:any = {
    parkingId: parkingGuid,
    abonementId
  }

  let config:any = {
    method: 'post', 
    url: abonementDelete,
    data
  }

  return customAxios(config).then((resp) => {
    return {code: resp.status, message: resp.data.result}
  }).catch((err) => {
    console.log(err)
    return {code: err.response.status, message: err.response.data}
  })
}