import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {axiosLogout} from '../../../../setup/axios/AxiosAuth'
import {accessTokenAction} from '../../../../setup/redux/actions/actions'
import {LoaderForm} from '../../forms/billing-form/components/BillingLoaderForm'

type Props = {
  modalShow: boolean
  setModalShow: any
}

const UserLogoutModal: FC<Props> = ({modalShow, setModalShow}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [errorMessage, setErrorMessage] = useState('')
  const [loader, setLoader] = useState(false)

  const dispatch = useDispatch()

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered

      >
        <Modal.Body style={{width: '280px', height: '100px', padding: '0'}}>
          {!loader && (
            <div className='d-flex flex-column h-100px justify-content-center'>
              <div className='w-100 flex-column justify-content-between align-items-start fs-3  d-flex'>
                  {intl.formatMessage({id: 'PROFILE.ACCOUNT.LOGOUT-MODAL-TITLE'})}
                  <span className='authorization_description'>{intl.formatMessage({id: 'PROFILE.ACCOUNT.LOGOUT-MODAL'})}</span>

              </div>

              {errorMessage === 'error' && (
                <div>{intl.formatMessage({id: 'LOGIN.ERROR.SOMETHING-WENT-WRONG'})}</div>
              )}

              <div className='d-flex flex-row'>
                <button
                  className='h-40px w-50 justify-content-center btn d-flex align-items-center mt-5 me-2'
                  style={{
                    borderRadius: '14px',
                    background: '#127FEE',
                    color: 'white',
                    backgroundColor: 'rgb(18,127,238)',
                  }}
                  onClick={() => {
                    axiosLogout()
                    dispatch(accessTokenAction(''))
                      navigate('/auth')
                  }}
                >
                  {intl.formatMessage({id: 'ABONEMENT.DELETE.YES'})}
                </button>
                <button
                  className='h-40px w-50 justify-content-center btn d-flex align-items-center mt-5 ms-2'
                  style={{
                    borderRadius: '14px',
                    background: '#127FEE',
                    color: 'white',
                    backgroundColor: '#9DCAF8',
                  }}
                  onClick={() => setModalShow(false)}
                >
                  {intl.formatMessage({id: 'BTNS.CANCEL'})}
                </button>
              </div>
            </div>
          )}
          {loader && (
            <div className='d-flex flex-column h-150px'>
              <LoaderForm />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export {UserLogoutModal}
