import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {axiosUserCards, axiosUserInfo} from '../../../setup/axios/AxiosUser'
import {MyAside} from '../../../_metronic/layout/components/aside/MyAside'
import arrowRight from '../../assets/ArrowRight.svg'
import {ProfileCardsList} from './components/profile-cards-list/ProfileCardsList'
import arrowLeft from '../../assets/ArrowLeft.svg'
import phoneIcon from '../../assets/icPhone.svg'
import abonementIcon from '../../assets/ic_parking_pass.svg'
import logoutIcon from '../../assets/ic_logout.svg'

import carIcon from '../../assets/ic_car1.svg'
import arrowDown from '../../assets/downArrow.svg'
import arrowUp from '../../assets/upArrow.svg'
import historyIcon from '../../assets/historyIcon.png'
import mapIcon from '../../assets/mapIcon.png'
import {GoTrashcan} from 'react-icons/go'

import './ProfilePage.css'
import {useDispatch} from 'react-redux'
import {axiosLogout} from '../../../setup/axios/AxiosAuth'
import {accessTokenAction} from '../../../setup/redux/actions/actions'
import {UserDeleteModal} from '../../modules/modal/user-delete/UserDeleteModal'
import {CarDeleteModal} from '../../modules/modal/car-delete/CarDeleteModal'
import {BsCreditCard} from 'react-icons/bs'
import {UserLogoutModal} from '../../modules/modal/user-logout/UserLogoutModal'
import axios from 'axios'
import {Languages} from "../../modules/languages/Languages";
import ReactDOM from "react-dom";
import {LanguagePickerModal} from "../../modules/modal/language-picker/LanguagePickerModal";

const apiBaseUrlMob = process.env.REACT_APP_API_MOBILE

interface Props{
  pages: any,
  setSelectedPage: any
}
const ProfilePage: FC<Props> = ({pages, setSelectedPage}) => {
  const [userPhoneNumber, setUserPhoneNumber] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [carsArray, setCarsArray] = useState<any[]>([])
  const [carsShow, setCarsShow] = useState(true)
  const [cardsShow, setCardsShow] = useState(true)
  const [cardsData, setCardsData] = useState<any[]>([])

  const [carModalShow, setCarModalShow] = useState(false)
  const [userLogoutModalShow, setUserLogoutModalShow] = useState(false)

  const [userDeleteModalShow, setUserDeleteModalShow] = useState(false)
  const [deletePlateNumber, setDeletePlateNumber] = useState('')
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState({})

  function toggleModal() {
    setShowModal(!showModal);
  }
  const intl = useIntl()

  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {
    axiosUserInfo().then((resp: any) => {
      if (resp.code === 200) {
        let userPhone = '+' + resp.message.username
        setUserPhoneNumber(userPhone)
        console.log(resp.message)
        setUser(resp.message)
        setCarsArray(resp.message.car)
      }
      if (resp.code !== 200) {
        setErrorMessage('error')
      }
    })
    // userCardsFunc()
  }, [])

  // const userCardsFunc = () => {
  //   axiosUserCards().then((resp) => {
  //     if (resp.code === 200) {
  //       if (resp.message.length > 0) {
  //         setCardsData(resp.message)
  //       }
  //       if (resp.message.length === 0) {
  //
  //         axios.get(apiBaseUrlMob + '/user/payment/cards/all', {
  //           headers: {
  //             'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
  //           }
  //         }).then((resp) => {
  //           console.log("Payze card: ", resp.data[0])
  //           setCardsData(resp.data)
  //         })
  //         setCardsData([])
  //       }
  //     }
  //     if (resp.code !== 200) {
  //       setErrorMessage('error')
  //     }
  //   })
  // }

  return (
    <div className='ProfilePage__parent__div'>
      <div className='p-5 ProfilePage__content__div'>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '15px'
          }}>
            <Languages onLanguage={toggleModal} />
          </div>
          {showModal &&
              ReactDOM.createPortal(
                  <LanguagePickerModal onToggle={toggleModal} />,
                  document.body
              )}

        <div
          className='ProfilePage__personal_info_div d-flex flex-column bg-white px-10 py-5'
          style={{borderRadius: '14px'}}
        >
          <div className='w-100 h-20px mb-10' style={{fontSize: '24px', fontWeight: '600'}}>
            {intl.formatMessage({id: 'PROFILE.ACCOUNT'})}
          </div>
          <div className='d-flex flex-row w-100 h-50px' onClick={() => navigate('/profile/edit', {state: {user: user}})}>
            <div className='h-100 d-flex align-items-center'>
              <img
                alt='phone icon'
                src={phoneIcon}
                className='h-25px'
                style={{
                  filter:
                    'invert(40%) sepia(79%) saturate(6383%) hue-rotate(222deg) brightness(103%) contrast(89%)',
                }}
              />
            </div>
            <div className='d-flex justify-content-center flex-column w-100 h-100 ms-5'>
              <span style={{fontSize: '16px'}} className='text-gray-500'>
                {intl.formatMessage({id: 'PROFILE.PHONE-NUMBER'})}
              </span>
              {errorMessage ? (
                <span
                  className='ProfileNavigation__big_span'
                  style={{fontSize: '18px', color: 'red'}}
                >
                  {intl.formatMessage({id: 'LOGIN.ERROR.SOMETHING-WENT-WRONG'})}
                </span>
              ) : (
                <span className='ProfileNavigation__big_span'>{userPhoneNumber}</span>
              )}
            </div>
          </div>
        </div>
        <div className='ProfilePage__module_parent_div'>

          <div
              onClick={() => {
                navigate('/my-cars')
              }}
              style={{borderRadius: '14px'}}
              className='bg-white px-10 py-5 mt-5 ProfilePage__btn_size cursor-pointer h-50px d-flex align-items-center justify-content-between Aside__div_element'
          >
            <div className='d-flex flex-row'>
              <img
                  alt='icon'
                  src={carIcon}
                  className='mt-1'
                  style={{
                    height: '25px',
                    width: '25px',
                    filter:
                        'invert(40%) sepia(79%) saturate(6383%) hue-rotate(200deg) brightness(103%) contrast(89%)',

                  }}
              />
              <span className='Aside__navigation_btns ms-5'>
                {intl.formatMessage({id: 'PROFILE.MY-CARS'})}
              </span>
            </div>
            <img src={arrowRight} />
          </div>


        </div>
        <div className='d-flex flex-column'>
          
          {/* my abonements start */}

          {/* my abonements end */}

          {/* payment history start */}
          <div
            onClick={() => {
              navigate('/profile/cityList', {state: {fromWhere: 'ProfilePage'}})
            }}
            style={{borderRadius: '14px'}}
            className='bg-white px-10 py-5 mt-5 ProfilePage__btn_size cursor-pointer h-50px d-flex align-items-center justify-content-between Aside__div_element'
          >
            <div className='d-flex flex-row'>
              <img
                alt='icon'
                src={mapIcon}
                className='mt-1'
                style={{
                  height: '21px',
                  width: '21px',

                }}
              />
              <span className='Aside__navigation_btns ms-5'>
                {intl.formatMessage({id: 'PROFILE.CITY'})}
              </span>
            </div>
            <img src={arrowRight} />
          </div>
          {/* payment history end */}
          <div
              onClick={() => {
                setSelectedPage(pages[1])
              }}
              style={{borderRadius: '14px'}}
              className='bg-white px-10 py-5 mt-5 ProfilePage__btn_size cursor-pointer h-50px d-flex align-items-center justify-content-between Aside__div_element'
          >
            <div className='d-flex flex-row'>
              <img
                  alt='icon'
                  src={historyIcon}
                  className='mt-1'
                  style={{
                    height: '20px',
                    width: '24px',

                  }}
              />
              <span className='Aside__navigation_btns ms-5'>
                {intl.formatMessage({id: 'PROFILE.HISTORY'})}
              </span>
            </div>
            <img src={arrowRight} />
          </div>

          <div
            onClick={() => {
              setUserLogoutModalShow(true)
            }}
            style={{borderRadius: '14px'}}
            className='bg-white px-10 py-5 mt-20 ProfilePage__btn_size cursor-pointer h-50px d-flex align-items-center justify-content-between Aside__div_element text-danger'
          >
            <div className='d-flex flex-row'>
              <img alt='icon' src={logoutIcon} className='mt-1' />
              <span className='Aside__navigation_btns ms-5'>
                {intl.formatMessage({id: 'AUTH.SIGNOUT'})}
              </span>
            </div>
          </div>
        </div>
        <UserLogoutModal setModalShow={setUserLogoutModalShow} modalShow={userLogoutModalShow} />
        <UserDeleteModal setModalShow={setUserDeleteModalShow} modalShow={userDeleteModalShow} />
        <CarDeleteModal
          setModalShow={setCarModalShow}
          modalShow={carModalShow}
          plateNumber={deletePlateNumber}
          setCarsData={setCarsArray}
          setParentErrorMessage={setErrorMessage}
        />
      </div>
    </div>
  )
}

export {ProfilePage}
