import {FC} from 'react'
import {useIntl} from 'react-intl'
import arrowRight from '../../../../assets/ArrowRight.svg'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {billingFormAction} from '../../../../../setup/redux/actions/actions'

import {BsCreditCard} from 'react-icons/bs'
import {CardLoaderForm} from '../../billing-form/components/BillingLoaderForm'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../../../App'
import tags from '../../../../assets/eventTags'


type Props = {
  cardPayFunc: any
  cardLoader: boolean
  setCardLoader?: any
  isNamba?: any
  isPayze?:any
  payzeCardMask?: any
}

const CardPay: FC<Props> = ({cardPayFunc, cardLoader, setCardLoader, isNamba, isPayze, payzeCardMask}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const loggedIn = useSelector((state: RootState) => state.token)
  const cityId = localStorage.getItem("cityId");

  const payFunc = () => {
    if (loggedIn) {
      setCardLoader(true)
      cardPayFunc()
    } else {
      dispatch(billingFormAction('auth'))
    }
  }

  return (
    <div className='w-100 h-100 align-items-center d-flex' onClick={() => {
      console.log("Mastercard Payment Selected");
      logEvent(analytics, tags.pay_by_card, {
        card: "Mastercard"
      })
    }}>
      {cardLoader ? (
        <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <div className='w-50px h-40px'>
            <CardLoaderForm />
          </div>
        </div>
      ) : (
        <div
          className='d-flex flex-row justify-content-between w-100 align-items-center'
          onClick={() => {
            if (!cardLoader) {
              payFunc()
            }
          }}
        >
          <div className='h-100 w-50px d-flex justify-content-center align-items-center'>
            <BsCreditCard
              style={{height: '35px', paddingRight: '5px', width: '45px', color: '#127FEE'}}
            />
          </div>
          <div className='fs-5 fw-bolder w-100 h-100 justify-content-between d-flex align-items-center text-dark rounded cursor-pointer'>
            <div className='d-flex flex-column ps-4'>
              <span className=' PaymentResult__span_bigText'>
                {payzeCardMask !== '0' ? payzeCardMask : intl.formatMessage({id: 'BILLING.PAYMENT-METHODS.CARD'})}
                {/* {intl.formatMessage({id: 'BILLING.PAYMENT-METHODS.CARD'})} */}
              </span>
              <span className='PaymentResult__span_smallText'>
                {intl.formatMessage({id: 'BILLING.PAYMENT-METHODS.PAYMENTS'})}
              </span>
              {isNamba === 'namba' ? <span className='PaymentResult__span_smallText'>
                Mastercard {cityId === "7" ? "/ UPI /Элкарт / Visa" : ""}
              
              </span> : 
                isPayze && <span className='PaymentResult__span_smallText'> Payze</span>
              }

              
            </div>

            <img src={arrowRight} className='PaymentResult__span_icon' />
          </div>
        </div>
      )}
    </div>
  )
}

export {CardPay}
