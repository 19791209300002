export const getBillingToken = "/user/ext_login"
export const getBillingInfo = "/payment/check"

export const woopayPayment = "/payment/woopay"

export const cityList = '/public/city/list'
export const parkingList = '/public/parking/list'
export const countryList = '/public/country/list'
export const paymentLinks = '/payment-links/list/'
// api/payment
export const pitech = '/payment/pitech/pay'
export const namba = '/namba/pay'

export const abonementParkingList = '/public/parkings/?abonementsEnabled=true'
export const carAbonement = '/abonements?plateNumber='
export const abonementCreate = '/abonement/create'
export const abonementDelete = '/abonement/delete'
export const abonementTypes = '/abonement/types/'
export const paymentHistory = '/payment/history'
export const lastPayment = '/payment/last/info'
export const authRegister = '/auth/register'
export const authLogin = '/auth/authorization'
export const authVerify = '/auth/verify'
export const authTokenRefresh = '/auth/refresh'

export const userLanguageEdit = '/user/edit'
export const userInfo = '/user/info'
export const userCars = '/user/cars'
export const userAddCar = '/mobile/cars/add'
export const userDelete = '/user/delete'
export const userCarDelete = '/cars/remove?plateNumber='
export const userCardSave = '/payment/pitech/card/save'
export const payzeCardSave = '/uz/payze/saveCard'
export const userCards = '/payment/pitech/user/cards'
export const payzeCards = '/user/payment/cards/all'
export const userCardDelete = '/payment/pitech/card/delete?cardId='
export const userPayzeCardDelete = '/payment/payze/card/delete?cardId='
export const discount = '/payment/namba/discount'
export const directDebt = '/payment/pitech/pay/direct-debt'

export const editUser = '/user/profile'