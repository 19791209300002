export const initialState = {
    filters: []
};

export const filtersReducer = (state = initialState, action:any) => {
    switch(action.type) {
        case 'SET_FILTERS':
            return {
                ...state,
                filters: action.filters
            };
        default:
            return state;
    }
};
