const tokenFromStorage = localStorage.getItem('billingToken')

const billingBearerTokenReducer = (
  state = tokenFromStorage ? tokenFromStorage : null,
  action: any
) => {
  const {type, payload} = action
  switch (type) {
    case 'SET_BEARER_TOKEN':
      return payload
    default:
      return state
  }
}

export {billingBearerTokenReducer}
