const parkingGuidFromStorage = localStorage.getItem('parkingGuid')

const addParkingGuidReducer = (
    state:any = parkingGuidFromStorage ? parkingGuidFromStorage : null,
    action:any
) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_PARKING_GUID":
            return payload;
        default:
            return state;
    }
};

export {addParkingGuidReducer}