import {FC, useEffect, useState} from 'react'

import {
  getAbonementParkingListCity,
  getCityList,
  getParkingList,
  getParkingListCity,
} from '../../../../setup/axios/AxiosParking'
import {ParkingBottomMenu} from './components/menu/mobile-menu/ParkingBottomMenu'
import {CityListDropdown} from '../../dropdown/CityListDropdown'
import {MyAside} from '../../../../_metronic/layout/components/aside/MyAside'
import {BillingForm} from '../billing-form/BillingForm'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {
  addParkingGuidAction,
  chooseParkingFormAction,
  cityIdAction,
  cityLatAction,
  cityLngAction,
  citySelectAction,
  countryIdAction,
  userInfoPhoneNumberAction,
} from '../../../../setup/redux/actions/actions'
import {axiosUserInfo} from '../../../../setup/axios/AxiosUser'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import { MapboxMain } from '../../mapbox/MapboxMain'

import {useIntl} from 'react-intl'
import {FaLocationArrow} from 'react-icons/fa'

import './ChooseParkingForm.css'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../../App'
import tags from '../../../assets/eventTags'
import PlateNumberModule from '../../modal/plate-number/PlateNumberModule'
import { isMobile } from 'react-device-detect'
import {useLocation, useParams} from 'react-router-dom'
import {Main} from "../../../pages/additional_info/Main";
import {ParkingIcon} from "../../../assets/svg/ParkingIcon";
import {HistoryIcons} from "../../../assets/svg/HistoryIcons";
import {NotificationIcon} from "../../../assets/svg/NotificationIcon";
import {ProfileIcon} from "../../../assets/svg/ProfileIcon";
import {ProfilePage} from "../../../pages/profile/ProfilePage";


type Props = {
  cityId?: any,
  currentCity?: any,
  lat?: any,
  lon?: any,
  qr?: boolean
}

const ChooseParkingForm: FC<Props> = ({cityId, currentCity, lat, lon, qr}) => {
  const lang = useLang()
  console.log("Lang: ", lang)
  let {city, parkingUid} = useParams()
  const location = useLocation()
  const state: any = location.state;
  console.log("City: ", city)
  console.log("Parking UID: ", parkingUid)
  const [parking, setParking] = useState('')
  const [plateNumber, setPlateNumber] = useState('')
  const [serviceId, setServiceId] = useState('1')
  const dispatch = useDispatch()
  const showParkingInfo = useSelector((state: RootState) => state.showParkingInfo)
  console.log("PARKING INFO: ", showParkingInfo)
  if(parkingUid){
    dispatch(addParkingGuidAction(parkingUid))
  }
  if(cityId && currentCity && lat && lon){
    console.log("HOME PAGE BISHKEK")
    dispatch(cityIdAction(cityId ? cityId : "3"))
    dispatch(citySelectAction(currentCity ? currentCity : "Алматы"))
    dispatch(cityLatAction(lat ? lat : 43.24764057651702))
    dispatch(cityLngAction(lon ? lon : 76.92229283522701))
  }
  
  // dispatch(citySelectAction(city || 'Almaty'))
  const [parkingData, setParkingData] = useState([])
  const [mapParkingLocation, setMapParkingLocation] = useState({})
  const [selected, setSelected] = useState<any>(43)
  const [modalShow, setModalShow] = useState(false)


  const [chooseParkingForm, setChooseParkingForm] = useState(true)

  const [cityLat, setCityLat] = useState(43.24764057651702)
  const [cityLng, setCityLng] = useState(76.92229283522701)
  const [geolocateControlCoordinates, setGeolocateControlCoordinates] = useState({lat: null, lng: null})
  const storeCity = useSelector((state: RootState) => state.city)
  const storeCityId = useSelector((state: RootState) => state.cityId)

  // const storeCity = useSelector((state: RootState) => state.city)
  // const storeCityId = useSelector((state: RootState) => state.cityId)

  const [cityData, setCityData] = useState([])

  const [userPositionMapInfo, setUserPositionMapInfo] = useState(false)

  const storeParkingGuid = useSelector((state: RootState) => state.parkingGuid)
  const storeCityLat = useSelector((state: RootState) => state.cityLat)
  const storeCityLng = useSelector((state: RootState) => state.cityLng)
  const [center, setCenter] = useState({lat: lat ? lat : storeCityLat ? storeCityLat : 43.238949, lng: lon ? lon : storeCityLng ? storeCityLng : 76.889709})

  const [abonement, setAbonement] = useState(false)
  useEffect(() => {
    console.log(parking)
  }, [parking])
  const intl = useIntl()

  const loggedIn = useSelector((state: RootState) => state.token)
  const showPlateNumberHint = useSelector((state: RootState) => state.showPlateNumberHint)
  useEffect(() => {
    dispatch(chooseParkingFormAction('fastPaymentMenu'))
    if (loggedIn) {
      axiosUserInfo().then((resp: any) => {
        if (resp.code === 200) {
          let userPhone = '+' + resp.message.username
          dispatch(userInfoPhoneNumberAction(userPhone))
        }
        if (resp.code !== 200) {
          console.log('error')
        }
      })
    }
  }, [])


  useEffect(() => {
    getCityList(lang ? lang : "ru").then((resp) => {
      resp.data.map((item: any) => {
        const {name, id, countryId} = item
        if (Number(id) === Number(storeCityId)) {
          console.log("Item city: ", countryId)
          dispatch(citySelectAction(name))
          dispatch(countryIdAction(countryId))
        }
      })
      setCityData(resp.data)
    })
  }, [lang])

  useEffect(() => {
    setCityLat(center.lat)
    setCityLng(center.lng)
  }, [center])

  useEffect(() => {
    console.log("Store city lat: ", storeCityLat)
    setCenter({lat: Number(storeCityLat), lng: Number(storeCityLng)})

    if (abonement) {
      getAbonementParkingListCity(lang).then((resp) => {
        const cityHandledAbonArray: any = []
        if (resp?.data) {
          resp.data.map((item: any) => {
            if (Number(item.cityId) === Number(storeCityId)) {
              cityHandledAbonArray.push(item)
            }
          })
          setParkingData(cityHandledAbonArray)
        }
        setUserPositionMapInfo(false)
      })
    }
    if (!abonement) {
      getParkingListCity(storeCityId, cityLat, cityLng, lang).then((resp) => {
        if (resp?.data) {
          console.log("PARKING DATA: ", resp?.data)
          logEvent(analytics, tags.parking_payment);
          setParkingData(resp?.data)
        }
        setUserPositionMapInfo(false)
      })
    }
    setSelected(null)
    setParking('')
    setPlateNumber('')
  }, [abonement, storeCityId])

  //Parking List Funciton start
  const parkingListFunc = () => {
    return parkingData.map((item: any, id: number) => {
      
      let {latitude, longitude, name, parkingUid, distance, address, paymentType} = item

      if (String(distance)[0] === '0') {
        distance = distance * 1000
        let point = String(distance).indexOf('.')
        distance =
          String(distance).substring(0, point + 3) +
          ' ' +
          intl.formatMessage({id: 'PAYMENT-CHOOSE-PARKING.DISTANCE.M'})
      } else {
        let point = String(distance).indexOf('.')
        distance =
          String(distance).substring(0, point + 3) +
          ' ' +
          intl.formatMessage({id: 'PAYMENT-CHOOSE-PARKING.DISTANCE.KM'})
      }

      const style = parking === name ? {background: '#E0E0E0'} : {}

      return (
        <div
          key={id}
          className='w-100 d-flex pt-2 border-bottom border-gray-300 cursor-pointer d-flex flex-column'
          style={style}
          onClick={() => {
            if (paymentType === 'PREPAID') {
              setServiceId('2')
            }
            if (paymentType !== 'PREPAID') {
              setServiceId('1')
            }
            setParking(name)
            setModalShow(false)
            dispatch(addParkingGuidAction(parkingUid))
            setSelected(parkingUid)
            setMapParkingLocation({
              lat: Number(latitude),
              lng: Number(longitude),
            })
          }}
        >
          <span className='ps-4 ChooseParking__fontSize_big'>{name}</span>
          <div className='d-flex flex-row'>
            <span className='ChooseParking__fontSize_small ps-4 pe-1'>{address}</span>
            {userPositionMapInfo && (
              <span className='ChooseParking__fontSize_small '> - {distance}</span>
            )}
          </div>
        </div>
      )
    })
  }
  // Parking List Funciton end

  // Parking List Funciton On Location Click Start
  const userPositionParkingList = (lat: any, lng: any) => {
    let newParkingDataArray: any = []
    if (abonement) {
      getAbonementParkingListCity(lang).then((resp: any) => {
        resp.data.map((item: any) => {
          const lat2 = item.latitude
          const lon2 = item.longitude
          const R = 6371 // m
          const φ1 = (lat * Math.PI) / 180 // φ, λ in radians
          const φ2 = (lat2 * Math.PI) / 180
          const Δφ = ((lat2 - lat) * Math.PI) / 180
          const Δλ = ((lon2 - lng) * Math.PI) / 180

          const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

          const d = R * c
          item.distance = d
          if (d < 60) {
            newParkingDataArray.push(item)
          }
        })
        newParkingDataArray.sort((a: any, b: any) => {
          if (a.distance < b.distance) {
            return -1
          }
          if (a.distance > b.distance) {
            return 1
          }
          return 0
        })

        setParkingData(newParkingDataArray)
      })
    }
    if (!abonement) {
      getParkingList(lang).then((resp) => {
        resp.map((item: any) => {
          const lat2 = item.latitude
          const lon2 = item.longitude
          const R = 6371 // m
          const φ1 = (lat * Math.PI) / 180 // φ, λ in radians
          const φ2 = (lat2 * Math.PI) / 180
          const Δφ = ((lat2 - lat) * Math.PI) / 180
          const Δλ = ((lon2 - lng) * Math.PI) / 180

          const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

          const d = R * c
          item.distance = d
          if (d < 60) {
            newParkingDataArray.push(item)
          }
        })
        newParkingDataArray.sort((a: any, b: any) => {
          if (a.distance < b.distance) {
            return -1
          }
          if (a.distance > b.distance) {
            return 1
          }
          return 0
        })

        setParkingData(newParkingDataArray)
      })
    }

    return
  }
    //Parking List Funciton On Location Click End
  
  const pages = [
    {name: 'Parking', element: <ParkingIcon color={'#B0B5D0'} />, elementSelected: <ParkingIcon color={'#127FEE'} />},
    {name: 'History', element: <HistoryIcons color={'#B0B5D0'} />, elementSelected: <HistoryIcons color={'#127FEE'} />},
    // {name: 'Notification', element: <NotificationIcon color={'#B0B5D0'} />, elementSelected: <NotificationIcon color={'#127FEE'} />},
    {name: 'Profile', element: <ProfileIcon color={'#B0B5D0'} />, elementSelected: <ProfileIcon color={'#127FEE'} />}
  ]
  const [selectedPage, setSelectedPage] = useState(state && state.returnTo === "ProfilePage" ? pages[2] : state && state.returnTo === 'History' ? pages[1] : pages[0])
  console.log("ASOSSO: ", selectedPage)
  return (
    <>
      {
        isMobile ?
            <div className='ChooseParking__div_parent'>
              <div style={{
                height: '100%'
              }}>
                {
                  selectedPage.name === 'Parking' ?
                      <MapboxMain
                          setMapParkingLocation={setMapParkingLocation}
                          mapParkingLocation={mapParkingLocation}
                          parkingData={parkingData}
                          setParking={setParking}
                          selected={selected}
                          setSelected={setSelected}
                          cityName={storeCity}
                          center={center}
                          setCenter={setCenter}
                          userPositionParkingList={userPositionParkingList}
                          setUserPositionMapInfo={setUserPositionMapInfo}
                          abonement={abonement}
                          setGeolocateControlCoordinates={setGeolocateControlCoordinates}
                      />
                      :
                  selectedPage.name === 'Profile' ?
                      <ProfilePage pages={pages} setSelectedPage={setSelectedPage} />
                      : <Main />
                }

              </div>
              <div style={{
                height: '70px',
                width: '100%',
                backgroundColor: 'white',
                position: 'fixed',
                bottom: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '30px'
              }}>
                {
                  pages.map((page: any) => (
                      <div onClick={() => setSelectedPage(page)} style={{
                        width: '73px',
                        height: '44px',
                        borderRadius: '26px',
                        background:selectedPage.name === page.name ? '#e2ebf9' : 'transparent',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        {
                          selectedPage.name === page.name ? page.elementSelected : page.element
                        }
                      </div>
                  ))
                }
              </div>
            </div>
            :
            <>
              {
                !qr ? (
                    <>
                      <div className=' ChooseParking__div_parent'>
                        {/* google maps start */}
                        <div
                            className={
                              chooseParkingForm
                                  ? `ChooseParking__maps_height__width`
                                  : `ChooseParking__maps_height__width_billing`
                            }
                            style={{zIndex: '1'}}
                        >
                          {/* aside menu start */}
                          <div
                              className='ms-4 mt-2 position-absolute end-0 me-5 mt-5 bg-white ChooseParking__aside_menu_show'
                              style={{zIndex: '3'}}
                          >
                            <MyAside />
                          </div>
                          {/* aside menu end */}

                          {/* city list start */}
                          {cityData && (
                              <div
                                  className='w-130px ms-4 h-30px position-absolute ChooseParking__city_list me-2 mt-6'
                                  style={{zIndex: '2'}}
                              >
                                <CityListDropdown cityData={cityData} city={storeCity} cityId={storeCityId} setCenter={setCenter}/>
                              </div>
                          )}
                          {/* city list end */}
                          <div
                              className='ChooseParking-location-distace'
                              style={{zIndex: '1'}}
                              onClick={() => {
                                if (geolocateControlCoordinates.lat && geolocateControlCoordinates.lng) {
                                  let lat = Number(geolocateControlCoordinates.lat)
                                  let lng = Number(geolocateControlCoordinates.lng)

                                  setUserPositionMapInfo(true)
                                  setCenter({lat, lng})
                                  userPositionParkingList(lat, lng)
                                }
                              }}
                          >
                            <FaLocationArrow />
                          </div>
                          <MapboxMain
                              setMapParkingLocation={setMapParkingLocation}
                              mapParkingLocation={mapParkingLocation}
                              parkingData={parkingData}
                              setParking={setParking}
                              selected={selected}
                              setSelected={setSelected}
                              cityName={storeCity}
                              center={center}
                              setCenter={setCenter}
                              userPositionParkingList={userPositionParkingList}
                              setUserPositionMapInfo={setUserPositionMapInfo}
                              abonement={abonement}
                              setGeolocateControlCoordinates={setGeolocateControlCoordinates}
                          />
                        </div>
                        {/* google maps end */}

                        {/* bottom menu start */}
                        {isMobile ? showPlateNumberHint && <div style={{marginBottom: '10px'}}><PlateNumberModule /></div> : ''}
                        {chooseParkingForm && (
                            <div className='ChooseParking__bottom_menu_show'>
                              <ParkingBottomMenu
                                  modalShow={modalShow}
                                  setModalShow={setModalShow}
                                  plateNumber={plateNumber}
                                  setPlateNumber={setPlateNumber}
                                  parking={parking}
                                  parkingGuid={storeParkingGuid}
                                  serviceId={serviceId}
                                  parkingListFunc={parkingListFunc}
                                  setChooseParkingForm={setChooseParkingForm}
                                  abonement={abonement}
                                  setAbonement={setAbonement}

                              />
                            </div>
                        )}
                        {!chooseParkingForm && (
                            <div className='ChooseParking__bottom_billing'>
                              <BillingForm showParkingList={setChooseParkingForm} abonement={abonement} />
                            </div>
                        )}
                        {/* bottom menu end */}
                      </div>
                    </>
                ) : (
                    <div className=' ChooseParking__div_parent'>
                      {isMobile ? showPlateNumberHint && <div style={{marginBottom: '10px'}}><PlateNumberModule /></div> : ''}
                      {chooseParkingForm && (
                          <div className='ChooseParking__bottom_menu_show'>
                            <ParkingBottomMenu
                                modalShow={modalShow}
                                setModalShow={setModalShow}
                                plateNumber={plateNumber}
                                setPlateNumber={setPlateNumber}
                                parking={parking}
                                parkingGuid={storeParkingGuid}
                                serviceId={serviceId}
                                parkingListFunc={parkingListFunc}
                                setChooseParkingForm={setChooseParkingForm}
                                abonement={abonement}
                                setAbonement={setAbonement}
                                city={city}
                            />
                          </div>
                      )}
                      {!chooseParkingForm && (
                          <div className='ChooseParking__bottom_billing'>
                            <BillingForm showParkingList={setChooseParkingForm} abonement={abonement} />
                          </div>
                      )}
                    </div>
                )
              }
            </>
      }
     

    {showPlateNumberHint && !isMobile && <PlateNumberModule />}
    </>
  )
}

export {ChooseParkingForm}
