import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'

import './Components.css'

type Props = {
  freeMinutes: string
  parkingName: string
  showParkingList?: any
}

const FreeBillingForm: FC<Props> = ({freeMinutes, parkingName, showParkingList}) => {
  let navigate = useNavigate()
  const intl = useIntl()
  return (
    <div className='p-5 w-100 h-100 d-flex flex-column bg-body rounded justify-content-between'>
      <div className='d-flex flex-column w-100 h-100px align-items-center mt-5'>
        <div className='d-flex flex-row justify-content-between w-100'>
          <span className='fs-6 fw-bold'>
            {intl.formatMessage({id: 'BILLING.FREE/PAYED-FORM.PARKING'})}
          </span>
          <span className='fs-5 fw-bolder ms-4'>{parkingName}</span>
        </div>
        <div className='d-flex flex-row justify-content-between w-100 mt-4'>
          <span className='fs-6 fw-bold'>
            {intl.formatMessage({id: 'BILLING.FREE/PAYED-FORM.FREE-MINUTES'})}:
          </span>
          <span className='fs-5 fw-bolder ms-4'>
            {freeMinutes} {intl.formatMessage({id: 'BILLING.FREE/PAYED-FORM.MINUTES'})}
          </span>
        </div>
      </div>
      <div className='d-flex flex-row w-100 border-top border-secondary'>
        <button
          className='h-40px w-100 justify-content-center btn btn-primary d-flex align-items-center'
          style={{borderRadius: '14px', background: '#127FEE', color: 'white'}}
          onClick={() => {
            showParkingList(true)
          }}
        >
          {intl.formatMessage({id: 'BTNS.BACK'})}
        </button>
      </div>
    </div>
  )
}

export {FreeBillingForm}
