const cityIdFromStorage = localStorage.getItem('cityId')

const addCityIdReducer = (
    state:any = cityIdFromStorage ? cityIdFromStorage : '',
    action:any 
) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_CITY_ID":
            return payload;
        default:
            return state;
    }
};

export {addCityIdReducer}