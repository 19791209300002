import {FC, useEffect, useState} from 'react'
import Calendar from 'react-calendar'
import {AiOutlineCalendar, AiOutlineClockCircle} from 'react-icons/ai'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import {
  axiosAbonementCreate,
  axiosGetAbonementInfo,
} from '../../../../../../../../setup/axios/AxiosAbonements'
import arrowLeft from '../../../../../../../assets/ArrowLeft.svg'
import {
  abonEndDateAction,
  abonStartDateAction,
  abonStartTimeAction,
  chooseParkingFormAction,
} from '../../../../../../../../setup/redux/actions/actions'
import {RootState} from '../../../../../../../../setup'
import {BtnLoaderForm, LoaderForm} from '../../../../../billing-form/components/BillingLoaderForm'
import {useLang} from '../../../../../../../../_metronic/i18n/Metronici18n'

import '../ParkingBottomMenuAbonementCalendar.css'
import 'react-calendar/dist/Calendar.css'

type Props = {
  setChooseParkingForm: any
}

const ParkingBottomMenuAbonementCalendar: FC<Props> = ({setChooseParkingForm}) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [startTime, setStartTime] = useState('')
  const [calendarShow, setCalendarShow] = useState(false)

  const [abonementTypes, setAbonementTypes] = useState([])
  const [innerParkings, setInnerParking] = useState([])
  const [customSchedule, setCustomSchedule] = useState('')

  const [formattedDate, setFormattedDate] = useState('')

  const [btnDisabled, setBtnDisabled] = useState(true)

  const [abonementTypeId, setAbonementTypeId] = useState('')
  const [innerParkingId, setInnerParkingId] = useState('')
  const [unlimited, setUnlimited] = useState(false)

  const [btnLoader, setBtnLoader] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  const [form, setForm] = useState('loader')

  const intl = useIntl()

  const [days, setDays] = useState(0)

  const dispatch = useDispatch()

  const storePlateNumber = useSelector((state: RootState) => state.unauthCarAdd)
  const storeParkingGuid = useSelector((state: RootState) => state.parkingGuid)

  const lang = useLang()

  useEffect(() => {
    axiosGetAbonementInfo(storeParkingGuid, lang ? lang.toUpperCase() : "ru").then((resp: any) => {
      setForm('')
      if (resp.code === 200) {
        setAbonementTypes(resp.message.abonementTypes)
        setInnerParking(resp.message.innerParkings)
      }
      if (resp.code !== 200) {
        setForm('error')
      }
    })
  }, [])

  useEffect(() => {
    if (abonementTypeId === 'emptyId') {
      setCustomSchedule('')
      setAbonementTypeId('')
      setUnlimited(false)
      return
    }
    abonementTypes.map((item: any) => {
      if (Number(item.id) === Number(abonementTypeId)) {
        if (item.type === 'UNLIMITED') {
          setCustomSchedule('')
          setUnlimited(true)
        }
        if (item.type === 'CUSTOM') {
          setCustomSchedule(item.customNumbers)
          setUnlimited(false)
        }
        if (item.type !== 'CUSTOM' && item.type !== 'UNLIMITED') {
          setCustomSchedule('')
          setUnlimited(false)
        }
      }
    })
  }, [abonementTypeId])

  useEffect(() => {
    addDays(startDate, days)
  }, [days, startDate])

  useEffect(() => {
    dateFormatter(startDate)
    if (startDate && startTime && abonementTypeId && innerParkingId) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
  }, [startDate, startTime, abonementTypeId, innerParkingId])

  const addDays = (date: any, days: any) => {
    let result = new Date(date)
    result.setDate(result.getDate() + days)
    setEndDate(result)
  }

  const scheduleFunc = () => {
    if (customSchedule) {
      return Object.entries(JSON.parse(customSchedule)).map((item: any, id) => {
        let startTime = item[1][0]
        let endTime = item[1][item[1].length - 1] + 1
        if (Number(startTime) < 10) {
          startTime = '0' + String(startTime)
        }
        if (Number(endTime) < 10) {
          endTime = '0' + String(endTime)
        }
        if (endTime === 24) {
          endTime = '00'
        }
        let weekDay = item[0]
        switch (weekDay) {
          case '0':
            weekDay = intl.formatMessage({id: 'ABONEMENT.MONDAY'})
            break
          case '1':
            weekDay = intl.formatMessage({id: 'ABONEMENT.TUESDAY'})
            break
          case '2':
            weekDay = intl.formatMessage({id: 'ABONEMENT.WEDNESDAY'})
            break
          case '3':
            weekDay = intl.formatMessage({id: 'ABONEMENT.THURSDAY'})
            break
          case '4':
            weekDay = intl.formatMessage({id: 'ABONEMENT.FRIDAY'})
            break
          case '5':
            weekDay = intl.formatMessage({id: 'ABONEMENT.SATURDAY'})
            break
          case '6':
            weekDay = intl.formatMessage({id: 'ABONEMENT.SUNDAY'})
            break
          default:
            weekDay = intl.formatMessage({id: 'ABONEMENT.MONDAY'})
            break
        }
        return (
          <div
            key={id}
            style={{fontSize: '16px', fontWeight: '400'}}
            className='d-flex w-200px flex-row justify-content-between'
          >
            <div className='me-2'>{weekDay}:</div>
            <div className='d-flex flex-row'>
              <span className='me-1'>
                {startTime} {intl.formatMessage({id: 'HOURS'})}
              </span>{' '}
              -{' '}
              <span className='ms-1'>
                {endTime} {intl.formatMessage({id: 'HOURS'})}
              </span>
            </div>
          </div>
        )
      })
    }
  }

  const dateFormatter = (date: any) => {
    let year = String(date.getFullYear())
    let month = String(date.getMonth() + 1)
    let day = String(date.getDate())
    if (Number(month) < 10) {
      month = '0' + month
    }
    if (Number(day) < 10) {
      day = '0' + day
    }
    setFormattedDate(year + '-' + month + '-' + day + 'T' + startTime)
  }

  const abonementTypesFunc = () => {
    return (
      <select
        className='w-100 h-100 ps-5 ParkingBottomMenuAbonementCalendar__border cursor-pointer'
        onChange={(e) => {
          setDays(Number(e.target.value.split(/(\s+)/)[0]))
          setAbonementTypeId(e.target.options[e.target.options.selectedIndex].id)
        }}
      >
        <option id={'emptyId'}></option>
        {abonementTypes.map((item) => {
          const {id, period, price} = item
          return (
            <option id={id} key={id}>
              {period} {intl.formatMessage({id: 'ABONEMENT.DAYS'})},{' '}
              {intl.formatMessage({id: 'ABONEMENT.AMOUNT'})} - {price}
            </option>
          )
        })}
      </select>
    )
  }

  const innerParkingsFunc = () => {
    return (
      <div className='w-100 h-100 d-flex flex-column mt-4 mb-2'>
        <span className='ParkingBottomMenuAbonementCalendar__span fw-bolder'>
          {intl.formatMessage({id: 'PAYMENT.CHOOSE-PARKING.CHOOSE-PARKING'})}
        </span>
        <div className='w-100 h-40px rounded'>
          <select
            className='w-100 h-100 ps-5 ParkingBottomMenuAbonementCalendar__border cursor-pointer'
            onChange={(e) => {
              if (e.target.options[e.target.options.selectedIndex].id === 'emptyId') {
                setInnerParkingId('')
                return
              }
              setInnerParkingId(e.target.options[e.target.options.selectedIndex].id)
            }}
          >
            <option id={'emptyId'}></option>
            {innerParkings.map((item) => {
              const {name, id} = item
              return (
                <option id={id} key={id}>
                  {name}
                </option>
              )
            })}
          </select>
        </div>
      </div>
    )
  }

  const btnFunc = () => {
    setErrorMessage('')
    setBtnLoader(true)
    dispatch(abonStartTimeAction(startTime))
    dispatch(abonStartDateAction(startDate.toLocaleDateString()))
    dispatch(abonEndDateAction(endDate.toLocaleDateString()))
    axiosAbonementCreate(
      storePlateNumber,
      storeParkingGuid,
      innerParkingId,
      abonementTypeId,
      formattedDate
    ).then((resp: any) => {
      setBtnLoader(false)
      if (resp.code === 200 && resp.message === true) {
        setChooseParkingForm(false)
      }
      if (resp.code === 200 && resp.message === false) {
        setErrorMessage('abonementCross')
      }
      if (resp.code !== 200) {
        setErrorMessage(resp.message)
      }
    })
  }

  if (form === 'loader') {
    return (
      <div className='w-100 h-200px d-flex flex-column justify-content-between ps-5 pe-5'>
        <LoaderForm />
      </div>
    )
  }

  if (form === 'error') {
    return (
      <div className='w-100 h-200px d-flex flex-column justify-content-between ps-5 pe-5'>
        <div className='d-flex me-5 flex-row justify-content-center align-items-center w-45px'>
          <img
            className='p-5 cursor-pointer'
            style={{fontSize: '32px', fontWeight: '400'}}
            onClick={() => {
              dispatch(chooseParkingFormAction('abonementNumberParkingMenu'))
            }}
            src={arrowLeft}
          />
        </div>
        <div className='w-100 h-100 align-items-center d-flex justify-content-center'>
          <span style={{color: 'red', fontSize: '16px', fontWeight: '400'}}>
            {intl.formatMessage({id: 'BILLING.PAYMENT-METHODS.CHECK-ERROR'})}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className='w-100 h-100 d-flex bg-white flex-column justify-content-between position-relative ps-5 pe-5'>
      <div className='d-flex flex-column'>
        {/* back button start */}
        <div className='d-flex me-5 flex-row justify-content-center align-items-center w-45px'>
          <img
            className='p-5 cursor-pointer'
            style={{fontSize: '32px', fontWeight: '400'}}
            onClick={() => {
              dispatch(chooseParkingFormAction('abonementNumberParkingMenu'))
            }}
            src={arrowLeft}
          />
        </div>
        {/* back button end */}
        {/* inner parkings start */}
        <div className='w-100 h-70px'>{innerParkingsFunc()}</div>
        {/* inner parkings end */}
        {/* abonement type start */}
        <div className='w-100 h-70px d-flex flex-column mt-4 mb-2'>
          <span className='ParkingBottomMenuAbonementCalendar__span fw-bolder'>
            {intl.formatMessage({id: 'ABONEMENT.SELECT-ABONEMENT-TYPE'})}
          </span>
          <div className='w-100 h-40px rounded'>{abonementTypesFunc()}</div>
        </div>
        {/* abonement type end */}

        {/* schedule start */}
        {Object.keys(customSchedule).length > 0 && (
          <div className='w-100 d-flex flex-column'>
            <span style={{fontSize: '16px', fontWeight: '600'}}>
              {intl.formatMessage({id: 'ABONEMENT.SCHEDULE'})}
            </span>
            <div className='w-100 mt-2 mb-4 d-flex flex-column'>{scheduleFunc()}</div>
          </div>
        )}
        {/* schedule end */}
        {/* unlimited abonement start */}
        {unlimited && (
          <div className='w-100 d-flex flex-column mb-5'>
            <span style={{fontSize: '16px', fontWeight: '600'}}>
              {intl.formatMessage({id: 'ABONEMENT.SCHEDULE'})}
            </span>
            <span style={{fontSize: '16px', fontWeight: '400'}}>
              {intl.formatMessage({id: 'ABONEMENT.ALL-WEEK-DAYS'})}
            </span>
          </div>
        )}
        {/* unlimited abonement end */}
        {/*calendar start*/}
        <div className='w-100 mb-6 d-flex flex-row '>
          <div className='d-flex flex-column' style={{width: '60%'}}>
            <span className='ParkingBottomMenuAbonementCalendar__span fw-bolder'>
              {intl.formatMessage({id: 'ABONEMENT.SELECT-DATE'})}
            </span>
            <div
              className='w-100 d-flex flex-row align-items-center ps-5 ParkingBottomMenuAbonementCalendar__border cursor-pointer'
              onClick={() => {
                setCalendarShow(!calendarShow)
              }}
            >
              <AiOutlineCalendar className='ParkingBottomMenuAbonementCalendar__icon' />
              <div className='d-flex flex-row ms-2 pe-2'>
                <span className='ParkingBottomMenuAbonementCalendar__span'>
                  {startDate.toLocaleDateString()}
                </span>
                <div className='me-2 ms-2 ParkingBottomMenuAbonementCalendar__span'>-</div>
                <span className='ParkingBottomMenuAbonementCalendar__span'>
                  {endDate.toLocaleDateString()}
                </span>
              </div>
            </div>
          </div>
          {/*time start*/}
          <div className='d-flex flex-column ms-5 ' style={{width: '35%'}}>
            <span className='ParkingBottomMenuAbonementCalendar__span fw-bolder'>
              {intl.formatMessage({id: 'ABONEMENT.SELECT-TIME'})}
            </span>
            <div className='d-flex flex-row ps-5 align-items-center ParkingBottomMenuAbonementCalendar__border'>
              <AiOutlineClockCircle className='ParkingBottomMenuAbonementCalendar__icon' />

              <input
                type='time'
                className='h-100 me-4 ms-2 w-75 cursor-pointer'
                onChange={(e) => {
                  setStartTime(e.target.value)
                }}
                value={startTime}
                style={{border: 'none', outline: 'none', background: 'white'}}
              />
            </div>
          </div>
          {/*time end*/}
        </div>
        {/* calendar start */}
        <div className='w-100 d-flex justify-content-center'>
          {calendarShow && (
            <Calendar
              onChange={(date: any) => {
                setStartDate(date)
                setCalendarShow(false)
              }}
              value={startDate}
              minDate={new Date()}
            />
          )}
        </div>
        {/*calendar end*/}
      </div>

      {errorMessage === 'error' && (
        <div style={{color: 'red', fontSize: '16px'}}>
          {intl.formatMessage({id: 'LOGIN.ERROR.SOMETHING-WENT-WRONG'})}
        </div>
      )}
      {errorMessage === 'abonementCross' && (
        <div style={{color: 'red', fontSize: '16px'}}>
          {intl.formatMessage({id: 'ABONEMENT.CREATED'})}
        </div>
      )}

      {/* pay button start */}
      <div className='ps-3 pe-3 w-100 d-flex flex-column align-items-center justify-content-center h-40px bg-white mt-5 mb-2'>
        <button
          className='h-50px w-100 justify-content-center btn d-flex align-items-center fs-1'
          style={{borderRadius: '14px', background: '#127FEE', color: 'white'}}
          disabled={btnDisabled}
          onClick={() => {
            btnFunc()
          }}
        >
          {btnLoader ? (
            <div className='h-50px w-100'>
              <BtnLoaderForm />
            </div>
          ) : (
            intl.formatMessage({id: 'BTNS.PAY'})
          )}
        </button>
      </div>
      {/* pay button end */}
    </div>
  )
}

export {ParkingBottomMenuAbonementCalendar}
