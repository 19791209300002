/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {OfferModal} from '../../../app/pages/offer/OfferModal'
import {PrivacyModal} from '../../../app/pages/privacy/PrivacyModal'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  const intl = useIntl()
  return (
    <div className='footer w-100 py-4 d-flex flex-lg-column border border-secondary' id='kt_footer'>
      {/* begin::Container */}
      <div className={`${classes.footerContainer} d-flex flex-row flex-md-row align-items-center`}>
        {/* begin::Copyright */}
        <div className='text-dark'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='#' className='text-gray-800 text-hover-primary'>
            Parqour
          </a>
        </div>
        {/* end::Copyright */}

        <div className='d-flex flex-row ms-10'>
          <PrivacyModal headerContent={'PRIVACY.PRIVACY-ASIDE'} />
          <span className='ms-2 me-2'>{intl.formatMessage({id: 'PRIVACY.AND'})}</span>
          <OfferModal headerContent={'OFFER.OFFER-ASIDE'} />
        </div>
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
