import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Offer} from './Offer'

import './Offer.css'

type Props = {
  headerContent?: any
}

const OfferModal: FC<Props> = ({headerContent}) => {
  const [show, setShow] = useState(false)
  const intl = useIntl()

  return (
    <div className=''>
      <div
        className='text-primary fs-8  cursor-pointer'
        data-bs-toggle='modal'
        data-bs-target='#exampleModal'
        onClick={() => {
          setShow(true)
        }}
      >
        <span className='Offer__text_small'>{intl.formatMessage({id: headerContent})}</span>
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        animation={true}
        size={'lg'}
        centered
      >
        <Modal.Header
          closeButton
          onClick={() => {
            setShow(false)
          }}
        ></Modal.Header>
        <Modal.Body>
          <Offer />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export {OfferModal}
