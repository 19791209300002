import arrowLeft from "../../assets/ArrowLeft.svg";
import carIcon from "../../assets/add-car/selectCarIcon.png";
import {useLocation, useNavigate} from "react-router-dom";
import mainImage from '../../assets/editCarImage.png'
import {useEffect, useRef, useState} from "react";
import {axiosUserCarAdd, axiosUserCarDelete} from "../../../setup/axios/AxiosUser";
import {useDispatch} from "react-redux";
import {setCar, setDisplayCar} from "../../../setup/redux/actions/actions";
import {useIntl} from "react-intl";
import editCar from '../../assets/add-car/editCar.png'
import hashtag from '../../assets/add-car/hashtag.png'

import './MyCars.css'

export const EditCar: React.FC<{  }> = props => {
    const navigate = useNavigate()
    const location = useLocation()
    const intl = useIntl()
    const dispatch = useDispatch()
    const state: any = location.state;
    const car = state.car;
    const fromWhere = state.fromWhere;
    const isInitialMount = useRef(true); // add a ref to track initial mount

    const [plateNumber, setPlateNumber] = useState(car.plateNumber ? car.plateNumber : '')
    const [checked, setChecked] = useState(car.defaultCar ? car.defaultCar : false);
    const [deleteModal, setDeleteModal] = useState(false)
    const [carName, setCarName] = useState(car.carName ? car.carName : '')
    const [showButton, setShowButton] = useState(false);
    const [showHint, setShowHint] = useState(false);
    console.log(car)
    const handleToggle = () => {
        setChecked(!checked);
    };
    const handleAdd = () => {
        axiosUserCarAdd(plateNumber, carName, checked, car.carId).then((resp:any) => {
            console.log(resp)
            if(fromWhere === 'ParkingInfo'){
                dispatch(setDisplayCar(true))
                navigate('/parking-info')
            }else if(fromWhere === 'ParkingPayment'){
                dispatch(setCar(car))
                dispatch(setDisplayCar(true))
                navigate('/parking-payment')
            }else{
                navigate('/my-cars')
            }
        })
    }
    const handleDelete = () => {
        axiosUserCarDelete(car.plateNumber).then((resp: any) => {
            if(fromWhere === 'ParkingInfo'){
                dispatch(setDisplayCar(true))
                navigate('/parking-info')
            }else if(fromWhere === 'ParkingPayment'){
                dispatch(setCar(car))
                dispatch(setDisplayCar(true))
                navigate('/parking-payment')
            }else{
                navigate('/my-cars')
            }
        })
    }
    const handleBack = () => {
        if(fromWhere === 'ParkingInfo') {
            navigate('/parking-info', {state: {toSelectCar: true}})
        }else{
            navigate('/my-cars')
        }
    }
    useEffect(() => {
        console.log('Plate number and car name: ', plateNumber, carName)
        if (isInitialMount.current) {
            // skip the effect on the first mount
            isInitialMount.current = false;
        } else {
            if(checked === car.defaultCar && plateNumber === car.plateNumber && carName === car.carName){
                setShowButton(false)
            }else{
                setShowButton(true)

            }
        }
    }, [checked, plateNumber, carName]);

    return (
        <>
            {
                deleteModal && (
                    <>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            background: 'black',
                            opacity: '0.25',
                            position: 'fixed',
                            zIndex: '100'
                        }}>

                        </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '323px',
                                height: '185px',
                                background: 'white',
                                position: 'fixed',
                                zIndex: '1000',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                borderRadius: '16px',
                                padding: '10px',
                                gap: '20px'
                            }}>
                                <span className='sf_pro_large'>{intl.formatMessage({id: 'CAR.DELETE'})}</span>
                                <span className='authorization_description' style={{textAlign: 'start'}}>{intl.formatMessage({id: 'CAR.POPUP'})}</span>
                                <div className='d-flex gap-3 '>
                                    <button className='primary-button w-150px' onClick={() => handleDelete()}>{intl.formatMessage({id: 'ABONEMENT.DELETE.YES'})}</button>
                                    <button className='secondary-button w-150px' onClick={() => setDeleteModal(false)}>{intl.formatMessage({id: 'BTNS.CANCEL'})}</button>
                                </div>
                            </div>

                    </>
                )
            }

            <div style={{padding: '20px', height: '100%'}}>

                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex  align-items-center' style={{padding:'20px 0'}}>
                        <img src={arrowLeft} style={{width:'15px', height:'21px', marginRight:'10px'}} onClick={handleBack} />
                        <span className='back-header-title'>{intl.formatMessage({id: "PROFILE.MY-CARS"})}</span>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap:'10px',
                    height: '80%'
                }}>
                    <div className='d-flex justify-content-center'>
                        <img src={mainImage} style={{width: '248px'}} />
                    </div>

                    <div className='d-flex flex-column gap-3'>
                        <div className={`d-flex flex-column  justify-content-start mt-5`} style={{height:'80%', gap: '5px'}} >
                            <div className='d-flex flex-column'>
                                <span className='authorization_title mb-1'>{intl.formatMessage({id: 'CAR.INFO'})}</span>
                                <span className='authorization_description text-start mb-1'>{intl.formatMessage({id: 'CAR.ADD_INFO'})}</span>
                            </div>
                            <div className="input-container">
                                <div className="icon-container">
                                    <img src={editCar} alt="hashtag icon" />
                                </div>
                                <input type="text" id="input-field" name="input-field" value={carName} onChange={(e) => setCarName(e.target.value)} />
                                <label htmlFor="input-field">{intl.formatMessage({id: 'CAR.NAME'})}:</label>

                            </div>
                            <div className="input-container">
                                <div className="icon-container">
                                    <img src={hashtag} alt="hashtag icon" />
                                </div>
                                <input type="text" id="input-field" name="input-field" value={plateNumber} onChange={(e) => setPlateNumber(e.target.value)} />
                                <label htmlFor="input-field">{intl.formatMessage({id: 'CAR.PLATE_NUMBER'})}:</label>
                                {
                                    plateNumber.length < 4 && (
                                        <div className="error-icon">
                                            <i className="fa fa-exclamation-circle"></i>
                                        </div>
                                    )
                                }
                                {plateNumber.length < 4 && (
                                    <div className="hint-modal">
                                        <p>{intl.formatMessage({id: 'CAR.MIN'})}</p>
                                    </div>
                                )}
                            </div>


                            <div className='d-flex justify-content-between' style={{width:'100%', marginTop:'20px'}}>
                                <span className='add_city_description' >{intl.formatMessage({id: 'CAR.DEFAULT'})}</span>
                                <div className="toggle-switch">
                                    <label className="switch">
                                        <input type="checkbox" checked={checked} onChange={handleToggle} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center p-5 mb-5'>
                                <span style={{color: 'red'}} onClick={() => setDeleteModal(true)}>{intl.formatMessage({id: 'CAR.DELETE'})}</span>
                            </div>
                        </div>

                    </div>
                </div>
                {
                    showButton && <button
                        className={`${plateNumber === '' || carName === '' || plateNumber.length < 4 ? 'secondary' : 'primary'}-button w-100`}
                        onClick={() => handleAdd()}
                        disabled={plateNumber === '' || carName === '' || plateNumber.length < 4}
                    >
                        {intl.formatMessage({id: 'CAR.EDIT'})}
                    </button>
                }

            </div>
        </>
    );
};