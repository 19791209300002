/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import arrowLeft from '../../../assets/ArrowLeft.svg'
import cancelIcon from '../../../assets/add-car/cancelIcon.png'
import plateNumberGroup1 from '../../../assets/add-car/plateNumberGroup1.png'
import plateNumberGroup2 from '../../../assets/add-car/plateNumberGroup2.png'
import plateNumberGroup3 from '../../../assets/add-car/plateNumberGroup3.png'
import kz1 from '../../../assets/instruction/kz_1.png'
import kz2 from '../../../assets/instruction/kz_2.png'
import kg1 from '../../../assets/instruction/kg_1.png'
import kg2 from '../../../assets/instruction/kg_2.png'
import ruNumber from '../../../assets/instruction/ru.png'
import arm from '../../../assets/instruction/arm.png'


import infoIcon from '../../../assets/infoIcon.png'
import successfulMsg from '../../../assets/successfulMsg.png'
import {useIntl} from "react-intl";
import {Languages} from "../../../modules/languages/Languages";
import {useLang} from "../../../../_metronic/i18n/Metronici18n";
import {getCityList} from "../../../../setup/axios/AxiosParking";
import {citySelectAction, countryIdAction} from "../../../../setup/redux/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {useNavigate} from "react-router-dom";
import {axiosUserCarAdd} from "../../../../setup/axios/AxiosUser";

interface ModalWindowProps {
    isOpen: boolean;
    onClose: () => void;
}
interface Props{
    fromParkingInfo: boolean,
    parking: any,
    fromCarsList: any,
    setShowCarForm: any
}
export const ShowCarForm: FC<Props> = ({fromParkingInfo, parking, fromCarsList, setShowCarForm}) => {
    const intl = useIntl()
    const lang = useLang()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showSuccessfulMsg, setShowSuccessfulMsg] = useState(false)
    const storeCityId = useSelector((state: RootState) => state.cityId)

    const [cities, setCities] = useState([{id: 6, name: 'New-York', longitude: '-74.168852', latitude: '40.733170', countryId: 2,countryCode: 'US'}])
    const [showModal, setShowModal] = useState(false);
    const [showLanguages, setShowLanguages] = useState(false)
    const [checked, setChecked] = useState(false);
    const [plateNumber, setPlateNumber] = useState('')
    const [isDefault, setIsDefault] = useState(false)
    const [carName, setCarName] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    function handleClose() {
        setShowModal(false);
    }
    const handleToggle = () => {
        setIsDefault(!isDefault)
        setChecked(!checked);
    };
    const handleLanguages = ( ) => {
        setShowLanguages(true)
    }
    useEffect(() => {
        getCityList(lang ? lang : "ru").then((resp) => {
            resp.data.map((item: any) => {
                const {name, id, countryId} = item
                if (Number(id) === Number(storeCityId)) {
                    console.log("Item city: ", countryId)
                    dispatch(citySelectAction(name))
                    dispatch(countryIdAction(countryId))
                }
            })
            console.log("Cities: ", resp.data)
            setCities(resp.data)
        })
    }, []);
    const handleAdd = () => {
        console.log("State: ", {
            fromParkingInfo: fromParkingInfo,
            parking, fromCarsList, showSuccessfulMsg
        })
        if(fromCarsList && showSuccessfulMsg){
            navigate('/my-cars')
        }
        else if(!fromParkingInfo && !fromCarsList && showSuccessfulMsg){
            console.log("AAAAAAA")
            setShowCarForm(false)
        }
        else if(fromParkingInfo && showSuccessfulMsg){
            navigate('/parking-info', {state: {parking: parking}})
        }
        else{
            axiosUserCarAdd(plateNumber,carName, isDefault, 0).then((resp) => {
                console.log(resp.message)
                if(fromParkingInfo) {
                    setShowSuccessfulMsg(true)
                }
                else if(fromCarsList){
                    setShowSuccessfulMsg(true)
                }
                else if(showSuccessfulMsg){
                    navigate('/home-page')
                }else{
                    setShowSuccessfulMsg(true)
                }

            }).catch(err => {
                console.log("Error: ", err)
            })
        }

    }
    useEffect(() => {
        console.log("Is default?: ", isDefault)
    }, [isDefault]);


    function ModalWindow({ isOpen, onClose }: ModalWindowProps) {
        return (
            <div className={isOpen ? 'modal open' : 'modal'}>

                <div className="modal-content">
                    <div className='d-flex justify-content-between' style={{maxWidth:'270px'}}>
                        <span className='authorization_title'>{intl.formatMessage({id: 'CAR.INSTRUCTION'})}</span>
                        <button className="close-button" onClick={onClose}>
                            <img src={cancelIcon} />
                        </button>
                    </div>

                    <div className='d-flex flex-column' style={{gap:'10px', marginTop: '10px'}}>
                        <div className='d-flex flex-column gap-3'>
                            <span className='instruction_city'>{intl.formatMessage({id: 'HINT.KZ'})}</span>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex flex-column gap-3'>
                                    <span className='sf_pro_medium unselected_color'>{intl.formatMessage({id: 'HINT.FIRST.VARIANT'})}</span>
                                    <img src={kz1} />
                                    <div className='d-flex justify-content-center align-items-center sf_pro_medium'>A238VHN</div>
                                </div>
                                <div className='d-flex flex-column gap-3'>
                                    <span className='sf_pro_medium unselected_color'>{intl.formatMessage({id: 'HINT.SECOND.VARIANT'})}</span>
                                    <img src={kz2} />
                                    <div className='d-flex justify-content-center align-items-center sf_pro_medium'>237BLM01</div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-3'>
                            <span className='instruction_city'>{intl.formatMessage({id: 'HINT.KG'})}</span>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex flex-column gap-3'>
                                    <span className='sf_pro_medium unselected_color'>{intl.formatMessage({id: 'HINT.FIRST.VARIANT'})}</span>
                                    <img src={kg1} />
                                    <div className='d-flex justify-content-center align-items-center sf_pro_medium'>B0123BI</div>
                                </div>
                                <div className='d-flex flex-column gap-3'>
                                    <span className='sf_pro_medium unselected_color'>{intl.formatMessage({id: 'HINT.SECOND.VARIANT'})}</span>
                                    <img src={kg2} />
                                    <div className='d-flex justify-content-center align-items-center sf_pro_medium'>01123ABC</div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-3'>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex flex-column gap-3'>
                                    <span className='instruction_city'>{intl.formatMessage({id: 'HINT.RU'})}</span>
                                    <img src={ruNumber} />
                                    <div className='d-flex justify-content-center align-items-center sf_pro_medium'>C065MK78</div>
                                </div>
                                <div className='d-flex flex-column gap-3'>
                                    <span className='instruction_city'>{intl.formatMessage({id: 'HINT.ARM'})}</span>
                                    <img src={arm} />
                                    <div className='d-flex justify-content-center align-items-center sf_pro_medium'>01AD243</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }



    return (
        <>
            <div className={`d-flex flex-column ${showSuccessfulMsg && 'justify-content-between'}`} style={{ height: '100%',padding:' 0 10px'}}>
                {showModal && <ModalWindow isOpen={showModal} onClose={handleClose} />}
                {
                    showSuccessfulMsg ?
                        <>
                            <div className='d-flex justify-content-end'>
                                <Languages onLanguage={handleLanguages} />
                            </div>
                            <div className='d-flex flex-column align-items-center'>
                                <img src={successfulMsg} />
                                <div className='d-flex flex-column align-items-center'>
                                    {
                                        fromCarsList || (!fromCarsList && !fromParkingInfo) ?  <span className='authorization_title'>{intl.formatMessage({id: 'CAR.ADDED'})}</span> : (
                                            <>
                                                <span className='authorization_title'>{intl.formatMessage({id: 'CAR.SUCCESSFUL'})}</span>
                                                <span className='authorization_description mt-3'>{intl.formatMessage({id: 'CITY.CHANGE'})}</span>
                                            </>
                                        )

                                    }

                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex  align-items-center' style={{padding:'20px 0'}}>
                                    <img src={arrowLeft} style={{width:'15px', height:'21px', marginRight:'10px'}} onClick={() => navigate('/my-cars', )} />
                                    <span className='back-header-title'>{intl.formatMessage({id: 'CAR.ADD'})}</span>
                                </div>
                                <img src={infoIcon} onClick={() => setShowModal(true)} />
                            </div>
                            <div className={`d-flex flex-column align-items-center justify-content-start mt-5`} style={{height:'80%', gap: '5px'}} >
                                <input className={`add-car-form`} placeholder={intl.formatMessage({id: 'CAR.NAME'})} onChange={(e) => setCarName(e.target.value)} />
                                <input className={`add-car-form`} placeholder={intl.formatMessage({id: 'CAR.PLATE_NUMBER'})} onChange={(e) => setPlateNumber(e.target.value)} />
                                <div className='d-flex justify-content-between' style={{width:'100%', marginTop:'20px'}}>
                                    <span className='add_city_description' >{intl.formatMessage({id: 'CAR.DEFAULT'})}</span>
                                    <div className="toggle-switch">
                                        <label className="switch">
                                            <input type="checkbox" checked={checked} onChange={handleToggle}  />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                }
                {
                    // !fromCarsList && fromParkingInfo && (
                    <div className='d-flex p-5'>
                        <button
                            className={`${carName === '' || plateNumber === '' && !showSuccessfulMsg ? 'secondary' : 'primary'}-button  mb-3`}
                            disabled={carName === '' || plateNumber === '' && !showSuccessfulMsg}
                            style={{ width: '100%'}}
                            onClick={() => handleAdd()}
                        >
                            {showSuccessfulMsg ? intl.formatMessage({id: 'BTNS.CONTINUE'}) : intl.formatMessage({id: 'CAR.ADD'})}
                        </button>
                    </div>
                    // )
                }


            </div>
        </>

    )
}
