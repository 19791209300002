import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {LoaderForm} from './components/BillingLoaderForm'
import {PayBillingForm} from './components/PayBillingForm'
import {BillingErrorForm} from './components/BillingErrorForm'
import {FreeBillingForm} from './components/FreeBillingForm'
import {PayedBillingForm} from './components/PayedBillingForm'
import {RootState} from '../../../../setup'
import {bearerToken, billingFormAction} from '../../../../setup/redux/actions/actions'
import {axiosBillingTokenFunc, billingInfoFunc} from '../../../../setup/axios/AxiosPayments'
import {LoginRegistration} from '../../../pages/authorization/components/login-registration/LoginRegistration'

import './BillingForm.css'

type Props = {
  showParkingList?: any
  abonement?: any
}

const BillingForm: FC<Props> = ({showParkingList, abonement}) => {
  const [token, setToken] = useState<any>('')
  const [parkingGuidChecker, setParkingGuidChecker] = useState(false)

  const [carNumber, setCarNumber] = useState(' ')
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState('')
  const [balance, setBalance] = useState('')
  const [message, setMessage] = useState('');
  const [reToken, setReToken] = useState(false)

  const [freeMinutes, setFreeMinutes] = useState('')

  const [errorType, setErrorType] = useState('')

  const dispatch = useDispatch()

  const storeCarInfo = useSelector((state: RootState) => state.unauthCarAdd)
  const storeParkingName = useSelector((state: RootState) => state.parkingName)
  const storeParkingGuid = useSelector((state: RootState) => state.parkingGuid)
  const storeBillingForm = useSelector((state: RootState) => state.billingForm)
  const storeServiceId = useSelector((state: RootState) => state.serviceId)
  const storeAbonStartTime = useSelector((state: RootState) => state.abonStartTime)
  const storeAbonStartDate = useSelector((state: RootState) => state.abonStartDate)
  const storeAbonEndDate = useSelector((state: RootState) => state.abonEndDate)

  useEffect(() => {
    dispatch(billingFormAction('loader'))
    if (storeCarInfo) {
      setCarNumber(storeCarInfo)
    }

    if (localStorage.getItem('billingToken')) {
      setToken(localStorage.getItem('billingToken'))
    } else {
      axiosBillingTokenFunc().then((resp: any) => {
        dispatch(bearerToken(resp))
        setToken(resp)
      })
    }
  }, [])

  useEffect(() => {
    if (storeParkingGuid && !parkingGuidChecker) {
      setParkingGuidChecker(true)
    } else {
      showParkingList(true)
    }
  }, [storeParkingGuid])

  useEffect(() => {
    if (token) {
      billingInfoFunc(token, carNumber, storeParkingGuid, storeServiceId).then((resp: any) => {
        console.log("Sum amount: ", resp.result.sum, ", Balance: ", resp.result.current_balance)
        if (resp.code === 200) {
          console.log("RESULT: ", resp.result.result)
          switch (resp.result.result) {
            case 0:
              console.log(resp.result)  
              setAmount(resp.result.sum)
              setBalance(resp.result.current_balance)
              // if(resp.result.sum <= resp.result.current_balance){
              //   dispatch(billingFormAction('payed'));
              // }else{
              if (resp.result.currency) {
                  console.log(resp.result.currency)
                  if (resp.result.currency === 'KZT') {
                    setCurrency('\u20B8')
                  }
                  if (resp.result.currency === 'USD') {
                    setCurrency('\u0024')
                  }
                  if (resp.result.currency === 'KGS') {
                    console.log("KGS")
                    setCurrency('KGS')
                  }
                  if (resp.result.currency === 'UZS') {
                    setCurrency('UZS')
                  }
                
              }
              dispatch(billingFormAction('payed'));
              
              return
            case 1:
              if (resp.result.message === 'Некорректное значение - service_id') {
                setErrorType('errorInvalidId')
              }
              if (resp.result.message === 'Некорректный номер авто свяжитесь с оператором.') {
                console.log("ERROR INVALID CAR")
                setErrorType('errorInvalidCar')
              }
              if (resp.result.message === 'Пустое значение поля') {
                setErrorType('errorEmptyId')
              }
              if (resp.result.message === 'Извините, объект не доступен. Попробуйте позже') {
                setErrorType('errorParkingNotAvailable')
              }
              if (resp.result.message === 'Такого паркинг не существует') {
                setErrorType('errorParkingDoesntExist')
              }

              dispatch(billingFormAction('error'))
              return

            case 2:
              setFreeMinutes(resp.result.left_free_time_minutes)

              dispatch(billingFormAction('payed'))
              return

            case 3:
              setMessage(resp.result.message);
              setFreeMinutes(resp.result.left_free_time_minutes)
              dispatch(billingFormAction('payed'))
              return

            default:
              dispatch(billingFormAction('loader'))
              return
          }
        }
        if (resp.code === 401 && !reToken) {
          dispatch(billingFormAction('loader'))
          setReToken(true)
          axiosBillingTokenFunc().then((resp: any) => {
            dispatch(bearerToken(resp))
            setToken(resp)
          })
        }
      })
    }
  }, [token])

  switch (storeBillingForm) {
    case 'loader':
      return <LoaderForm />
    case 'auth':
      return <LoginRegistration form={'billingPage'} />
    case 'error':
      return <BillingErrorForm errorType={errorType} showParkingList={showParkingList} />
    case 'billing':
      return (
        <PayBillingForm
          parkingName={storeParkingName}
          carNumber={storeCarInfo}
          amount={amount}
          currency={currency}
          balance={balance}
          showParkingList={showParkingList}
          abonStartTime={storeAbonStartTime}
          abonStartDate={storeAbonStartDate}
          abonEndDate={storeAbonEndDate}
          abonement={abonement}
        />
      )
    case 'free':
      return (
        <FreeBillingForm
          freeMinutes={freeMinutes}
          parkingName={storeParkingName}
          showParkingList={showParkingList}
        />
      )
    case 'payed':
      return (
        <PayedBillingForm
          freeMinutes={freeMinutes}
          parkingName={storeParkingName}
          showParkingList={showParkingList}
          amount={amount}
          balance={balance}
          currency={currency!}
          abonStartTime={storeAbonStartTime}
          abonStartDate={storeAbonStartDate}
          abonEndDate={storeAbonEndDate}
          abonement={abonement}
          carNumber={carNumber}
          message={message}
        />
      )
    default:
      return <LoaderForm />
  }
 
  
}

export {BillingForm}
