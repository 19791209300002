const initialState = 'loader'

const billingFormReducer = (
  state = initialState,
  action: any
) => {
  const {type, payload} = action
  switch (type) {
    case 'SET_BILLING_FORM':
      return payload
    default:
      return state
  }
}

export {billingFormReducer}