import {FC} from 'react'
import {useIntl} from 'react-intl'

import {OfferModal} from '../../../../pages/offer/OfferModal'
import {PrivacyModal} from '../../../../pages/privacy/PrivacyModal'
import {PaymentMethodsForm} from '../../payment-methods-form/PaymentMethodsForm'
import arrowLeft from '../../../../assets/ArrowLeft.svg'

import './Components.css'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../../../App'
import tags from '../../../../assets/eventTags'

type Props = {
  parkingName: string
  carNumber: string
  amount: string
  balance: string
  showParkingList: any
  abonStartTime?: any
  abonStartDate?: any
  abonEndDate?: any
  currency?: any
  abonement: boolean
}

const PayBillingForm: FC<Props> = ({
  parkingName,
  carNumber,
  amount,
  balance,
  showParkingList,
  abonStartTime,
  abonStartDate,
  abonEndDate,
  abonement,
  currency,
}) => {
  const intl = useIntl()
  console.log("Sum amount: ", amount);
  console.log("Balance: ", balance);
  return (
    <div className='d-flex flex-column w-100 h-100 ps-10 pe-10'>
      {/* back button start */}
      <div
        className='d-flex me-5 flex-row justify-content-center align-items-center w-45px'
        onClick={() => {
          showParkingList(true)
          console.log("BACK BUTTON")
          logEvent(analytics, tags.back_button);
        }}
      >
        <img
          className='p-5 cursor-pointer'
          style={{fontSize: '32px', fontWeight: '400'}}
          src={arrowLeft}
        />
      </div>
      {/* back button end */}

      {/* price start */}
      <div className='d-flex flex-row justify-content-center'>
        <div className='d-flex w-100 align-items-center justify-content-between BillingForm__span_big'>
          <span>{intl.formatMessage({id: 'BILLING.BILLING-FORM.TO.PAY'})}</span>
          <span className='BillingForm__span_very-big'>
            {amount} {currency ? currency : '₸'}
          </span>
        </div>
      </div>
      {/* price end */}
      {/* parking start */}
      <div className='d-flex flex-row justify-content-center mb-1 mt-2'>
        <div className='d-flex w-100 align-items-center justify-content-between BillingForm__span_medium'>
          <span className='w-25'>{intl.formatMessage({id: 'BILLING.BILLING-FORM.PARKING'})}</span>
          <span className='w-75 BillingForm__span_medium d-flex justify-content-end'>
            {parkingName}
          </span>
        </div>
      </div>
      {/* parking end */}
      {/* car number start */}
      <div className='d-flex flex-row justify-content-center mb-2 mt-1'>
        <div className='d-flex w-100 align-items-center justify-content-between BillingForm__span_medium'>
          <span>{intl.formatMessage({id: 'BILLING.BILLING-FORM.PLATE-NUMBER'})}</span>
          <span className='BillingForm__span_medium'>{carNumber.toUpperCase()}</span>
        </div>
      </div>
      {/* car number end */}

      {/* abon start date start */}
      {abonement && (
        <div className='d-flex flex-row justify-content-center mb-2 mt-1'>
          <div className='d-flex w-100 align-items-center justify-content-between BillingForm__span_medium'>
            <span>{intl.formatMessage({id: 'ABONEMENT.START-DATE'})}</span>
            <span className='BillingForm__span_medium'>
              {abonStartDate} - {abonStartTime}
            </span>
          </div>
        </div>
      )}
      {/* car start date end */}
      {/* abon end date start */}
      {abonement && (
        <div className='d-flex flex-row justify-content-center mb-2 mt-1'>
          <div className='d-flex w-100 align-items-center justify-content-between BillingForm__span_medium'>
            <span>{intl.formatMessage({id: 'ABONEMENT.END-DATE'})}</span>
            <span className='BillingForm__span_medium'>
              {abonEndDate} - {abonStartTime}
            </span>
          </div>
        </div>
      )}
      {/* car end date end */}

      {/* border start */}
      <div className='mt-1 mb-8' style={{borderTop: 'solid 1px #EBEFFB'}}></div>
      {/* border end */}

      {/* payment methods start */}
      <div className='w-100 mb-5' style={{height: 'fit-content'}}>
        <PaymentMethodsForm />
      </div>
      {/* payment methods end */}

      {/* privacy/offer block start */}
      <div className='d-flex justify-content-center w-100 align-items-center'>
        <div className='d-flex flex-column w-100 mb-5'>
          {/* privacy offer modal start */}
          <div className='d-flex flex-row w-100 flex-wrap'>
            <span className='me-2 BillingForm__span_small'>
              {intl.formatMessage({id: 'PRIVACY.READ'})}
            </span>{' '}
            <PrivacyModal headerContent={'PRIVACY.PRIVACY'} />{' '}
            <span className='ms-2 me-2 BillingForm__span_small'>
              {intl.formatMessage({id: 'PRIVACY.AND'})}
            </span>{' '}
            <OfferModal headerContent={'OFFER.OFFER'} />
            <span className='ms-2 BillingForm__span_small'>
              {intl.formatMessage({id: 'PRIVACY.END'})}
            </span>
          </div>
          {/* privacy offer modal end */}
        </div>
      </div>
      {/* privacy/offer block end */}
    </div>
  )
}

export {PayBillingForm}
