import React, {useEffect, useRef, useState} from "react";
import { useQuery, useInfiniteQuery} from "react-query";
import {axiosPaymentHistory} from "../../../../../setup/axios/AxiosUser";
import {LoadingAnim} from "../../../../modules/Loader";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import emptyImage from '../../../../assets/EmptyHistory.png'

export const History: React.FC<{  }> = props => {
    const intl = useIntl()
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [historyData, setHistoryData] = useState([])
    const fetchParkings = async () => {
        const response = await axiosPaymentHistory(currentPage);
        setHistoryData(response.message.payments)
        return response.message.payments.filter((item: any) => item.paid === true || item.paid === false);
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
    } = useInfiniteQuery("parkings", fetchParkings, {
        getNextPageParam: (lastPage) => {
            // const { payments } = lastPage.nextPage.message;
            console.log("Last page: ", lastPage)
            const nextPage = lastPage.length > 0 ? lastPage.nextPage + 1 : undefined;
            return nextPage;
        },
    });


    return (
        <>
            {
                historyData.length < 1 ? (
                    <>
                        <div className='d-flex flex-column align-items-center justify-content-center' style={{height: '80%'}}>
                            <div className='d-flex align-items-center justify-content-center'>
                                <img style={{width: '256px', height: '256px'}} src={emptyImage} />
                            </div>
                            <div className='d-flex justify-content-center authorization_title text-center' style={{maxWidth: '210px'}}>
                                {intl.formatMessage({id: 'HISTORY.EMPTY'})}
                            </div>
                        </div>

                    </>

                ) :
                    (
                        <div className="d-flex flex-column mt-10 gap-3" style={{ overflowY: 'scroll', height: '100%' }}>
                            {isLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <LoadingAnim />
                                </div>
                            ) : (
                                <>

                                    {data?.pages.map((page, i) => (
                                        <React.Fragment key={i}>
                                            {page.map((parking: any) => (
                                                <div
                                                    key={parking.id}
                                                    style={{
                                                        width: '100%',
                                                        height: '230px',
                                                        border: '1px solid #E9E9E9',
                                                        background: '#FFFFFF',
                                                        borderRadius: '16px',
                                                        padding: '10px',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <div className='d-flex justify-content-between' style={{borderBottom: '1px solid #E9E9E9', padding: '5px'}}>
                                                        <div className='d-flex flex-column gap-1'>
                                                            <span style={{fontSize: '18px', fontWeight: '800', color: "#051C3F"}}>{parking.parkingName}</span>
                                                            <span style={{color: '#ADB9C9', fontSize: '14px', fontWeight: '400'}}>{parking.parkingAddress}</span>
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <span style={{color: '#127FEE', fontSize: '16px',fontWeight: '700'}}>{parking.amount} ₸</span>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between ' style={{borderBottom: '1px solid #E9E9E9', padding: '15px 0 15px 0', }}>
                                                        <div className='d-flex flex-column gap-5'>
                                                            <span className='date_and_time_text'>{intl.formatMessage({id: 'HISTORY.BEGIN'})} </span>
                                                            {/*<span className='date_and_time_text'>Время окончания: </span>*/}
                                                        </div>
                                                        <div className='d-flex flex-column gap-5'>
                                                            <span className='date_and_time_text'>{new Date(parking.createdAt).toLocaleDateString("en-US", {month: "2-digit", day: "2-digit", year: "numeric"}) + " - " + new Date(parking.createdAt).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})}</span>
                                                            {/*<span className='date_and_time_text'>{parking.modifiedAt}</span>*/}
                                                        </div>
                                                    </div>
                                                    <button className='primary-button mt-5' onClick={() => navigate('/payment/info', {state: {parking: parking}})}>{intl.formatMessage({id: 'MORE'})}</button>

                                                    {/* Parking information */}
                                                </div>
                                            ))}
                                        </React.Fragment>
                                    ))}


                                    {
                                        hasNextPage ? isFetchingNextPage ? (<div className='d-flex justify-content-center'><LoadingAnim /></div>) : (
                                            <button className='primary-button' onClick={() => {
                                                setCurrentPage(currentPage + 1)
                                                fetchNextPage()
                                            }
                                            } disabled={isFetchingNextPage}>
                                                {intl.formatMessage({id: 'HISTORY.LOAD.MORE'})}
                                            </button>
                                        ) : ''
                                    }
                                </>
                            )}

                        </div>
                    )
            }



        </>
    );
};