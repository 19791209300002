const abonEndDateFromStorage = localStorage.getItem('abonEndDate')

const abonEndDateReducer = (
    state:any = abonEndDateFromStorage ? abonEndDateFromStorage : null,
    action:any
) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_ABON_END_DATE":
            return payload;
        default:
            return state;
    }
};

export {abonEndDateReducer}