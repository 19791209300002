import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {RootState} from '../../../../../../setup'
import {UnauthTopbar} from '../Topbar/UnauthTopbar'
import {useIntl} from 'react-intl'
import {axiosLogout} from '../../../../../../setup/axios/AxiosAuth'
import {accessTokenAction} from '../../../../../../setup/redux/actions/actions'
import {Languages} from '../../../../../../app/modules/languages/Languages'

type Props = {
  div?: string
}

const HeaderContent: FC<Props> = ({div}) => {
  const loggedIn = useSelector((state: RootState) => state.token)
  const dispatch = useDispatch()
  const intl = useIntl()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(true);

    function toggleModal() {
        setShowModal(!showModal);
    }
  const storePhoneNumber = useSelector((state: RootState) => state.userInfoPhoneNumber)

  return (
    <div className={`d-flex ${div}`} style={{width: 'fit-content'}}>
      <div className='fs-5 d-flex justify-content-center align-items-center flex-shrink-0 me-6'>
        <div className='h-100 d-flex align-items-center'>
          <Languages onLanguage={toggleModal} />
        </div>
        <div className='text-align-center ms-5'>
          <span
            className='text-primary cursor-pointer'
            onClick={() => {
              navigate('/home-page')
            }}
          >
            {intl.formatMessage({id: 'ASIDE.HOME-PAGE'})}
          </span>
        </div>
        {loggedIn && (
          <div className='ms-5 text-align-center'>
            <div
              className='text-primary cursor-pointer d-flex flex-column'
              onClick={() => {
                navigate('/profile')
              }}
            >
              {intl.formatMessage({id: 'ASIDE.PROFILE'})}
              
            </div>
          </div>
        )}
        {loggedIn && (
          <div className='ms-5 text-align-center'>
            <div
              className='text-primary cursor-pointer d-flex flex-column'
              onClick={() => {
                navigate('/profile/parking-passes')
              }}
            >
              {intl.formatMessage({id: 'ASIDE.ABONEMENT'})}
            </div>
          </div>
        )}
      </div>
      <div className='fs-5 d-flex justify-content-center align-items-center flex-shrink-0'>
        {loggedIn ? (
          <div className='text-align-center'>
            <span
              className='text-primary cursor-pointer'
              onClick={() => {
                axiosLogout()
                dispatch(accessTokenAction(''))
              }}
            >
              {intl.formatMessage({id: 'AUTH.SIGNOUT'})}
            </span>
          </div>
        ) : (
          <UnauthTopbar />
        )}
      </div>
    </div>
  )
}

export {HeaderContent}
