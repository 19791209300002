/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import arrowLeft from '../../assets/ArrowLeft.svg'
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useLang} from "../../../_metronic/i18n/Metronici18n";
import {RootState} from "../../../setup";
import filterIcon from "../../modules/mapbox/assets/filterIcon.png";
import carIcon from '../../assets/carIcon.svg'
import blueCarIcon from '../../assets/add-car/blueCar.png'

import locationIcon from '../../modules/mapbox/assets/locationIcon.png'
import clockIcon from '../../modules/mapbox/assets/clockIcon.png'
import arrowIcon from '../../modules/mapbox/assets/arrowIcon.png'
import phoneIcon from '../../modules/mapbox/assets/phoneIcon.png'
import {ArrowRight} from "@material-ui/icons";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from "axios";
import './ParkingInfo.css'
import {SelectCar} from "./SelectCar";
import {axiosUserCars, axiosUserInfo} from "../../../setup/axios/AxiosUser";
import {
    addParkingGuidAction,
    addParkingNameAction,
    setDisplayCar,
    setParkingInfo
} from "../../../setup/redux/actions/actions";

interface CurrencySymbols {
    [key: string]: string;
}
export const ParkingInfo: FC = () => {
    const intl = useIntl()
    const lang = useLang()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const storeParkingInfo = useSelector((state: RootState) => state.storeParkingInfo)
    const displayCar = useSelector((state: RootState) => state.displayCars)
    const parkingState: any = location.state;
    const toSelectCar: any = parkingState ? parkingState.toSelectCar : false
    const parking = storeParkingInfo ? storeParkingInfo : parkingState.parking
    console.log("Parking info: ", parking)
    console.log("Dsiplay car: ", displayCar)
    dispatch(setParkingInfo(parking))
    const [selectedCar, setSelectedCar] = useState({plateNumber: '', carName: ''})
    const [selectCar, setSelectCar] = useState(displayCar ? displayCar : toSelectCar)
    const [images, setImages] = useState([])

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        cssEase: 'linear'
    };
    async function getUserInfo() {
        try {
            const response: any = await axiosUserInfo();
            let car: any = response.message.car;
            if(car){
                setSelectedCar({carName: car.carName, plateNumber: car.plateNumber})
            }

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        axios.get('https://api.parqour.app/mobile/public/parking/images/' + parking.id).then(resp => {
            console.log(resp)
            setImages(resp.data.parkingImages)
        }).catch(err => console.log(err))
        getUserInfo()
        dispatch(addParkingNameAction(parking.name))
        dispatch(addParkingGuidAction(parking.parkingUid))
    }, []);
    const handleBack = () => {
        if(selectCar){
            setSelectCar(false)
        }else{
            navigate('/search')
        }
    }
    const currencySymbols: CurrencySymbols = {
        "KZT": '₸',
        "KGS": 'с',
        "UZS": 'soʻm',
        "USD": '$',
        "RUB": '₽',
        "EUR": '€'
    };
    function getCurrencySymbol(code: string) {
        return currencySymbols[code];
    }
    const handlePayment = () => {
        dispatch(setDisplayCar(false))
        navigate('/parking-payment', {state: {plateNumber: selectedCar}})
    }
    let workHoursStart = parking.workSchedule.workDays[0].workHoursStart.slice(0, -3)
    let workHoursEnd = parking.workSchedule.workDays[0].workHoursEnd.slice(0, -3)
    return (
        <div style={{padding: '20px', height: '100%'}}>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex  align-items-center' style={{padding:'20px 0'}}>
                    <img src={arrowLeft} style={{width:'15px', height:'21px', marginRight:'10px'}} onClick={handleBack} />
                    <span className='back-header-title'>{!selectCar ? parking.name : intl.formatMessage({id: 'CAR.CHOOSE'})}</span>
                </div>
            </div>
            {
                selectCar ? (
                    <div className="button_wrapper responsive_width">
                        <SelectCar selectedCar={selectedCar} setSelectedCar={setSelectedCar} setSelectCar={setSelectCar} fromWhere={'ParkingInfo'} />
                    </div>
                ) :
                    (
                        <>
                            <Slider {...settings}>
                                {images.map(imageUrl => (
                                    <div key={imageUrl} >
                                        <img src={imageUrl}     alt="slider image" />
                                    </div>
                                ))}
                            </Slider>
                            <span className={'line'}></span>
                            <div className={'info-content-wrapper'}>
                                <span className="info_title" style={{color: '#051C3F'}}>{intl.formatMessage({id: "INFO"})}</span>
                                <div className={'info-content'}>
                                    <div className={'left-side'}>

                                        <div className={'highlighted_info'}><img src={carIcon} className={'mr'} /> {parking.parkingCapacity} {intl.formatMessage({id: 'MAIN.PLACES'})}</div>
                                        <div className={'info_text'}><img src={locationIcon} className={'mr'} /> {parking.address}</div>
                                        <div className={'info_text'} style={{
                                            display:'flex', alignItems: 'center'
                                        }}><img src={clockIcon} className={'mr'} />{workHoursStart + " - " + workHoursEnd}  </div>
                                        {/*<div><img src={phoneIcon} /> </div>*/}
                                    </div>
                                    <div className={'right-side'}>
                                        <span className={'highlighted_info mr'}>{parking.tariffRate} {parking.currency}</span>
                                        <span className={'info_text'}>{intl.formatMessage({id: 'HOURS'})}</span>
                                    </div>
                                </div>
                                <div className={'info_title mt-5'}> {parking.name}</div>
                            </div>


                            <span className={'line'}></span>
                        </>
                    )
            }


            <div className='d-flex flex-column responsive_width' style={{position: 'fixed', bottom: '5px'}}>
                {
                    !selectCar && (
                        <div className='d-flex flex-column'>
                            <span className={'line'}></span>
                            <div className={'select_auto'} onClick={() => {
                                setSelectCar(true)}
                            } style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                justifyContent:'space-between',
                                marginTop: '10px'
                            }}>
                                <div style={{
                                    display: 'flex',
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        gap: '5px',
                                        alignItems:'center'
                                    }} >
                                        {
                                            selectedCar.plateNumber.length > 0  ? (
                                                <div>
                                                    <img style={{width: '16px', height: '14px', marginRight: '10px'}} src={blueCarIcon} />
                                                    <span className='default_color'>{selectedCar.carName + ' (' + selectedCar.plateNumber + ')'}</span>
                                                </div>
                                            ) : (

                                                <>
                                                    <img style={{width: '16px', height: '14px', marginRight: '10px'}} src={carIcon} />
                                                    <span className='default_color'>{intl.formatMessage({id: 'CAR.CHOOSE'})}</span>
                                                </>
                                            )
                                        }

                                    </div>


                                </div>
                                <div style={{display: 'flex', justifyContent:'flex-end'}}>
                                    <img  src={arrowIcon} />
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className={selectedCar ? 'primary-button' : 'secondary-button'} style={{display: 'flex', alignItems: 'center', justifyContent:'center', marginTop:'20px', }}
                     onClick={() => {
                         selectCar ? navigate('/add-car', {state: {fromParkingInfo: true, parking: parking}}) : handlePayment()
                     }
                     }
                >
                    {
                        selectCar ? intl.formatMessage({id: 'CAR.ADD'}) : intl.formatMessage({id: 'BTNS.PAY'})
                    }
                </div>
            </div>


        </div>

    )
}
