
const currentLocationReducer = (
    state:any = [43.24764057651702,76.92229283522701],
    action:any
) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_CURRENT_LOCATION":
            return payload;
        default:
            return state;
    }
};

export {currentLocationReducer}