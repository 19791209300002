const initialState = null

const currentPaymentMethodReducer = (
    state = initialState,
    action: any
) => {
    const {type, payload} = action
    switch (type) {
        case 'SET_PAYMENT_METHOD':
            return payload ? payload : {}
        default:
            return state
    }
}

export {currentPaymentMethodReducer}