import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { showPlateNumberHint } from '../../../../setup/redux/actions/actions'
import exampleRU from '../../../assets/exampleRU.png'
import exampleEN from '../../../assets/exampleEN.jpg'
import exampleKZ from '../../../assets/exampleKZ.jpg'

type Props = {}

const PlateNumberModule = (props: Props) => {
    const showPlateNumber = useSelector((state: RootState) => state.showPlateNumberHint)
    const dispatch = useDispatch();
    const intl = useIntl();

    const languages = {
        'ru': exampleRU,
        'kk': exampleKZ,
        'en': exampleEN
    }
  return (
    <>
        {showPlateNumber && (
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch(showPlateNumberHint(false))}></button>
                    </div>
                    <div className="modal-body">
                        <img src={languages[intl.locale as keyof typeof languages]} alt="Example of plate numbers" style={{width: '300px', height: '300px'}} />
                    </div>
                    
                </div>
            </div>
        )}
        
    </>
  )
}

export default PlateNumberModule