import {FC, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ru from 'react-phone-input-2/lang/ru.json'


import {ImCancelCircle} from 'react-icons/im'

import './PhoneInputStyles.css'
import {useLang} from "../../../../../_metronic/i18n/Metronici18n";


interface CountryData {
  countryCode: string;
  dialCode: string;
  format: string;
  name: string;
}

type Props = {
  phone: string
  setPhone: any
  sendBtn: boolean
  codeSend: boolean
  setSendBtn: any
  setCodeSend: any
  setPhoneLength: any
}

const PhoneInputComponent: FC<Props> = ({phone, setPhone, sendBtn, setSendBtn, setCodeSend, codeSend,setPhoneLength}) => {
  const [changeNumber, setChangeNumber] = useState(false)
    const lang = useLang()

  const [countryData, setCountryData] = useState<CountryData>({
    countryCode: "KZ",
    dialCode: "7",
    format: "+... ... ... ...",
    name: "Kazakhstan",
  });

  useEffect(() => {
    if (!sendBtn || codeSend) {
      setChangeNumber(true)
    } else {
      setChangeNumber(false)
    }
  }, [codeSend, sendBtn])
  
  return (
    <div className='w-100 h-100 position-relative'>
      <PhoneInput
      containerClass={'PhoneInput__container'}
      buttonStyle={{border: 'none',borderTopLeftRadius: '18px', borderBottomLeftRadius: '18px', background:'none', padding:'5px'}}
      inputClass={clsx('form-control form-control-lg form-control-solid')}
      inputStyle={{width: '100%', border: '1px solid #E9E9E9', borderRadius:'18px', paddingLeft:'65px', height: '58px'}}
      country={'kz'}
      preferredCountries={[]}
      excludeCountries={['ru']}
      autoFormat={true}
      localization={lang === 'ru' ? ru : {}}
      value={phone}
      onChange={(e, country) => {
          console.log("AAAA")
        if ("dialCode" in country) {
          console.log(country.format)
          setCountryData(country)
          setPhoneLength(country.format.replace(/[\s()+-]+/g, '').length);
        }
      setPhone(e)
    }}
  />
      <span
          className='position-absolute highlighted_info' style={{
            left: '35px',
            bottom: '20px',
            fontWeight: '400',
            color: '#051C3F'
      }}>
        {countryData.countryCode.toUpperCase()}
      </span>
  {phone.length > 1 && <span
    className='position-absolute mt-2 me-4 end-0 fs-1 cursor-pointer'
    style={{top: '8px'}}
    onClick={() => {
      // setSendBtn(true)
      setCodeSend(false)
      setPhone('')
      }}>
    <ImCancelCircle />
  </span>}
  </div>
  )
}

export {PhoneInputComponent}
