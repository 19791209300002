import {FC} from 'react'
import {VscError} from 'react-icons/vsc'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {addParkingNameAction, unauthCarAdd} from '../../../../../setup/redux/actions/actions'

import './PaymentResult.css'
import errorIcon from "../../../../assets/error_frame.png";

const SaveCardErrorForm: FC = () => {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const intl = useIntl()
    return (
        <>
            <div className='d-flex flex-column p-10 pt-20 h-100' >
                <img src={errorIcon} />
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div className='d-flex flex-column gap-3 align-items-center' style={{height: '40%'}}>
                        <span className='authorization_title'>{intl.formatMessage({id: 'ERROR.TITLE'})}</span>
                        <span className='authorization_description'>{intl.formatMessage({id: 'ERROR.DESCRIPTION.CARD'})}</span>
                    </div>

                    <div className='d-flex flex-column gap-3 mb-5' style={{height: '60%'}}>
                        <button className='primary-button' onClick={() => navigate('/parking-payment')}>{intl.formatMessage({id: 'ERROR.TRY.AGAIN'})}</button>
                        <button className='secondary-button' onClick={() => navigate('/home-page')} >{intl.formatMessage({id: 'BTNS.CANCEL'})}</button>
                    </div>
                </div>

            </div>
        </>
    )
}

export {SaveCardErrorForm}
