import arrowLeft from "../../assets/ArrowLeft.svg";
import React, {useState} from "react";
import editCar from "../../assets/add-car/editCar.png";
import {useIntl} from "react-intl";
import {useLocation, useNavigate} from "react-router-dom";
import {axiosEditUser, axiosUserCarDelete, axiosUserDelete} from "../../../setup/axios/AxiosUser";
import {setCar, setDisplayCar} from "../../../setup/redux/actions/actions";
import {UserDeleteModal} from "../../modules/modal/user-delete/UserDeleteModal";
import {axiosLogout} from "../../../setup/axios/AxiosAuth";

export const ProfileEdit: React.FC<{  }> = props => {
    const intl = useIntl()
    const location = useLocation()
    const navigate = useNavigate()
    const state: any = location.state;
    const user = state.user
    const [name, setName] = useState(user.firstName === null ? ' ' : user.firstName + ' ' + user.lastName === null ? '' : user.lastName)
    const prevName = user.firstName === null ? ' ' : user.firstName + ' ' + user.lastName === null ? '' : user.lastName
    const [email, setEmail] = useState(user.email)
    const [deleteModal, setDeleteModal] = useState(false)
    console.log("User: ", user)

    const handleEdit = () => {
        axiosEditUser(name, email).then((resp: any) => {
            console.log("Response: ", resp)
            if(resp.message.message === 'Profile is updated!'){
                navigate('/home-page', {state: {returnTo: 'ProfilePage'}})
            }
        })
    }
    return (
        <>
            {
                deleteModal && (
                    <UserDeleteModal modalShow={deleteModal} setModalShow={setDeleteModal} />
                )
            }
            <div style={{padding: '20px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex  align-items-center' style={{padding:'20px 0'}}>
                        <img src={arrowLeft} style={{width:'15px', height:'21px', marginRight:'10px'}} onClick={() => navigate('/home-page', {state: {returnTo: 'ProfilePage'}})} />
                        <span className='back-header-title'>{intl.formatMessage({id: 'PROFILE.EDIT'})}</span>
                    </div>
                </div>
                <div className="input-container">
                    <input type="text" id="input-field" name="input-field" value={name} style={{paddingLeft: '1.3rem'}} onChange={(e) => setName(e.target.value)} />
                    <label htmlFor="input-field" style={{left: '1rem'}}>{intl.formatMessage({id: 'PROFILE.EDIT.NAME'})}:</label>
                </div>
                <div className="input-container">
                    <input type="text" id="input-field" name="input-field" value={user.username} disabled style={{paddingLeft: '1.3rem'}}  />
                    <label htmlFor="input-field" style={{left: '1rem'}}>{intl.formatMessage({id: 'PROFILE.EDIT.NUMBER'})}:</label>
                </div>
                <div className="input-container">
                    <input type="text" id="input-field" name="input-field" value={email} style={{paddingLeft: '1.3rem'}} onChange={(e) => setEmail(e.target.value)} />
                    <label htmlFor="input-field" style={{left: '1rem'}}>{intl.formatMessage({id: 'PROFILE.EDIT.EMAIL'})}:</label>
                </div>
                <div className='d-flex justify-content-center mt-10'>
                    <span style={{color: 'red'}} onClick={() => setDeleteModal(true)}>{intl.formatMessage({id: 'PROFILE.ACCOUNT-DELETE'})}</span>
                </div>
                <div className='d-flex flex-column justify-content-end h-100'>
                    <button className={`${prevName === name && user.email === email ? 'secondary' : 'primary'}-button`} onClick={() => handleEdit()}>{intl.formatMessage({id: 'BTNS.SAVE'})}</button>
                </div>
            </div>
        </>
    );
};