import {FC} from 'react'
import {useIntl} from 'react-intl'
import calendarImg from '../../assets/calendar.svg'
import timepickerImg from '../../assets/timepicker.svg'

import './AbonementInformationMobile.css'

const AbonementMobileEng: FC = () => {
  const intl = useIntl()

  return (
    <div className='d-flex flex-column justify-content-center p-5'>
      <div className='d-flex flex-column w-100 mb-4'>
        <span className='AbonementInformationModal__text_big'>A parking pass includes:</span>
        <div className='d-flex flex-column AbonementInformationModal__text_small'>
          <p>1. Saves time when you buy a parking pass</p>
          <p>2. Choose the most convenient fare plan</p>
          <p>3. Pay once and have an open pass for the entire period of your plan</p>
          <p>4. Best deal for your convenience</p>
          <p>
            Important! Each parking lot sets its own fare options. For more information, contact
            your parking administration
          </p>
        </div>
      </div>
      <div className='d-flex flex-column w-100'>
        <span className='AbonementInformationModal__text_big'>How does it work?</span>
        <span className='AbonementInformationModal__text_small mt-2'>1. Choose a start date</span>
        <div className='w-100 d-flex justify-content-center mt-4'>
          <img src={calendarImg} style={{width: '50%'}} alt={'Calendar image'} />
        </div>
        <span className='AbonementInformationModal__text_small mt-2'>2. Choose a start time</span>
        <div className='w-100 d-flex justify-content-center mt-4'>
          <img src={timepickerImg} style={{width: '50%'}} alt={'timepicker'} />
        </div>
        <span className='AbonementInformationModal__text_small'>3. Pay</span>
      </div>
    </div>
  )
}

export {AbonementMobileEng}
