const cityLngFromStorage = localStorage.getItem('cityLng')

const cityLngReducer = (
    state:any = cityLngFromStorage ? cityLngFromStorage : 76.92229283522701,
    action:any
) => {
    const { type, payload } = action;
    !cityLngFromStorage && localStorage.setItem('cityLng', '76.92229283522701')
    switch (type) {
        case "SET_CITY_LNG":
            !cityLngFromStorage && localStorage.setItem('cityLng', payload)
            return payload;
        default:
            return state;
    }
};

export {cityLngReducer}