import {FC, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {isMobile} from 'react-device-detect'

import {KaspiPay} from '../../../modules/forms/payment-methods-form/components/KaspiPay'
import {RootState} from '../../../../setup'

import '../../../modules/forms/payment-methods-form/PaymentMethodsForm.css'
import {useIntl} from 'react-intl'
import {axiosPaymentLinks} from '../../../../setup/axios/AxiosPaymentLinks'

import {axiosUserCards, axiosUserInfo} from '../../../../setup/axios/AxiosUser'

import { useNavigate } from 'react-router-dom'
import {getParkingList} from "../../../../setup/axios/AxiosParking";
import {useLang} from "../../../../_metronic/i18n/Metronici18n";
import settingsIcon from '../../../assets/payment-page/settings_icon.png'
import currencyIcon from '../../../assets/payment-page/currency_wrapper.png'

import { ReactComponent as CardIcon} from "../../../assets/payment-page/cardIcon.svg";

import {Cards} from "./Cards";
import {nambaPaymentFunc} from "../../../../setup/axios/AxiosPayments";


const apiBaseUrl = process.env.REACT_APP_API_PARQOUR
const apiBaseUrlMob = process.env.REACT_APP_API_MOBILE


type Props = {
    cardPayment: boolean,
    setCardPayment: () => void,
    billingInfo: any,
    setStep: any,
    userId: any,
    setUserId: any
}
interface CurrencySymbols {
    [key: string]: string;
}
const PaymentMethods: FC<Props> = ({cardPayment, setCardPayment, billingInfo, setStep, userId, setUserId}) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const lang = useLang()
    const loggedIn = useSelector((state: RootState) => state.token)
    const storeParkingName = useSelector((state: RootState) => state.parkingName)
    const storeCurrentPaymentMethod = useSelector((state:RootState) => state.currentPaymentMethod)
    const [kaspiLink, setKaspiLink] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [paymentProvider, setPaymentProvider] = useState<any>([])
    const [visaDicsount, setVisaDicsount] = useState('')
    const storeCarNumber = useSelector((state: RootState) => state.unauthCarAdd)
    const [selectedPayment, setSelectedPayment] = useState(false)
    console.log("Store current payment method: ", storeCurrentPaymentMethod)


    const parkingGuid = useSelector((state: RootState) => state.parkingGuid)
    useEffect(() => {
        console.log("Store current payment method: ", storeCurrentPaymentMethod)
        axiosUserInfo().then((resp) => {
            if (resp.code === 200) {
                setUserId(resp.message.id)
            }
            if (resp.code !== 200) {
                setErrorMessage('error')
            }
        })
        axiosPaymentLinks(parkingGuid)
            .then((resp: any) => {
                console.log("Payment links: ", resp)
                if (resp.code === 200) {
                    if (resp.data.link) {
                        setKaspiLink(resp.data.link + '=' + storeCarNumber)
                    }
                    if (resp.data.cardType) {
                        setVisaDicsount(resp.data.cardType)
                    }
                    if (resp.data.providerSet) {
                        resp.data.providerSet.map((providerSetItem:any) => {
                            if (providerSetItem === 'PITECH') {
                                setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                            }
                            if (providerSetItem === 'STRIPE') {
                                setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                            }
                            if (providerSetItem === 'NAMBA') {
                                setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                            }
                            if (providerSetItem === 'PAYZE') {
                                setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                            }
                        })
                    }
                }
                if (resp.code === 400) {
                    console.log('error')
                }
            })
            .catch((err) => {
                console.log(err)
            })


        getParkingList(lang).then((resp: any) => {
            console.log(resp)
        })
    }, [])

    useEffect(() => {
        console.log("Payment provider: ", paymentProvider)
    }, [paymentProvider]);

    const currencySymbols: CurrencySymbols = {
        "KZT": '₸',
        "KGS": 'с',
        "UZS": 'soʻm',
        "USD": '$',
        "RUB": '₽',
        "EUR": '€'
    };
    function getCurrencySymbol(code: string) {
        return currencySymbols[code];
    }
    return (
        <div className='d-flex flex-column align-items-center mt-10 gap-3'>
            {
                billingInfo.current_balance - billingInfo.sum < 0 && (
                    <div className='d-flex justify-content-between' style={{
                        height: '72px',
                        width: '100%',
                        padding:'12px',
                        background:'white',
                        border: '1px solid #E9E9E9',
                        borderRadius: '16px'
                    }}>
                        <div className='d-flex gap-3'>
                            <div style={{
                                width:'44px',
                                height:'44px',
                                background:'#F2F4F7',
                                borderRadius:'6px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent:'center',
                                fontWeight: '600'
                            }}>
                                {getCurrencySymbol(billingInfo.currency)}
                            </div>
                            <div className='d-flex flex-column gap-1' style={{whiteSpace: 'nowrap'}}>
                                <span className='parking_name_title' >{intl.formatMessage({id: 'PARKING.BALANCE'}, {PARKING_NAME: storeParkingName})}</span>
                                <span className='authorization_description on_surface_secondary' style={{textAlign: 'start'}}>{intl.formatMessage({id: 'PARKING.BALANCE'}, {PARKING_NAME: ''})}: {billingInfo.current_balance}</span>
                            </div>
                        </div>

                    </div>
                )
            }
            {
                paymentProvider.length > 0 && (
                    <div className='d-flex justify-content-between'
                         onClick={() =>
                         {
                             if(paymentProvider.includes('NAMBA')){
                                 nambaPaymentFunc(storeCarNumber, parkingGuid, null, userId, `${window.location.origin}/payment-success`).then((resp: any) => {
                                     if (resp.code === 200) {
                                         window.open(resp.message, '_self')
                                     }

                                     if (resp.code !== 200) {
                                         if (resp.message === 'error') {
                                             setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
                                             return
                                         }
                                     }
                                 })
                             }else{
                                 setSelectedPayment(!selectedPayment)
                             }
                         }

                         }
                         style={{
                             height: '72px',
                             width: '100%',
                             padding:'12px',
                             background:'white',
                             border: `1px solid ${selectedPayment ? '#157FFF' : '#E9E9E9'}`,
                             borderRadius: '16px'
                         }}>
                        <div className='d-flex gap-3'>
                            <div style={{
                                width:'44px',
                                height:'44px',
                                background:'#F2F4F7',
                                borderRadius:'6px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent:'center'
                            }}>
                                <CardIcon />
                            </div>
                            <div className='d-flex flex-column gap-1'>
                                <span className='add_city_description on_surface' style={{textAlign: 'start', fontWeight: '600'}} >{intl.formatMessage({id: 'BILLING.PAYMENT-METHODS.CARD'})}</span>
                                {
                                    storeCurrentPaymentMethod !== null && storeCurrentPaymentMethod.length > 0 && (
                                        <span className='authorization_description on_surface_secondary' style={{textAlign: 'start'}}>{storeCurrentPaymentMethod.cardMask ?
                                            <h4 className={` default_number `}>{storeCurrentPaymentMethod.cardMask.startsWith('4') ? 'Visa' : 'Mastercard'} {storeCurrentPaymentMethod.cardMask}</h4>
                                            :
                                            <h4 className={` default_number`}>{storeCurrentPaymentMethod.maskedPan.startsWith('4') ? 'Visa' : 'Mastercard'} {storeCurrentPaymentMethod.maskedPan}</h4>
                                        }</span>
                                    )
                                }

                            </div>

                        </div>
                        <div className='d-flex align-items-center' onClick={() => {
                            if(paymentProvider.includes('NAMBA')){
                                // Handle NAMBA payment provider
                            }else{
                                setCardPayment()
                                setStep(1)
                            }

                        }
                        }>
                            <img src={settingsIcon} style={{width: '20px', height:'20px'}}  />

                        </div>
                    </div>
                )
            }

            {kaspiLink && (
                <div className='PaymentMethodsForm__div_paymentType'>
                    <KaspiPay link={kaspiLink} />
                </div>
            )}
        </div>
    )
}

export {PaymentMethods}
