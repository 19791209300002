const paymentAmountFromStorage = localStorage.getItem('paymentAmount')

const paymentAmountReducer = (
    state:any = paymentAmountFromStorage ? paymentAmountFromStorage : '',
    action:any
) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_PAYMENT_AMOUNT":
            return payload;
        default:
            return state;
    }
};

export {paymentAmountReducer}