import React from "react";

export const ProfileIcon: React.FC<{ color: string }> = (props) => {
    return (
        <>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.9805 0C9.66731 0 8.36695 0.258658 7.1537 0.761205C5.94044 1.26375 4.83805 2.00035 3.90946 2.92893C2.0341 4.8043 0.98053 7.34784 0.98053 10C0.98053 12.6522 2.0341 15.1957 3.90946 17.0711C4.83805 17.9997 5.94044 18.7362 7.1537 19.2388C8.36695 19.7413 9.66731 20 10.9805 20C13.6327 20 16.1762 18.9464 18.0516 17.0711C19.927 15.1957 20.9805 12.6522 20.9805 10C20.9805 8.68678 20.7219 7.38642 20.2193 6.17317C19.7168 4.95991 18.9802 3.85752 18.0516 2.92893C17.123 2.00035 16.0206 1.26375 14.8074 0.761205C13.5941 0.258658 12.2937 0 10.9805 0ZM6.05053 16.28C6.48053 15.38 9.10053 14.5 10.9805 14.5C12.8605 14.5 15.4805 15.38 15.9105 16.28C14.5099 17.396 12.7714 18.0026 10.9805 18C9.12053 18 7.41053 17.36 6.05053 16.28ZM17.3405 14.83C15.9105 13.09 12.4405 12.5 10.9805 12.5C9.52053 12.5 6.05053 13.09 4.62053 14.83C3.55671 13.4446 2.9802 11.7467 2.98053 10C2.98053 5.59 6.57053 2 10.9805 2C15.3905 2 18.9805 5.59 18.9805 10C18.9805 11.82 18.3605 13.5 17.3405 14.83ZM10.9805 4C9.04053 4 7.48053 5.56 7.48053 7.5C7.48053 9.44 9.04053 11 10.9805 11C12.9205 11 14.4805 9.44 14.4805 7.5C14.4805 5.56 12.9205 4 10.9805 4ZM10.9805 9C10.5827 9 10.2012 8.84196 9.91987 8.56066C9.63857 8.27936 9.48053 7.89782 9.48053 7.5C9.48053 7.10218 9.63857 6.72064 9.91987 6.43934C10.2012 6.15804 10.5827 6 10.9805 6C11.3784 6 11.7599 6.15804 12.0412 6.43934C12.3225 6.72064 12.4805 7.10218 12.4805 7.5C12.4805 7.89782 12.3225 8.27936 12.0412 8.56066C11.7599 8.84196 11.3784 9 10.9805 9Z" fill={props.color}/>
            </svg>

        </>
    );
};