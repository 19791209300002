const userInfoPhoneNumberFromStorage = localStorage.getItem("userInfoPhoneNumber")

const userInfoPhoneNumberReducer = (state: any = userInfoPhoneNumberFromStorage ? userInfoPhoneNumberFromStorage : null, action:any) => {
    const {type, payload} = action
    switch (type) {
        case "SET_INFO_USER_PHONE_NUMBER":
            return payload
        default:
            return state
    }
}

export {userInfoPhoneNumberReducer}