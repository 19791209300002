import {FC, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {isMobile} from 'react-device-detect'

import {KaspiPay} from './components/KaspiPay'
import {RootState} from '../../../../setup'

import './PaymentMethodsForm.css'
import {useIntl} from 'react-intl'
import {axiosPaymentLinks} from '../../../../setup/axios/AxiosPaymentLinks'
import {CardPay} from './components/CardPay'
import {
  axiosSavedCardPayment,
  axiosSavedPayzePayment,
  nambaPaymentFunc,
  payzePaymentFunc,
  pitechPaymentFunc,
  stripePaymentFunc,
} from '../../../../setup/axios/AxiosPayments'
import {SavedCardModal} from '../../modal/saved-card/SavedCardModal'
import {axiosUserCards, axiosUserInfo} from '../../../../setup/axios/AxiosUser'

import masterCardLogo from '../../../assets/mastercard.svg'
import visaCardLogo from '../../../assets/visa.svg'
import ArrowRight from '../../../assets/ArrowRight.svg'
import { VisaPay } from './components/VisaPay'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const apiBaseUrl = process.env.REACT_APP_API_PARQOUR

const apiBaseUrlMob = process.env.REACT_APP_API_MOBILE


type Props = {
  offer?: boolean
}

const PaymentMethodsForm: FC<Props> = () => {
  const intl = useIntl()

  const loggedIn = useSelector((state: RootState) => state.token)
  const navigate = useNavigate()
  const [kaspiLink, setKaspiLink] = useState('')
  const [cardPayment, setCardPayment] = useState(false)
  const [cardLoader, setCardLoader] = useState(false)
  const [payzeCardId, setPayzeCardId] = useState('0')
  const [payzeCardMask, setPayzeCardMask] = useState('0')
  const [cardsModalShow, setCardsModalShow] = useState(false)

  const [cardsData, setCardsData] = useState<any[]>([])

  const [errorMessage, setErrorMessage] = useState('')

  const [stripePayment, setStripePayment] = useState(false)
  const [nambaPayment, setNambaPayment] = useState(false)

  const [cardsId, setCardsId] = useState('')
  const [cardsModalHeader, setCardsModalHeader] = useState('')
  const [cardIcon, setCardIcon] = useState('visa')
  const [cardsListLoaded, setCardsListLoaded] = useState(false)
  const [savedCardPayment, setSavedCardPayment] = useState(false)
  const [paymentProvider, setPaymentProvider] = useState<any>([])
  const [visaDicsount, setVisaDicsount] = useState('')

  const storeCarNumber = useSelector((state: RootState) => state.unauthCarAdd)
  const [userId, setUserId] = useState('0')
  const parkingGuid = useSelector((state: RootState) => state.parkingGuid)
  useEffect(() => {
    axiosUserInfo().then((resp) => {
      if (resp.code === 200) {
        setUserId(resp.message.id)
      }
      if (resp.code !== 200) {
        setErrorMessage('error')
      }
    })
    console.log("Parking id: ", parkingGuid)
    axiosPaymentLinks(parkingGuid)
        .then((resp: any) => {
          if (resp.code === 200) {
            console.log(resp.data)
            if (resp.data.link) {
              setKaspiLink(resp.data.link + '=' + storeCarNumber)
            }
            if (resp.data.cardType) {
              setVisaDicsount(resp.data.cardType)
            }
            if (resp.data.providerSet) {
              resp.data.providerSet.map((providerSetItem:any) => {
                if (providerSetItem === 'PITECH') {
                  setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                }
                if (providerSetItem === 'STRIPE') {
                  setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                }
                if (providerSetItem === 'NAMBA') {
                  setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                }
                if (providerSetItem === 'PAYZE') {
                  setPaymentProvider((previousState:any) => [...previousState, providerSetItem])
                }
              })
            }
          }
          if (resp.code === 400) {
            console.log('error')
          }
        })
        .catch((err) => {
          console.log(err)
        })

    if (loggedIn) {
      userCardsFunc()
    }
  }, [])

  useEffect(() => {
    if (paymentProvider.includes('PITECH')) {
      setCardPayment(true)
    }
  }, [paymentProvider])

  const userCardsFunc = () => {
    axiosUserCards().then((resp) => {
      if (resp.code === 200) {
        setCardsListLoaded(true)
        console.log("MESSAGE: ", resp.message)
        if (resp.message.length > 0) {
          setCardsData(resp.message)
          setSavedCardPayment(true)
          let cardLastFourNumbers = resp.message[0].maskedPan.split('-').slice(-1)[0]
          setCardsId(resp.message[0].cardsId)
          setCardsModalHeader(cardLastFourNumbers)
          setCardIcon(resp.message[0].issuer)
        }
        if (resp.message.length === 0) {
          axios.get(apiBaseUrlMob + '/user/payment/cards/all', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
          }).then((resp) => {
            console.log("Payze card: ", resp.data)
            if(resp.data.length > 0){
              setPayzeCardId(resp.data[0].cardId)
              setPayzeCardMask(resp.data[0].cardMask)
              setCardsData(resp.data)
            }

          })
          // setCardsData([])
        }
      }
      if (resp.code !== 200) {
        // axios.get('https://api.dev.parqour.app/mobile/user/payment/cards/all', {
        //     headers: {
        //       'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        //     }
        //   }).then((resp) => {
        //     console.log("Payze card: ", resp.data)
        //     setPayzeCardId(resp.data[0].cardId)
        //     setCardsData(resp.data)
        //   }).catch(err => {
        //     setErrorMessage('LOGIN.ERROR.SOMETHING-WENT-WRONG')
        //   })
        //   setCardsData([])

      }
    })
  }
  const cardPayzeFunc = () => {
    return payzePaymentFunc(storeCarNumber, parkingGuid, apiBaseUrl + "/payment/payze/result").then(
        (resp: any) => {
          if (resp.code === 200) {
            setCardLoader(false)
            if (resp.message === 'Не удалось проверить сумму к оплате') {
              setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
              return
            }
            if (resp.message === 'Платежный провайдер не доступен') {
              setErrorMessage('BILLING.PAYMENT-METHODS.PROVIDER-ERROR')
              return
            }
            if (resp.message === 'Сумма к оплате: 0') {
              setErrorMessage('BILLING.PAYMENT-METHODS.ZERO-ERROR')
              return
            }

            window.open(resp.message, '_self')
          }

          if (resp.code !== 200) {
            setCardLoader(false)
            navigate('/payment-error')
            // if (resp.message === 'error') {
            //   setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
            //   return
            // }
          }
        });
  }
  const cardStripeFunc = () => {
    const successUrl = `${window.location.origin}/payment-success`
    const errorUrl = `${window.location.origin}/payment-error`
    return stripePaymentFunc(storeCarNumber, parkingGuid, successUrl, errorUrl).then(
        (resp: any) => {
          if (resp.code === 200) {
            setCardLoader(false)
            if (resp.message.message === 'Не удалось проверить сумму к оплате') {
              setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
              return
            }
            if (resp.message.message === 'Платежный провайдер не доступен') {
              setErrorMessage('BILLING.PAYMENT-METHODS.PROVIDER-ERROR')
              return
            }
            if (resp.message.message === 'Сумма к оплате: 0') {
              setErrorMessage('BILLING.PAYMENT-METHODS.ZERO-ERROR')
              return
            }
            if (resp.message.message === 'Некорректный номер авто свяжитесь с оператором.') {
              setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
              return
            }

            window.open(resp.message, '_self')
          }
        }
    )
  }

  const cardNambaFunc = () => {
    const cardType = null
    return nambaPaymentFunc(storeCarNumber, parkingGuid, cardType).then((resp: any) => {
      if (resp.code === 200) {
        setCardLoader(false)
        window.open(resp.message, '_self')
      }

      if (resp.code !== 200) {
        setCardLoader(false)
        if (resp.message === 'error') {
          setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
          return
        }
      }
    })
  }
  const cardNambaFuncVisa = () => {
    const cardType = 'VISA';
    console.log("CARD NAMBA FUNC VISA")
    return nambaPaymentFunc(storeCarNumber, parkingGuid, cardType).then((resp: any) => {
      if (resp.code === 200) {
        setCardLoader(false)
        window.open(resp.message, '_self')
      }

      if (resp.code !== 200) {
        setCardLoader(false)
        if (resp.message === 'error') {
          setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
          return
        }
      }
    })
  }

  const cardsListFunc = () => {
    return cardsData.map((item: any, id: any) => {
      const {maskedPan, issuer, cardsId} = item
      const {cardMask} = item
      const cardsLastFourNumbers = cardMask ? cardMask :  maskedPan.split('-').slice(-1)[0]
      return (
          <div
              className='w-100 h-50px d-flex flex-row border border-secondary mb-4 bg-white justify-content-between'
              style={{borderRadius: '14px'}}
              key={id}
              onClick={() => {
                setCardsId(cardsId)
                setCardsModalHeader(cardsLastFourNumbers)
                setCardsModalShow(false)
                setCardIcon(issuer)

              }}
          >
            <div className='w-225px h-100 d-flex flex-row justify-content-center align-items-center'>
              <div className='ms-2 w-75px h-100 d-flex flex-row justify-content-center align-items-center'>
                {issuer === 'visa' && <img alt='Visa' style={{width: '50px'}} src={visaCardLogo} />}
                {issuer === 'mastercard' && (
                    <img style={{width: '50px'}} alt='Mastercard' src={masterCardLogo} />
                )}
                {cardMask && <span>Payze</span>}
              </div>
              <div
                  className='w-150px h-100 d-flex flex-row align-items-center ps-2'
                  style={{fontSize: '16px', fontWeight: '500'}}
              >
                <span className='me-2'>{intl.formatMessage({id: 'USER.CARDS.CARD'})}</span>
                <span>{cardsLastFourNumbers}</span>
              </div>
            </div>
            <div className='h-100 d-flex align-items-center justify-content-center w-40px cursor-pointer'>
              <img alt='confirm arrow' src={ArrowRight} />
            </div>
          </div>
      )
    })
  }
  const savedPayzePayFunc = () => {
    return axiosSavedPayzePayment(userId, storeCarNumber, parkingGuid, payzeCardId).then(
        (resp) => {
          if (resp.code === 200) {
            setCardLoader(false)
            navigate('/payment-success')
            // if (resp.message === 'Не удалось проверить сумму к оплате') {
            //   setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
            //   return
            // }
            // if (resp.message === 'Платежный провайдер не доступен') {
            //   setErrorMessage('BILLING.PAYMENT-METHODS.PROVIDER-ERROR')
            //   return
            // }
            // if (resp.message === 'Сумма к оплате: 0') {
            //   setErrorMessage('BILLING.PAYMENT-METHODS.ZERO-ERROR')
            //   return
            // }

            //  window.open(resp.message, '_self')
          }

          if (resp.code !== 200) {
            setCardLoader(false)
            navigate('/payment-error')
            // if (resp.message === 'error') {
            //   setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
            //   return
            // }
          }
        }
    )
  }
  const savedCardPayFunc = () => {
    const successUrl = `${window.location.origin}/payment-success`
    const errorUrl = `${window.location.origin}/payment-error`
    return axiosSavedCardPayment(storeCarNumber, parkingGuid, errorUrl, successUrl, cardsId).then(
        (resp) => {
          if (resp.code === 200) {
            setCardLoader(false)
            if (resp.message === 'Не удалось проверить сумму к оплате') {
              setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
              return
            }
            if (resp.message === 'Платежный провайдер не доступен') {
              setErrorMessage('BILLING.PAYMENT-METHODS.PROVIDER-ERROR')
              return
            }
            if (resp.message === 'Сумма к оплате: 0') {
              setErrorMessage('BILLING.PAYMENT-METHODS.ZERO-ERROR')
              return
            }
            window.open(resp.message, '_self')
          }

          if (resp.code !== 200) {
            setCardLoader(false)
            if (resp.message === 'error') {
              setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
              return
            }
          }
        }
    )
  }

  const cardPayFunc = () => {
    const successUrl = `${window.location.origin}/payment-success`
    const errorUrl = `${window.location.origin}/payment-error`
    return pitechPaymentFunc(storeCarNumber, parkingGuid, successUrl, errorUrl).then(
        (resp: any) => {
          if (resp.code === 200) {
            setCardLoader(false)
            if (resp.message === 'Не удалось проверить сумму к оплате') {
              setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
              return
            }
            if (resp.message === 'Платежный провайдер не доступен') {
              setErrorMessage('BILLING.PAYMENT-METHODS.PROVIDER-ERROR')
              return
            }
            if (resp.message === 'Сумма к оплате: 0') {
              setErrorMessage('BILLING.PAYMENT-METHODS.ZERO-ERROR')
              return
            }

            window.open(resp.message, '_self')
          }
          if (resp.code !== 200) {
            setCardLoader(false)
            if (resp.message === 'error') {
              setErrorMessage('BILLING.PAYMENT-METHODS.CHECK-ERROR')
              return
            }
          }
        }
    )
  }

  return (
      <div className='d-flex flex-column w-100 h-100 align-items-center'>
        {errorMessage && (
            <span style={{color: 'red', fontSize: '16px', fontWeight: '500'}} className='mb-2'>
              {intl.formatMessage({id: errorMessage})}
            </span>
        )}
        {loggedIn && cardsListLoaded && cardPayment && cardsData.length > 0 && (
            <div className='w-100 h-40px mb-5'>
              <SavedCardModal
                  cardsFunc={cardsListFunc}
                  cardsModalHeader={cardsModalHeader}
                  setModalShow={setCardsModalShow}
                  modalShow={cardsModalShow}
                  cardIcon={cardIcon}
                  anotherCardPaymentFunc={cardPayFunc}
                  setCardLoader={setCardLoader}
              />
            </div>
        )}
        {paymentProvider.includes('PITECH') && (
            <div className='d-flex flex-column w-100'>
              <div className='w-100 PaymentMethodsForm__div_paymentType'>
                <CardPay
                    cardPayFunc={savedCardPayment ? savedCardPayFunc : cardPayFunc}
                    cardLoader={cardLoader}
                    setCardLoader={setCardLoader}
                />
              </div>
              {/* {errorMessage && (
            <span style={{color: 'red', fontSize: '16px', fontWeight: '500'}} className='mb-2'>
              {intl.formatMessage({id: errorMessage})}
            </span>
          )} */}
            </div>
        )}
        {paymentProvider.includes('PAYZE') && (
            <div className='d-flex flex-column w-100'>
              <div className='PaymentMethodsForm__div_paymentType'>
                <CardPay
                    cardPayFunc={payzeCardId !== '0' ? savedPayzePayFunc : cardPayzeFunc}
                    cardLoader={cardLoader}
                    setCardLoader={setCardLoader}
                    isPayze={true}
                    payzeCardMask={payzeCardMask}
                />
              </div>
              {/* {errorMessage && (
            <span style={{color: 'red', fontSize: '16px', fontWeight: '500'}} className='mb-2'>
              {intl.formatMessage({id: errorMessage})}
            </span>
          )} */}
            </div>
        )}
        {paymentProvider.includes('NAMBA') && (
            <div className='d-flex flex-column w-100'>
              <div className='PaymentMethodsForm__div_paymentType'>
                <CardPay
                    cardPayFunc={cardNambaFunc}
                    cardLoader={cardLoader}
                    setCardLoader={setCardLoader}
                    isNamba={'namba'}
                />
              </div>
              {/* {errorMessage && (
            <span style={{color: 'red', fontSize: '16px', fontWeight: '500'}} className='mb-2'>
              {intl.formatMessage({id: errorMessage})}
            </span>
          )} */}
            </div>
        )}
        {visaDicsount &&  (
            <div className='d-flex flex-column w-100'>
              <div className='PaymentMethodsForm__div_paymentType'>
                <VisaPay
                    visaLogo={visaCardLogo}
                    cardPayFunc={cardNambaFuncVisa}
                    cardLoader={cardLoader}
                    setCardLoader={setCardLoader}
                />
              </div>
              {/* {errorMessage && (
            <span style={{color: 'red', fontSize: '16px', fontWeight: '500'}} className='mb-2'>
              {intl.formatMessage({id: errorMessage})}
            </span>
          )} */}
            </div>
        )}
        {paymentProvider.includes('STRIPE') && (
            <div className='d-flex flex-column w-100'>
              <div className='PaymentMethodsForm__div_paymentType'>
                <CardPay
                    cardPayFunc={cardStripeFunc}
                    cardLoader={cardLoader}
                    setCardLoader={setCardLoader}
                />
              </div>
              {/* {errorMessage && (
            <span style={{color: 'red', fontSize: '16px', fontWeight: '500'}} className='mb-2'>
              {intl.formatMessage({id: errorMessage})}
            </span>
          )} */}
            </div>
        )}
        {kaspiLink && (
            <div className='PaymentMethodsForm__div_paymentType'>
              <KaspiPay link={kaspiLink} />
            </div>
        )}
      </div>
  )
}

export {PaymentMethodsForm}
