import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import './Components.css'

type Props = {
  errorType?: string
  showParkingList?: any
}

const BillingErrorForm: FC<Props> = ({errorType, showParkingList}) => {
  const intl = useIntl()

  const [errorText, setErrorText] = useState('')

  useEffect(() => {
    switch (errorType) {
      case 'errorInvalidId':
        setErrorText(intl.formatMessage({id: 'BILLING.ERROR-FORM.ERROR-MESSAGE.INVALID-ID'}))
        return
      case 'errorInvalidCar':
        setErrorText(intl.formatMessage({id: 'BILLING.ERROR-FORM.ERROR-MESSAGE.INVALID-CAR'}))
        return
      case 'errorEmptyId':
        setErrorText(intl.formatMessage({id: 'BILLING.ERROR-FORM.ERROR-MESSAGE.EMPTY-ID'}))
        return
      case 'errorParkingNotAvailable':
        setErrorText(
          intl.formatMessage({id: 'BILLING.ERROR-FORM.ERROR-MESSAGE.PARKING-NOT-AVAILABLE'})
        )
        return
      case 'errorParkingDoesntExist':
        setErrorText(intl.formatMessage({id: 'LOGIN.ERROR.SOMETHING-WENT-WRONG'}))
        return
    }
  }, [])

  return (
    <div className='d-flex flex-column align-items-center justify-content-between w-100 h-100 rounded'>
      <div className='w-100 h-100 d-flex flex-column align-items-center mt-5'>
        <div className='fs-1 fw-bold text-danger'>{errorText}</div>
        <div className='fs-2 fw-bold text-dark mt-5'>
          {intl.formatMessage({id: 'BILLING.ERROR-FORM.TRY-AGAIN'})}
        </div>
      </div>
      <div className='d-flex flex-row w-100 border-top border-secondary'>
        <button
          className='h-40px w-100 justify-content-center btn btn-primary d-flex align-items-center'
          style={{borderRadius: '14px', background: '#127FEE', color: 'white'}}
          onClick={() => {
            showParkingList(true)
          }}
        >
          {intl.formatMessage({id: 'BTNS.BACK'})}
        </button>
      </div>
    </div>
  )
}

export {BillingErrorForm}
