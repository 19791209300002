
const displayCarsReducer = (
    state = false,
    action: any
) => {
    const {type, payload} = action
    switch (type) {
        case 'SET_DISPLAY_CARS':
            return payload
        default:
            return state
    }
}

export {displayCarsReducer}