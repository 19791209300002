import axios from "axios";
import { authLogin, authRegister, authTokenRefresh, authVerify } from "../endpoints";
import customAxios from "./CustomAxios";

const apiBaseUrl = process.env.REACT_APP_API_PARQOUR
const mobileDevUrl = process.env.REACT_APP_API_MOBILE

export const axiosPhoneAuth = (phoneNumber:string) => {
  return axios.post(mobileDevUrl + authLogin, {phoneNumber, deviceId: 'web'}).then((resp) => {
    
    return {code: resp.status, message: resp.data.message}
  }).catch((err) => {
    return {code: err.response.status, message: err.response.data?.message}
  })
}

export const axiosAuthCode = (phoneNumber: string, code: string) => {
  return axios.post(mobileDevUrl + authVerify, {phoneNumber, code}).then((resp) => {
    return {code: resp.status, message: {access: resp.data.accessToken, refresh: resp.data.refreshToken}}
  }).catch((err) => {console.log(err)
    return {code: err.response.status, message: err.response.data?.message}})
}

export const axiosAuth = async (username:string, password:string) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

    const urlencoded = new URLSearchParams()
    urlencoded.append('username', username)
    urlencoded.append('password', password)

    const config: any = {
      method: 'post',
      //url: apiBaseUrl + authLogin,
      url: mobileDevUrl + authLogin,
      headers: myHeaders,
      data: urlencoded,
    }
    try {
      const response = await axios(config)
      return {status: response.status, data: response.data}
    } catch (err:any) {
      return {status: err.response.status, data: err.response.data}
    }
  }

export const axiosRegistration = async (username:string, password:string, rePassword: string) => {
    const data = {
      username,
      password,
      rePassword,
      firstName: 'firstName',
      lastName: 'lastName',
    }
    const config: any = {
      method: 'post',
      url: mobileDevUrl + authRegister,
      //url: apiBaseUrl + authRegister,
      data: data,
    }
    try {
      const response = await axios(config)
      return {
          status: response.status, 
          data: response?.data
        }
    } catch (err:any) {
      return {
          status: err.response.status, 
          data: err.response.data.details
        }
    }
  }

export const refreshToken = async () => {
    const token = localStorage.getItem('refreshToken')
    let config: object = {
      method: 'post',
      url: mobileDevUrl + authTokenRefresh,
      //url: apiBaseUrl + authTokenRefresh,
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    }
    try {
      const response = await axios(config)
      return {accessToken: response.data.accessToken, refreshToken: response.data.refreshToken}
    } catch (err:any) {
      return {status: err.response.status, data: err.response.data}
    }
  }

export const axiosLogout = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
}

export const checkToken = () => {
  let config: any = {
    method: 'post',
    url: 'api/city/save',
  }
  customAxios(config)
    .then((resp: any) => {
      return resp
    })
    .catch((err) => {
      console.log(err)
      return err
    })
}