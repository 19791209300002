import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

const UnauthTopbar: FC = () => {
  const intl = useIntl()
  return (
    <div className='text-align-center'>
      <Link to='/auth' className='text-primary'>
        {intl.formatMessage({id: 'AUTH.LOGIN'})}
      </Link>
    </div>
  )
}

export {UnauthTopbar}
