import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {axiosAbonementDelete, axiosCarAbonement} from '../../../../setup/axios/AxiosAbonements'
import {LoaderForm} from '../../forms/billing-form/components/BillingLoaderForm'

import './AbonementDeleteModal.css'

type Props = {
  modalShow: boolean
  setModalShow: any
  parkingGuid: string
  abonementId: string
  plateNumber: string
  setAbonementList: any
  setParentErrorMessage: any
}

const AbonementDeleteModal: FC<Props> = ({
  modalShow,
  setModalShow,
  abonementId,
  parkingGuid,
  plateNumber,
  setAbonementList,
  setParentErrorMessage,
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [loader, setLoader] = useState(false)

  const intl = useIntl()

  const abonementDeleteFunc = () => {
    setLoader(true)
    axiosAbonementDelete(parkingGuid, abonementId).then((resp: any) => {
      if (resp.code === 200 && resp.message === true) {
        axiosCarAbonement(plateNumber).then((resp: any) => {
          setLoader(false)
          setModalShow(false)
          if (resp.code === 200) {
            if (resp.message.length > 0) {
              setAbonementList(resp.message)
            }
            if (resp.message.length === 0) {
              setAbonementList('')
            }
          }
          if (resp.code !== 200 && resp.message === 'error') {
            setParentErrorMessage('error')
          }
        })
      }
      if (resp.code !== 200) {
        setLoader(false)
        setErrorMessage('something went wrong')
      }
    })
  }

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
          {!loader && (
            <div className='d-flex flex-column h-150px justify-content-center'>
              <div className='w-100 justify-content-center fs-1 fw-bolder d-flex'>
                {intl.formatMessage({id: 'ABONEMENT.DELETE.ARE-YOU-SURE'})}
              </div>
              {errorMessage === 'something went wrong' && (
                <div>{intl.formatMessage({id: 'LOGIN.ERROR.SOMETHING-WENT-WRONG'})}</div>
              )}
              <div className='d-flex flex-row'>
                <button
                  className='h-40px w-50 justify-content-center btn d-flex align-items-center mt-5 me-2'
                  style={{
                    borderRadius: '14px',
                    background: '#127FEE',
                    color: 'white',
                    backgroundColor: 'rgb(18,127,238)',
                  }}
                  onClick={() => abonementDeleteFunc()}
                >
                  {intl.formatMessage({id: 'ABONEMENT.DELETE.YES'})}
                </button>
                <button
                  className='h-40px w-50 justify-content-center btn d-flex align-items-center mt-5 ms-2'
                  style={{
                    borderRadius: '14px',
                    background: '#127FEE',
                    color: 'white',
                    backgroundColor: 'rgb(18,127,238)',
                  }}
                  onClick={() => setModalShow(false)}
                >
                  {intl.formatMessage({id: 'ABONEMENT.DELETE.NO'})}
                </button>
              </div>
            </div>
          )}
          {loader && (
            <div className='d-flex flex-column h-150px'>
              <LoaderForm />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export {AbonementDeleteModal}
