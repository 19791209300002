/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import arrowLeft from '../../assets/ArrowLeft.svg'
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useLang} from "../../../_metronic/i18n/Metronici18n";
import './ParkingPayment.css'
import {axiosUserCards, axiosUserCars, axiosUserInfo} from "../../../setup/axios/AxiosUser";
import arrowIcon from "../../modules/mapbox/assets/arrowIcon.png";

import "react-datetime/css/react-datetime.css";
import {
    axiosBillingTokenFunc,
    axiosSavedCardPayment,
    axiosSavedPayzePayment,
    billingInfoFunc
} from "../../../setup/axios/AxiosPayments";
import {RootState} from "../../../setup";
import {
    bearerToken,
    billingFormAction,
    currentPaymentMethod,
    setParkingInfo
} from "../../../setup/redux/actions/actions";
import {PaymentMethods} from './components/PaymentMethods'
import {Cards} from "./components/Cards";
import {ParkingPay} from "../../assets/svg/ParkingPay";
import {CarIcon} from "../../assets/svg/CarIcon";
import {axiosAbonementCreate, axiosGetAbonementInfo} from "../../../setup/axios/AxiosAbonements";
import Slider from "react-slick";
import {CreateAbonement} from "./components/CreateAbonement";

import "react-calendar/dist/Calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import {LoadingAnim} from "../../modules/Loader";
import {SelectCar} from "../parking-info/SelectCar";
import {ShowCarForm} from "../add-car/components/ShowCarForm";
import axios from "axios";

const apiBaseUrlMob = process.env.REACT_APP_API_MOBILE
interface CurrencySymbols {
    [key: string]: string;
}

const ParkingPayment: FC = () => {
    const intl = useIntl();
    const lang = useLang();
    const navigate = useNavigate();
    const params = useParams();
    const fromReturn = params.return && params.return
    const location = useLocation();
    const state: any = location.state ? location.state : {};
    const plateNumber = state.plateNumber;
    const dispatch = useDispatch()
    const displayCar = useSelector((state: RootState) => state.displayCars)
    const storeCar = useSelector((state: RootState) => state.storeCar)
    const [currentStep, setCurrentStep] = useState(0);
    const [loading, setLoading] = useState(false)
    const [selectedCar, setSelectedCar] = useState(storeCar)
    const [selectCar, setSelectCar] = useState(displayCar)
    const [error, setError] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [token,setToken] = useState('');
    const [innerParkings, setInnerParkings] = useState([])
    const [selectedInnerParking, setSelectedInnerParking] = useState({id: 1})
    const storeServiceId = useSelector((state: RootState) => state.serviceId)
    const storeParkingGuid = useSelector((state: RootState) => state.parkingGuid)
    const storePaymentInfo = useSelector((state: RootState) => state.currentPaymentMethod)
    const storeCurrentPaymentMethod = useSelector((state:RootState) => state.currentPaymentMethod)
    const storeParkingInfo = useSelector((state: RootState) => state.storeParkingInfo)

    const [step, setStep] = useState(0)
    const [cardPayment, setCardPayment] = useState(false)
    const [abonementTypes, setAbonementTypes] = useState([{price: 5000}, {price: 6000}, {price: 6000}, {price: 6000}, {price: 6000}])
    const [showAbonementTypes, setShowAbonementTypes] = useState(false)
    const [selectedAbonement, setSelectedAbonement] = useState({id: 1})
    const [userId, setUserId] = useState('0')
    const [showCarForm, setShowCarForm] = useState(false)
    const [date, setDate] = useState("  /   /  ");
    const [time, setTime] = useState("");
    localStorage.setItem('parkingUid', storeParkingGuid)
    if(plateNumber){
        localStorage.setItem('carNumber', plateNumber.plateNumber)
        localStorage.setItem('carName', plateNumber.carName)
    }
    const currencySymbols: CurrencySymbols = {
        "KZT": '₸',
        "KGS": 'с',
        "UZS": 'soʻm',
        "USD": '$',
        "RUB": '₽',
        "EUR": '€'
    };
    function getCurrencySymbol(code: string) {
        return currencySymbols[code];
    }

    const handleDateChange = (e: any) => {
        setDate(e.target.value);
    };

    const handleTimeChange = (e: any) => {
        setTime(e.target.value);
    };
    const [billingInfo, setBillingInfo] = useState({
        current_balance: 0,
        sum: 0, currency: 'KZT'
    })
    if(fromReturn === 'true'){
        setCardPayment(true)
    }
    const handleStepChange = (step: any) => {
        setCurrentStep(step);
    };
    const [paymentTypes, setPaymentTypes] = useState([
        {name: intl.formatMessage({id: 'PARKING.PAYMENT'}), icon: <ParkingPay color={"#157FFF"} />, selectedIcon: <ParkingPay color={"white"} />, selected: true,type: 'fastPayment'},
        {name:intl.formatMessage({id: 'ONBOARDING.THIRD-SLIDE.HEADER'}), icon: <ParkingPay color={"#157FFF"} />,selectedIcon: <ParkingPay color={"white"} />, selected: false, type: 'abonement'}
    ])
    const handleCardSelectment = () => {
        setCardPayment(true)
    }
    const selectedCard = {
        color: '#FFF',
        gap:'5px',
        backgroundColor: "#157FFF"
    }
    const defaultCard = {
        color: '#B0B5D0',
        gap:'5px',
        backgroundColor: "#FFF"
    }
    const handlePaymentTypeClick = (index: number) => {
        if(index === 1 && storeParkingInfo.abonementsEnabled){
            axiosGetAbonementInfo(storeParkingGuid,lang ? lang  : 'RU').then((resp: any) => {
                if(resp.code === 500){

                }else{
                    setInnerParkings(resp.message.innerParkings);
                    setAbonementTypes(resp.message.abonementTypes);
                }

            })
            setShowAbonementTypes(true)
        }else{
            setShowAbonementTypes(false)
        }
        const updatedPaymentTypes = paymentTypes.map((paymentType, i) => {
            if (i === index) {
                return {...paymentType, selected: true};
            } else {
                return {...paymentType, selected: false};
            }
        });
        setPaymentTypes(updatedPaymentTypes);

    };
    const userCardsFunc = () => {
        // setLoading(true)
        axiosUserCards().then((resp) => {
            if (resp.code === 200) {
                console.log("User cards: ", resp.message )
                if (resp.message.length > 0) {

                    dispatch(currentPaymentMethod(resp.message[0] ? resp.message[0] : {}))
                }
                if (resp.message.length === 0) {

                    axios.get(apiBaseUrlMob + '/user/payment/cards/all', {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    }).then((resp) => {
                        dispatch(currentPaymentMethod(resp.data[0] ? resp.data[0] : {}))
                    })
                }
                setLoading(false)
            }
            if (resp.code !== 200) {
                axios.get(apiBaseUrlMob + '/user/payment/cards/all', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then((resp) => {
                    dispatch(currentPaymentMethod(resp.data[0] ? resp.data[0] : {}))

                })
                setLoading(false)
            }

        })
    }
    useEffect(() => {
        const handleGetParking = async () => {
            await axios.get(`https://api.parqour.app/mobile/public/parking/uid/${localStorage.getItem('parkingUid')}`).then((resp: any) => {
                if(resp.status === 200){
                    dispatch(setParkingInfo(resp.data))
                }
            })
        }
        handleGetParking()
            axiosBillingTokenFunc().then((resp: any) => {
                dispatch(bearerToken(resp))
                console.log(resp)
                setToken(resp)
                localStorage.setItem('billingToken', resp)
            })
        if(plateNumber){
            setSelectedCar({
                plateNumber: plateNumber.plateNumber,
                carName: plateNumber.carName
            })
            localStorage.setItem('carNumber', plateNumber.plateNumber)
            localStorage.setItem('carName', plateNumber.carName)
        }
        userCardsFunc()

    }, [])

    useEffect(() => {
        console.log("Token changed: ", token)
    }, [token]);


    useEffect(() => {
        console.log("Selected car: ", selectedCar)
    }, [selectedCar])
    const billingHandler = () => {
        console.log("Current payment: ", storeCurrentPaymentMethod)
        console.log("Type of current method: ", typeof(storeCurrentPaymentMethod))
        if(token && !showAbonementTypes){
            if(step === 0){
                billingInfoFunc(token, selectedCar.plateNumber, storeParkingGuid, storeServiceId).then((resp: any) => {
                    console.log(resp)
                    if(resp.result.result === 1 || resp.result.result === 44 ){
                        setError(true)
                    }else{
                        setBillingInfo(resp.result)
                        handleStepChange(1)
                    }
                })
            }
            if(step === 1){
                setCardPayment(false)
            }
            if(step === 2){
                setLoading(true)
                const successUrl = `${window.location.origin}/payment-success`
                const errorUrl = `${window.location.origin}/payment-error`
                storePaymentInfo.cardsId && axiosSavedCardPayment(selectedCar.plateNumber, storeParkingGuid, errorUrl, successUrl, storePaymentInfo.cardsId).then((resp: any) => {
                    console.log("Pitech payment: ",resp)
                    if (resp.code === 200) {
                        navigate('/payment-success')
                    }

                    if (resp.code !== 200) {
                        navigate('/payment-error')
                    }
                })
                storePaymentInfo.cardId && axiosSavedPayzePayment(userId, selectedCar.plateNumber, storeParkingGuid, storePaymentInfo.cardId).then((resp: any) => {
                    if (resp.code === 200) {
                        navigate('/payment-success')
                    }

                    if (resp.code !== 200) {
                        navigate('/payment-error')
                    }
                })
            }
        }else{
            if(step === 0){
                handleStepChange(1)
            }
            if(step === 1){
                console.log(selectedAbonement)
                const [year, month, day] = date.split('-'), [hour, minute] = time.split(':');
                const dateValue = `${year}-${month}-${day}T${hour}:${minute}`;
                console.log("Date value: ", dateValue)
                axiosAbonementCreate(selectedCar.plateNumber, storeParkingGuid,String(selectedInnerParking.id),String(selectedAbonement.id), dateValue).then((resp: any) => {
                    if (resp.code === 200 && resp.message === true) {
                        billingInfoFunc(token, selectedCar.plateNumber, storeParkingGuid, storeServiceId).then((resp: any) => {
                            console.log(resp)
                            if(resp.result.result === 1 || resp.result.result === 44 ){
                                setError(true)
                            }else{
                                handleStepChange(2)
                                setBillingInfo(resp.result)
                            }
                        })
                    }else{
                        setErrMsg('Что-то пошло не так :(')
                        setError(true)
                    }
                })
            }
            if(step === 2){
                const successUrl = `${window.location.origin}/payment-success`
                const errorUrl = `${window.location.origin}/payment-error`
                storePaymentInfo.cardsId && axiosSavedCardPayment(selectedCar.plateNumber, storeParkingGuid, errorUrl, successUrl, storePaymentInfo.cardsId).then((resp: any) => {
                    console.log("Pitech payment: ",resp)
                    if (resp.code === 200) {
                        navigate('/payment-success')
                    }

                    if (resp.code !== 200) {
                        navigate('/payment-error')
                    }
                })
                storePaymentInfo.cardId && axiosSavedPayzePayment(userId, selectedCar.plateNumber, storeParkingGuid, storePaymentInfo.cardId).then((resp: any) => {
                    if (resp.code === 200) {
                        navigate('/payment-success')
                    }

                    if (resp.code !== 200) {
                        navigate('/payment-error')
                    }
                })
            }

        }

        setStep(step + 1)
    }


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        cssEase: 'linear'
    };


    return (

                <>
                    {
                        error ? (
                            <div style={{
                                width: '100%',
                                height: '100%',
                                background: '#b5b7b9',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <div style={{
                                    width: '90%',
                                    height: '150px',
                                    borderRadius: '16px',
                                    backgroundColor: '#fff',

                                    padding: '10px'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        height: '100%',
                                    }}>
                                        <span className='authorization_title'>{intl.formatMessage({id: 'PARKING.UNAVAILABLE'})}</span>
                                        {/*<span className='date_and_time_text'>{errMsg ? errMsg : intl.formatMessage({id: 'PARKING.UNAVAILABLE.DESCRIPTION'})} </span>*/}
                                        <button className='primary-button' onClick={() => window.location.reload()}>{intl.formatMessage({id: 'BTNS.FURTHER'})}</button>
                                    </div>

                                </div>
                            </div>


                        ) : (
                            <>
                                {
                                    selectCar && !showCarForm && (
                                        <div className="d-flex align-items-center justify-content-between" style={{padding: '0 10px'}}>
                                            <div className="d-flex align-items-center" style={{ padding: '20px 0' }}>
                                                <img
                                                    src={arrowLeft}
                                                    style={{ width: '15px', height: '21px', marginRight: '10px' }}
                                                    onClick={() => setSelectCar(false)}
                                                />
                                                <span className="back-header-title">{intl.formatMessage({id: 'CAR.CHOOSE'})}</span>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    selectCar ?
                                        <div style={{padding: '0 10px', height: '100%'}} className={!showCarForm ? 'listOfCars' : ''}>
                                            {
                                                showCarForm ?   <ShowCarForm fromParkingInfo={false} parking={{}} fromCarsList={false} setShowCarForm={setShowCarForm} />
                                                    :           <SelectCar selectedCar={selectedCar} setSelectedCar={setSelectedCar} setSelectCar={setSelectCar} fromWhere={'ParkingPayment'} />
                                            }
                                        </div>
                                        : (
                                            <div className={error ? 'billing_modalShow' : 'billing_show'} >

                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center" style={{ padding: '20px 0' }}>
                                                        <img
                                                            src={arrowLeft}
                                                            style={{ width: '15px', height: '21px', marginRight: '10px' }}
                                                            onClick={() => navigate('/home-page')}
                                                        />
                                                        <span className="back-header-title">{intl.formatMessage({id: 'PARKING.PAYMENT'})}</span>
                                                    </div>
                                                </div>
                                                {
                                                    !cardPayment ?
                                                        (
                                                            <>
                                                                <div className='d-flex align-items-center flex-column justify-content-center gap-3 mt-10'>
                                                                    <span className='highlighted_info on_surface' >{currentStep === 0 ? intl.formatMessage({id: 'PARKING.FIRST_STEP'}) : intl.formatMessage({id: 'PARKING.SECOND_STEP'})} </span>
                                                                    <span className='authorization_description on_surface_secondary'>{currentStep === 0 ? intl.formatMessage({id: 'PARKING.PAYMENT_TYPE'}) : intl.formatMessage({id: 'PARKING.PAYMENT_METHOD'})} </span>
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-center mt-3">
                                                                    {
                                                                        !showAbonementTypes ? (
                                                                            <div className="stepper">

                                                                                <div
                                                                                    className={`step ${currentStep === 0 ? 'current' : currentStep > 0 ? 'completed' : ''}`}
                                                                                    onClick={() => handleStepChange(0)}
                                                                                ></div>
                                                                                <div className="line_gradient"></div>
                                                                                <div
                                                                                    className={`step ${currentStep === 1 ? 'current' : currentStep > 1 ? 'completed' : ''}`}
                                                                                    onClick={() => handleStepChange(1)}
                                                                                ></div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="stepper">

                                                                                <div
                                                                                    className={`step ${currentStep === 0 ? 'current' : currentStep > 0 ? 'completed' : ''}`}
                                                                                    onClick={() => handleStepChange(0)}
                                                                                ></div>
                                                                                <div className="line_gradient"></div>
                                                                                <div
                                                                                    className={`step ${currentStep === 1 ? 'current' : currentStep > 1 ? 'completed' : ''}`}
                                                                                    onClick={() => handleStepChange(1)}
                                                                                ></div>
                                                                                <div className="line_gradient"></div>
                                                                                <div
                                                                                    className={`step ${currentStep === 2 ? 'current' : currentStep > 2 ? 'completed' : ''}`}
                                                                                    onClick={() => handleStepChange(2)}
                                                                                ></div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                </div>
                                                                {
                                                                    loading ? (
                                                                            <div className='d-flex justify-content-center w-100 mt-10'>
                                                                                <LoadingAnim />
                                                                            </div>
                                                                        ) :
                                                                        currentStep === 0 ? (
                                                                            <>
                                                                                    <div className='d-flex gap-3 mt-10'>
                                                                                        {
                                                                                            paymentTypes.map((item: any, index: number) =>
                                                                                                ((item.type === 'abonement' && storeParkingInfo.abonementsEnabled) || item.type === 'fastPayment') && (
                                                                                                        <div className='payment_type_card d-flex flex-column p-4 red' style={item.selected ? selectedCard : defaultCard} onClick={() => handlePaymentTypeClick(index)}>
                                                                                                            <div>{item.selected ? item.selectedIcon : item.icon}</div>
                                                                                                            <span className={'title'}>{item.name}</span>
                                                                                                        </div>
                                                                                                    )
                                                                                            )

                                                                                        }

                                                                                    </div>
                                                                                {
                                                                                    showAbonementTypes && (
                                                                                        <>
                                                                                            <div className='d-flex flex-column mt-10 custom_slider'>
                                                                                                <span className='section_titles mb-5'>{intl.formatMessage({id: 'ABONEMENT.period'})}</span>

                                                                                                <Slider {...settings}>
                                                                                                    {abonementTypes.map((item: any) => (
                                                                                                        <div className={`abonement_card ${selectedAbonement === item ? 'selected_bg' : 'default_bg'}`} onClick={() => setSelectedAbonement(item)}>
                                                                            <span className={`highlighted_info
                                                                                ${
                                                                                !selectedAbonement ? 'default_color' : selectedAbonement === item ? 'selected_color' : 'unselected_color'
                                                                            }   
                                                                            `} >{item.period} {intl.formatMessage({id: 'ABONEMENT.DAYS'})}</span>
                                                                                                            <span className='sf_pro_small mt-1' style={{ color: selectedAbonement === item ? 'white' : '#157FFF'}}>{item.price} ₸</span>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </Slider>

                                                                                            </div>
                                                                                            <div className='d-flex justify-content-between gap-3 mt-5'>
                                                                                                <div className='d-flex flex-column w-100'>
                                                                                                    <label className='sf_pro_small'>{intl.formatMessage({id: 'ABONEMENT.START-DATE'})}</label>
                                                                                                    <input type="date" value={date}  onChange={handleDateChange} />
                                                                                                </div>
                                                                                                <div className='d-flex flex-column '>
                                                                                                    <label className='sf_pro_small text-nowrap'>{intl.formatMessage({id: 'ABONEMENT.SELECT-TIME'})}</label>
                                                                                                    <input type="time" value={time} onChange={handleTimeChange} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </>


                                                                                    )
                                                                                }

                                                                                <div className='d-flex flex-column mt-10 '>
                                                                                    <span className='section_titles'>{intl.formatMessage({id: 'CAR'})}</span>

                                                                                    <div className='car_card mt-3 ' onClick={() => setSelectCar(true)}>
                                                                                        <div className={`d-flex align-items-center  gap-3 `}>
                                                                                            <CarIcon color={'#157FFF'} />
                                                                                            {selectedCar.carName ? <span>{selectedCar.carName} ({selectedCar.plateNumber})</span> : <span>{intl.formatMessage({id: 'PARKING.CHOOSE_CAR'})}</span>}

                                                                                        </div>
                                                                                        <div style={{display: 'flex', justifyContent:'flex-end'}}>
                                                                                            <img  src={arrowIcon} />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </>
                                                                        ) : showAbonementTypes && currentStep === 1 ? <CreateAbonement innerParkings={innerParkings} setSelectedInnerParking={setSelectedInnerParking} selectedInnerParking={selectedInnerParking} /> :
                                                                            <PaymentMethods cardPayment={cardPayment} setCardPayment={handleCardSelectment} billingInfo={billingInfo} setStep={setStep} userId={userId} setUserId={setUserId} />
                                                                }
                                                            </> ) :
                                                        <Cards />
                                                }

                                            </div>
                                        )
                                }
                                {
                                    !showCarForm && (
                                        <div className='d-flex flex-column justify-content-end h-100 '>
                                            <div className={`bottom_button_wrapper ${billingInfo.sum === 0 && 'h-100px'} flex-column ${billingInfo && (billingInfo.current_balance - billingInfo.sum > 0 ? 'justify-content-center' : 'justify-content-between')}`}>
                                                {
                                                    Object.keys(billingInfo).length > 3 && (
                                                        <div className='d-flex justify-content-between p-5 w-100'>
                                        <span style={{
                                            fontWeight: 600,
                                            fontSize:'14px',

                                        }}>{intl.formatMessage({id: 'PARKING.TOTAL'})} </span>
                                                            <span style={{
                                                                fontWeight: 600,
                                                                fontSize: '14px',
                                                                color: '#157FFF'
                                                            }}>{billingInfo && (billingInfo.current_balance - billingInfo.sum > 0 ? '0' : billingInfo.sum - billingInfo.current_balance)} {billingInfo.currency} </span>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    billingInfo.current_balance - billingInfo.sum > 0 ? <div className='d-flex w-100 justify-content-center sf_pro_large'>{intl.formatMessage({id: 'ENOUGH.BALANCE'})}</div> : selectCar ?
                                                        <button className='primary-button responsive_width'  onClick={() => showCarForm ? setShowCarForm(false  ) : setShowCarForm(true)}>{'Добавить автомобиль'}</button>
                                                        :
                                                        <button className={`primary-button responsive_width`} onClick={billingHandler}>{cardPayment ? intl.formatMessage({id: "CITY.CHOOSE"}) : intl.formatMessage({id: 'BTNS.PAY'})}</button>

                                                }


                                            </div>
                                        </div>
                                    )

                                }

                            </>
                        )
                    }
                </>


    );
};

export default ParkingPayment;
