import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {isMobile} from 'react-device-detect'
import {BsQuestionCircle} from 'react-icons/bs'
import {useIntl} from 'react-intl'

import calendarImg from '../../../assets/calendar.svg'
import timepickerImg from '../../../assets/timepicker.svg'

import './AbonementInformationModal.css'

type Props = {
  modalShow: boolean
  setModalShow: any
}

const AbonementInformationModal: FC<Props> = ({modalShow, setModalShow}) => {
  const intl = useIntl()

  const [modalType, setModalType] = useState('')

  useEffect(() => {
    isMobile ? setModalType('mobile') : setModalType('web')
  }, [])

  return (
    <div className='d-flex h-40px w-100 justify-content-center '>
      <div
        className='fw-bolder w-100 ms-2 me-2 p-3 ParkingMenu__fontSize_big d-flex align-items-center ParkingMenu__bg cursor-pointer'
        onClick={() => {
          setModalShow(true)
        }}
      >
        <BsQuestionCircle style={{fontSize: '24px', color: '#127FEE'}} />
        <span className='ps-5'>{intl.formatMessage({id: 'ABONEMENT.HEADER'})}</span>
      </div>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName={`AbonementInformationModal__dialog_position-${modalType}`}
      >
        <Modal.Header
          closeButton
          onClick={() => {
            setModalShow(false)
          }}
        ></Modal.Header>
        <Modal.Body className='AbonementInformationModal__modal_body'>
          <div className='d-flex flex-column w-100 mb-4'>
            <span className='AbonementInformationModal__text_big'>
              {intl.formatMessage({id: 'ABONEMENT.ABONEMENT-IS'})}
            </span>
            <div className='d-flex flex-column AbonementInformationModal__text_small'>
              <p>{intl.formatMessage({id: 'ABONEMENT.ONE'})}</p>
              <p>{intl.formatMessage({id: 'ABONEMENT.TWO'})}</p>
              <p>{intl.formatMessage({id: 'ABONEMENT.THREE'})}</p>
              <p>{intl.formatMessage({id: 'ABONEMENT.FOUR'})}</p>
              <p>{intl.formatMessage({id: 'ABONEMENT.P-2'})}</p>
            </div>
          </div>
          <div className='d-flex flex-column w-100'>
            <span className='AbonementInformationModal__text_big'>
              {intl.formatMessage({id: 'ABONEMENT.HOW'})}
            </span>
            <span className='AbonementInformationModal__text_small mt-2'>
              {intl.formatMessage({id: 'ABONEMENT.ONE-1'})}
            </span>
            <div className='w-100 d-flex justify-content-center mt-4'>
              <img src={calendarImg} style={{width: '50%'}} alt={'Calendar image'} />
            </div>
            <span className='AbonementInformationModal__text_small mt-2'>
              {intl.formatMessage({id: 'ABONEMENT.TWO-2'})}
            </span>
            <div className='w-100 d-flex justify-content-center mt-4'>
              <img src={timepickerImg} style={{width: '50%'}} alt={'timepicker'} />
            </div>
            <span className='AbonementInformationModal__text_small'>
              {intl.formatMessage({id: 'ABONEMENT.THREE-3'})}
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  )
}

export {AbonementInformationModal}
