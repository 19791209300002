import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { RootState } from '../../../setup'
import {MdLocationPin} from 'react-icons/md'
import {cityIdAction, cityLatAction, cityLngAction, citySelectAction, countryIdAction} from '../../../setup/redux/actions/actions'
import './CityListDropdown.css'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../App'
import tags from '../../assets/eventTags'
import { useNavigate } from 'react-router-dom'
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import { getCityList } from '../../../setup/axios/AxiosParking'

type Props = {
  cityData: any
  city: any
  cityId: any
  setCenter: any
}

const CityListDropdown: FC<Props> = ({cityData, city, setCenter}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const storeCityLat = useSelector((state: RootState) => state.cityLat)
  const storeCityLng = useSelector((state: RootState) => state.cityLng)
        const lang = useLang()

  const cityListFunc = () => {
    return cityData.map((item: any) => {
      const {name, id, latitude, longitude} = item
      return (
        <option value={name} id={id} key={id} data-lat={latitude} data-lng={longitude}>
          {name}
        </option>
      )
    })
  }

  return (
    <div className='w-100 h-100 d-flex flex-row align-items-center'
    >
      <select
    className='w-100px h-100 fw-bold border-gray-500 cursor-pointer select-border'
    onChange={(e) => {
      console.log("City: ", e.target.value)
      logEvent(analytics, tags.city_selected, {
        city: e.target.value
      });
      console.log(e.target.options)
      dispatch(citySelectAction(e.target.value))
      getCityList(lang ? lang : "ru").then((resp) => {
         dispatch(countryIdAction(resp.data.find((item: { name: string }) => item.name === e.target.value).countryId)) 
      })
      
      dispatch(cityIdAction(e.target.options[e.target.options.selectedIndex].id))
      dispatch(cityLatAction(e.target.options[e.target.options.selectedIndex].getAttribute('data-lat')))
      dispatch(cityLngAction(e.target.options[e.target.options.selectedIndex].getAttribute('data-lng')))
    }}
    value={city ? city : ''}
  >
    {cityListFunc()}
  </select>
  
  </div>
  )
}

export {CityListDropdown}
