import {combineReducers} from 'redux'
import { unauthCarAddReducer } from './reducers/unauthCarAddReducer'
import { billingBearerTokenReducer } from './reducers/billingBearerTokenReducer'
import { addParkingNameReducer } from './reducers/addParkingNameReducer'
import { authReducer } from './reducers/authReducer'
import { selectCityReducer } from './reducers/selectCityReducer'
import { addParkingGuidReducer } from './reducers/addParkingGuidReducer'
import { billingFormReducer } from './reducers/billingFormReducer'
import { userLocationReducer } from './reducers/userLocationReducer'
import { serverErrorReducer } from './reducers/serverErrorReducer'
import { addCityIdReducer } from './reducers/addCityIdReducer'
import { onboardingReducer } from './reducers/onboardingReducer'
import { chooseParkingFormReducer } from './reducers/chooseParkingFormReducer'
import { serviceIdReducer } from './reducers/serviceIdReducer'
import { abonStartTimeReducer } from './reducers/abonStartTimeReducer'
import { abonStartDateReducer } from './reducers/abonStartDateReducer'
import { abonEndDateReducer } from './reducers/abonEndDateReducer'
import { userInfoPhoneNumberReducer } from './reducers/userInfoPhoneNumber'
import { cityLatReducer } from './reducers/cityLatReducer'
import { cityLngReducer } from './reducers/cityLngReducer'
import { paymentAmountReducer } from './reducers/paymentAmountReducer'
import { currentLocationReducer } from './reducers/currentLocationReducer'
import { showPlateNumberHintReducer } from './reducers/showPlateNumberHint'
import { countryIdReducer } from './reducers/countryIdReducer'
import {showParkingInfoReducer} from "./reducers/showParkingInfoReducer";
import {filtersReducer} from "./reducers/ParkingFilterReducer";
import {currentPaymentMethodReducer} from "./reducers/currentPaymentMethodReducer";
import {storeParkingInfoReducer} from "./reducers/storeParkingInfoReducer";
import {displayCarsReducer} from "./reducers/displayCarsReducer";
import {carReducer} from "./reducers/carReducer";
import {geolocateControlReducer} from "./reducers/geolocateControllRecuder";

export const rootReducer = combineReducers({
  unauthCarAdd: unauthCarAddReducer,
  billingToken: billingBearerTokenReducer,
  parkingName: addParkingNameReducer,
  token: authReducer,
  city: selectCityReducer,
  parkingGuid: addParkingGuidReducer,
  billingForm: billingFormReducer,
  chooseParkingForm: chooseParkingFormReducer,
  userLocation: userLocationReducer,
  serverError: serverErrorReducer,
  cityId: addCityIdReducer,
  onboarding: onboardingReducer,
  serviceId: serviceIdReducer,
  abonStartTime: abonStartTimeReducer,
  abonStartDate: abonStartDateReducer,
  abonEndDate: abonEndDateReducer,
  userInfoPhoneNumber: userInfoPhoneNumberReducer,
  cityLat: cityLatReducer,
  cityLng: cityLngReducer,
  paymentAmount: paymentAmountReducer,
  currentLocation: currentLocationReducer,
  showPlateNumberHint: showPlateNumberHintReducer,
  countryId: countryIdReducer,
  showParkingInfo: showParkingInfoReducer,
  filters: filtersReducer,
  currentPaymentMethod: currentPaymentMethodReducer,
  storeParkingInfo: storeParkingInfoReducer,
  displayCars: displayCarsReducer,
  storeCar: carReducer,
  triggerGeolocation: geolocateControlReducer
})

export type RootState = ReturnType<typeof rootReducer>

