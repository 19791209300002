import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'
import { PaymentMethodsForm } from '../../payment-methods-form/PaymentMethodsForm'
import { isMobile } from 'react-device-detect'

import './Components.css'
import { PayBillingForm } from './PayBillingForm'

type Props = {
  freeMinutes: string
  parkingName: string
  showParkingList?: any
  amount?: string
  balance?: string
  currency?: string,
  abonStartTime?: any
  abonStartDate?: any
  abonEndDate?: any
  abonement?: any,
  carNumber?: any,
  message?: any,
}

const PayedBillingForm: FC<Props> = ({parkingName, freeMinutes, showParkingList, amount, balance, currency, abonement, abonStartTime,
  abonStartDate,
  abonEndDate, carNumber, message}) => {
  const intl = useIntl()
    const storeCarInfo = useSelector((state: RootState) => state.unauthCarAdd)
    const storeParkingGuid = useSelector((state: RootState) => state.parkingGuid)
  const storeBillingForm = useSelector((state: RootState) => state.billingForm)
  const storeServiceId = useSelector((state: RootState) => state.serviceId)
  const storeAbonStartTime = useSelector((state: RootState) => state.abonStartTime)
  const storeAbonStartDate = useSelector((state: RootState) => state.abonStartDate)
  const storeAbonEndDate = useSelector((state: RootState) => state.abonEndDate)
    const storeParkingName = useSelector((state: RootState) => state.parkingName)
  
  const [payBilling, setPayBilling] = useState(false);
  let remains = 0;
  let toPay = 0;
  if(amount !== undefined && balance !== undefined){
    remains =  parseFloat(balance) - parseFloat(amount) ;
    toPay = parseInt(balance) === 0 ? parseFloat(amount) : remains < 0 ? remains * (-1) : 0;
  }
  // remains = balance - amount
  // toPay = balance === 0 ? amount : remains < 0 ? remains * (-1) : 0;
  console.log("Parking PayedBillingForm: ", parkingName === '')
  return (
    !payBilling ?
    <div style={{padding: '0 30px 0 15px'}} className='p-5 w-100 h-100 d-flex flex-column bg-body rounded justify-content-between' 
      ref={(node) => {
        if (node && isMobile) {
          node.style.setProperty("height", "700px", "important");
        }
      }}
    >
      <div className='d-flex flex-column w-100 align-items-center mt-5'>
            {freeMinutes ? (
              <>
                {
                  parkingName !== '' && (
                    <div className='d-flex flex-row justify-content-between w-100'>
                      <span className='fs-6 fw-bold'>
                        {intl.formatMessage({id: 'BILLING.FREE/PAYED-FORM.PARKING'})}:
                      </span>
                      <span className='fs-5 fw-bolder ms-4'>{parkingName}</span>
                    </div>
                  )
                }
                
                <div className='d-flex flex-row justify-content-between w-100 mt-4'>
                  <span className='fs-4 fw-bold'>{intl.formatMessage({id: "BILLING.BILLING-FORM.PLATE-NUMBER"})}</span>
                  <span className='fs-5 fw-bolder ms-4'>{carNumber}</span>
                </div>
                <div className='d-flex flex-row justify-content-between w-100 mt-4'>
                  <span className='fs-4 fw-bold'>{intl.formatMessage({id: 'BILLING.FREE/PAYED-FORM.FREE-MINUTES'})}:</span>
                  <span className='fs-5 fw-bolder ms-4'>{freeMinutes}</span>
                </div>
              </>
            ) : 
              <>
                  { parkingName !== '' && (
                      <div className='d-flex flex-row justify-content-between w-100'>
                        <span className='fs-6 fw-bold'>
                            {intl.formatMessage({id: 'BILLING.FREE/PAYED-FORM.PARKING'})}:
                          </span>
                        <span className='fs-5 fw-bolder ms-4'>{parkingName}</span>
                      </div>
                  )}
                  
                  <div className='d-flex flex-column w-100 mt-20'>
                    <div className='d-flex flex-row justify-content-between w-100 mt-4'>
                      <span className='fs-4 fw-bold'>{intl.formatMessage({id: "BILLING.PAYMENT-FORM.SUM"})} </span>
                      <span className='fs-5 fw-bolder ms-4' style={{display: 'inline-block', borderBottom: '1px solid black', paddingBottom: '1px'}}>{amount} {currency}</span>
                    </div>
                    <div className='d-flex flex-row justify-content-between w-100 mt-4'>
                      <span className='fs-4 fw-bold'>{intl.formatMessage({id: "BILLING.PAYMENT-FORM.BALANCE"})} </span>
                      <span className='fs-5 fw-bolder ms-4'>
                        {balance !== undefined ? parseFloat(balance) : 0} {currency}
                        </span>
                    </div>
                    <div className='d-flex flex-row justify-content-between w-100 mt-4'>
                      <span className='fs-4 fw-bold'>{intl.formatMessage({id: "BILLING.BILLING-FORM.PLATE-NUMBER"})}</span>
                      <span className='fs-5 fw-bolder ms-4'>{carNumber}</span>
                    </div>
                    {/* abon start date start */}
      {abonement && (
        <div className='d-flex flex-row justify-content-center mb-2 mt-1'>
          <div className='d-flex w-100 align-items-center justify-content-between BillingForm__span_medium'>
            <span>{intl.formatMessage({id: 'ABONEMENT.START-DATE'})}</span>
            <span className='BillingForm__span_medium'>
              {abonStartDate} - {abonStartTime}
            </span>
          </div>
        </div>
      )}
      {/* car start date end */}
      {/* abon end date start */}
      {abonement && (
        <div className='d-flex flex-row justify-content-center mb-2 mt-1'>
          <div className='d-flex w-100 align-items-center justify-content-between BillingForm__span_medium'>
            <span>{intl.formatMessage({id: 'ABONEMENT.END-DATE'})}</span>
            <span className='BillingForm__span_medium'>
              {abonEndDate} - {abonStartTime}
            </span>
          </div>
        </div>
      )}
                    <div className='d-flex flex-row justify-content-center mt-6'>
                      <div className='d-flex w-100 align-items-center justify-content-between BillingForm__span_big'>
                        <span>{intl.formatMessage({id: 'BILLING.BILLING-FORM.TO.PAY'})}</span>
                        <span className='BillingForm__span_very-big'>
                          {toPay} {currency ? currency : '₸'}
                        </span>
                      </div>
                    </div>
                    {/* <div className='d-flex flex-row justify-content-between w-100 mt-4'>
                      <span className='fs-5 fw-bolder'>{intl.formatMessage({id:  "BILLING.PAYMENT-FORM.TO_PAY"})} </span>
                      <span className='fs-5 fw-bolder ms-4' style={{color: 'blue'}}>{toPay} {currency}</span>
                    </div> */}
                    
                  </div>
                  <div className='d-flex flex-column w-100' style={{marginTop: '20%'}}>
                    {toPay === 0 ? "" : <PaymentMethodsForm />}
                    {/* <button className='h-40px w-100 justify-content-center btn btn-primary d-flex align-items-center' disabled={toPay === 0} onClick={() => setPayBilling(true)}>{intl.formatMessage({id: 'BILLING.PAYMENT-FORM.DEPOSIT'})} {toPay}</button> */}
                  </div>
              </>
            }
      </div>
      {/* <div className='d-flex flex-column w-100 h-100px align-items-center mt-5'>
        
      </div> */}
      <div className='d-flex flex-row w-100 border-top border-secondary'>
        <div className='d-flex flex-column w-100 p-2'>
          <button
            className='h-40px w-100 justify-content-center btn btn-primary d-flex align-items-center'
            style={{borderRadius: '14px', background: '#127FEE', color: 'white'}}
            onClick={() => {
              showParkingList(true)
            }}
          >
            {intl.formatMessage({id: 'BTNS.BACK'})}
          </button>
        </div>
      </div>
    </div>

    :

    <PayBillingForm 
          parkingName={storeParkingName}
          carNumber={storeCarInfo}
          amount={toPay?.toString()!}
          currency={currency}
          balance={balance!}
          showParkingList={showParkingList}
          abonStartTime={storeAbonStartTime}
          abonStartDate={storeAbonStartDate}
          abonEndDate={storeAbonEndDate}
          abonement={abonement}
    />
    // <div className='p-5 w-100 h-100 d-flex flex-column bg-body rounded justify-content-between'>
    //   <div className='d-flex flex-column w-100 h-100px align-items-center mt-5'>
    //     <div className='d-flex flex-row justify-content-between w-100'>
    //       <span className='fs-6 fw-bold'>
    //         {intl.formatMessage({id: 'BILLING.FREE/PAYED-FORM.PARKING'})}
    //       </span>
    //       <span className='fs-5 fw-bolder ms-4'>{parkingName}</span>
    //     </div>
    //     <div className='d-flex flex-row justify-content-between w-100 mt-4'>
    //       <span className='fs-6 fw-bold'>
    //         {intl.formatMessage({id: 'BILLING.FREE/PAYED-FORM.FREE-MINUTES'})}:
    //       </span>
    //       <span className='fs-5 fw-bolder ms-4'>
    //         {freeMinutes} {intl.formatMessage({id: 'BILLING.FREE/PAYED-FORM.MINUTES'})}
    //       </span>
    //     </div>
    //   </div>
    //   <div className='d-flex flex-row w-100 border-top border-secondary'>
    //     <div className='d-flex flex-column w-100 p-2'>
    //       <button
    //         className='h-40px w-100 justify-content-center btn btn-primary d-flex align-items-center'
    //         style={{borderRadius: '14px', background: '#127FEE', color: 'white'}}
    //         onClick={() => {
    //           showParkingList(true)
    //         }}
    //       >
    //         {intl.formatMessage({id: 'BTNS.BACK'})}
    //       </button>
    //     </div>
    //   </div>
    // </div>
  )
}

export {PayedBillingForm}
