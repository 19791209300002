const carFromStorage = localStorage.getItem('carNumber')

const unauthCarAddReducer = (
    state:any = carFromStorage ? carFromStorage : null,
    action:any
) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_UNAUTH_CAR":
            return payload;
        default:
            return state;
    }
};

export {unauthCarAddReducer}