const cityFromStorage = localStorage.getItem('city')

const selectCityReducer = (
    state:any = cityFromStorage ? cityFromStorage : null,
    action:any
) => {
    const {type, payload} = action
    switch (type) {
        case 'SET_LOCATION_CITY':
            return payload
        default:
            return state 
    }
}

export {selectCityReducer}