const tags = {
    onboarding_finished: "onboarding_finished",
    onboarding_skipped: "onboarding_skipped",
    registered: "registered", //
    authorized: "authorized", //
    city_selected: "city_selected",
    instant_pay_select: "instant_pay_select", //
    parking_pass_select: "parking_pass_select", //
    booking_select: "booking_select", //
    parking_select: "parking_select",
    kaspi_select: "kaspi_select",
    namba_pay_select: "namba_pay_select",
    pitech_pay_select: "pitech_pay_select",
    delete_pitech_card: "delete_pitech_card",
    save_pitech_card: "save_pitech_card",
    parking_payment: "parking_payment",
    enter_number_plate: "enter_number_plate",
    pay_by_card: "pay_by_card",
    pay_button: "pay_button",
    back_button: "back_button"
}
export default tags;