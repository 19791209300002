/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import arrowLeft from '../../assets/ArrowLeft.svg'
import {
    citySelectAction,
    countryIdAction,
    currentLocationAction,
    onboardingAction, setParkingInfo
} from "../../../setup/redux/actions/actions";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import searchIcon from "../../assets/search_icon.svg";
import emptySearch from "../../assets/empty-search.png";
import {useLang} from "../../../_metronic/i18n/Metronici18n";
import {RootState} from "../../../setup";
import {getCityList, getParkingListCity} from "../../../setup/axios/AxiosParking";
import filterIcon from "../../modules/mapbox/assets/filterIcon.png";
import './SearchParking.css'
import locationIcon from '../../modules/mapbox/assets/locationIcon.png'
import carIcon from '../../modules/mapbox/assets/carIcon.png'
import {ArrowRight} from "@material-ui/icons";
interface CurrencySymbols {
    [key: string]: string;
}
export const SearchParking: FC = () => {
    const intl = useIntl()
    const lang = useLang()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showSuccessfulMsg, setShowSuccessfulMsg] = useState(false)
    const [showCityList, setShowCityList] = useState(false)
    const storeCityId = useSelector((state: RootState) => state.cityId)
    const [filteredItems, setFilteredItems] = useState([]);
    const [parkings, setParkings] = useState([])
    const [searchIcon, setSearchIcon] = useState(false);
    const currentLocation = useSelector((state: RootState) => state.currentLocation)
    const userLatitude = currentLocation[0]
    const userLongitude = currentLocation[1]

    const cityId = localStorage.getItem('cityId') || '1'
    const filters = useSelector((state:RootState) => state.filters.filters)

    const currencySymbols: CurrencySymbols = {
        "KZT": '₸',
        "KGS": 'с',
        "UZS": 'soʻm',
        "USD": '$',
        "RUB": '₽',
        "EUR": '€'
    };
    function getCurrencySymbol(code: string) {
        return currencySymbols[code];
    }
    useEffect(() => {
        const fetchData = async () => {
            const resp = await getParkingListCity(cityId, localStorage.getItem('cityLat'), localStorage.getItem('cityLng'), lang);
            console.log("Parkings of cityId 3: ", resp?.data)
            setParkings(resp?.data)
            let parking = resp?.data;
            let filteredParkingsSet = new Set()
            let filteredParkings = []
            // fix all values filter
            console.log("Filter type: ", filters)
            if(filters.length > 0){
                parking.map((item:any) => {
                    if(filters.includes('all')){
                        filteredParkingsSet.add(item)
                    }else{
                        filters.map((filter:any) => {
                            console.log(filter)
                            if(item[filter]){
                                console.log("Item: ", item)
                                filteredParkingsSet.add(item)
                            }
                        })
                    }

                })
                filteredParkings = Array.from(filteredParkingsSet)
            }else{
                filteredParkings = parking
            }

            setFilteredItems(filteredParkings.sort((parkingA: any, parkingB: any) => {
                const distanceA = calcDistance(userLatitude, userLongitude, parkingA.latitude, parkingA.longitude);
                const distanceB = calcDistance(userLatitude, userLongitude, parkingB.latitude, parkingB.longitude);
                return distanceA - distanceB;
            }));
        };

        fetchData();
    }, []);


    function calcDistance(lat1:any, lon1: any, lat2: any, lon2:any) {
        const R = 6371e3; // earth radius in meters
        const phi1 = lat1 * Math.PI / 180;
        const phi2 = lat2 * Math.PI / 180;
        const deltaPhi = (lat2 - lat1) * Math.PI / 180;
        const deltaLambda = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
            Math.cos(phi1) * Math.cos(phi2) *
            Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const d = R * c; // distance in meters
        return d;
    }
    const handleSearchTextChange = (event: any) => {
        setSearchIcon(true)
        const filteredItems = parkings.filter((item:any) =>
            item.name.toLowerCase().includes(event.target.value.toLowerCase())
        );
        console.log("Filtered items: ", filteredItems)

        setFilteredItems(filteredItems.sort((parkingA: any, parkingB: any) => {
            const distanceA = calcDistance(userLatitude, userLongitude, parkingA.latitude, parkingA.longitude);
            const distanceB = calcDistance(userLatitude, userLongitude, parkingB.latitude, parkingB.longitude);
            return distanceA - distanceB;
        }));
    };
    useEffect(() => {
        parkings.map((parking: any) => {
            console.log(parking.name)
        })
    }, [parkings]);

    return (
        <div style={{padding: '20px'}}>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex  align-items-center' style={{padding:'20px 0'}}>
                    <img src={arrowLeft} style={{width:'15px', height:'21px', marginRight:'10px'}} onClick={() => navigate('/home-page')} />
                    <span className='back-header-title'>{intl.formatMessage({id: 'PARKING.SEARCH'})}</span>
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent:'space-between',
                gap:'10px'
            }}>
                <input type="text" placeholder={intl.formatMessage({id: 'SEARCH'})} className="search-input"  onChange={handleSearchTextChange} style={{backgroundImage: `${searchIcon ? `url(${searchIcon})` : ''}`}} />
                <div className='filter'>
                    <img src={filterIcon} onClick={() => navigate('/parking-filter', {state: {isFromSearch: true}})} />
                </div>
            </div>


            <div className={`d-flex flex-column ${!showCityList && 'align-items-center justify-content-center'}`} >
                {
                    filteredItems?.map((parking: any) => (
                        <div className={'parking_card'} onClick={() => {
                            dispatch(setParkingInfo(parking))
                            navigate('/parking-info')
                        }
                        }>
                            <img src={parking.iconUrl} />
                            <div className={'main_info'}>
                                <span>{parking.name}</span>
                                <span className={'info_text'} style={{ fontFamily: "'SF Pro Display'", fontStyle: "normal", fontWeight: 400, fontSize: "12px", lineHeight: "12px", letterSpacing: "0.012em", color: "#B0B5D0", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", marginLeft: '5px' }}><img src={locationIcon} style={{marginRight:'5px'}} /> {parking.address}</span>
                                <span className={'info_text'} style={{ fontFamily: "'SF Pro Display'", fontStyle: "normal", fontWeight: 400, fontSize: "12px", lineHeight: "12px", letterSpacing: "0.012em", color: "#B0B5D0", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden",marginLeft: '5px' }}><img src={carIcon} style={{marginRight:'5px'}} /> {parking.parkingCapacity} {intl.formatMessage({id: 'MAIN.PLACES'})}</span>
                            </div>
                            <div className={'additional_info'}>
                                <span>{parking.tariffRate} {getCurrencySymbol(parking.currency)}</span>
                                <span style={{justifyContent: 'flex-end'}}>{intl.formatMessage({id: 'HOURS'})}</span>
                                <span>{parseInt(String(calcDistance(userLatitude, userLongitude, parking.latitude, parking.longitude)))} {intl.formatMessage({id: 'meter'})}</span>
                                <span className={'more'}>{intl.formatMessage({id: 'MORE'})} {<ArrowRight />}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>

    )
}
