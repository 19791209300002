import axios from "axios";

import { abonementParkingList, cityList, countryList, parkingList} from "../endpoints";

const apiBaseUrl = process.env.REACT_APP_API_PARQOUR
const mobileDevUrl = 'https://api.parqour.app/mobile'

export const getParkingList = (lang:any) => {
return axios.post(mobileDevUrl + parkingList, null, {params: {languageCode: lang}}).then((resp) => {
        return resp.data
    })
}
export const getCountryList = () => {
return axios.get(mobileDevUrl + countryList).then((resp) => {
        return resp.data
    })
}

export const getCityList = (lang:string) => {
    return axios.post(mobileDevUrl + cityList, null, {params: {languageCode: lang}} ).then((resp) => {
        return resp
    })
}

export const getParkingListCity = async (cityId: string, lat: any, lng: any, lang: any) => {
    try {
        const resp = await axios.post(mobileDevUrl + parkingList + '/' + cityId + '?languageCode=' + lang, { lat, lng });
        return resp;
    } catch (err) {
        console.log(err);
    }
};


export const getAbonementParkingListCity = (lang:any) => {
    return axios.get(mobileDevUrl + abonementParkingList, {params: {languageCode: lang}}).then((resp) => {
        return resp
    }).catch((err) => {
        console.log(err)
    })
}