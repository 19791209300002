import {FC} from 'react'
import {BsFillCheckCircleFill} from 'react-icons/bs'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {addParkingNameAction, unauthCarAdd} from '../../../../../setup/redux/actions/actions'
import {useIntl} from 'react-intl'
import successIcon from '../../../../assets/success_frame.png'

import './PaymentResult.css'

const PaymentSuccessForm: FC = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  return (
      <>
          <div className='d-flex flex-column p-10 pt-20 h-100' >
              <img src={successIcon} />
              <div className='d-flex flex-column justify-content-between h-100'>
                  <div className='d-flex flex-column gap-3 align-items-center' style={{height: '40%'}}>
                      <span className='authorization_title'>{intl.formatMessage({id: 'SUCCESS.CONGRATS'})}</span>
                      <span className='authorization_description'>{intl.formatMessage({id: 'SUCCESS.CONGRATS.MSG'})}</span>
                  </div>

                  <div className='d-flex flex-column gap-3 mb-5 justify-content-end' style={{height: '60%'}}>
                      <button className='primary-button' onClick={() => navigate('/home-page')}>{intl.formatMessage({id: 'BTNS.CONTINUE'})}</button>
                      <button className='secondary-button' onClick={() => navigate('/payment/coupon')}>{intl.formatMessage({id: 'SUCCESS.COUPON'})}</button>
                  </div>
              </div>

          </div>
      </>

    // <div className='PaymentResult__div_parent mt-10'>
    //   <div className='PaymentResult__div_content shadow'>
    //     <span className='PaymentResult__icon PaymentResult__icon_success'>
    //       <BsFillCheckCircleFill />
    //     </span>
    //     <span className='PaymentResult__span_text text-center w-100 text-gray-700'>
    //       {intl.formatMessage({id: 'PAYMENT.SUCCESS.SUCCESS'})}
    //     </span>
    //     <button
    //       className='mt-8 btn btn-primary'
    //       onClick={() => {
    //         dispatch(unauthCarAdd(''))
    //         dispatch(addParkingNameAction(''))
    //         navigate('/home-page')
    //       }}
    //     >
    //       {intl.formatMessage({id: 'PAYMENT.SUCCESS.BTNS'})}
    //     </button>
    //   </div>
    // </div>
  )
}

export {PaymentSuccessForm}
