import {useState} from 'react'
import {BiMenu} from 'react-icons/bi'
import {GrClose} from 'react-icons/gr'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import {RootState} from '../../../../setup'
import {axiosLogout} from '../../../../setup/axios/AxiosAuth'
import {accessTokenAction, chooseParkingFormAction} from '../../../../setup/redux/actions/actions'

import arrowRight from '../../../../app/assets/ArrowRight.svg'

import './Aside.css'
import {Languages} from '../../../../app/modules/languages/Languages'
import {PrivacyModal} from '../../../../app/pages/privacy/PrivacyModal'
import {OfferModal} from '../../../../app/pages/offer/OfferModal'

const MyAside = () => {
  const [asideActive, setAsideActive] = useState(false)
  const [showModal, setShowModal] = useState(true);

    function toggleModal() {
        setShowModal(!showModal);
    }
  const intl = useIntl()

  const loggedIn = useSelector((state: RootState) => state.token)
  const phoneNumber = useSelector((state: RootState) => state.userInfoPhoneNumber)

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const showSide = () => setAsideActive(true)

  const closeSide = () => setAsideActive(false)

  return (
    <>
      {/* burger icon start */}
      <BiMenu onClick={() => showSide()} className='cursor-pointer Menu__font-size ' />
      {/* burger icon end */}

      {/* aside menu start */}
      <div className={asideActive ? `Toggle active` : `Toggle`}>
        {/* invisible div start */}
        <div
          onClick={() => {
            setAsideActive(false)
          }}
          style={{opacity: '0', width: '20%'}}
        ></div>
        {/* invisible div end */}

        <div
          className='d-flex flex-column h-100 bg-white border-start border-light-dark'
          style={{width: '80%', zIndex: '2'}}
        >
          {/* cross icon start */}
          <div className='w-100 d-flex p-5 align-items-center h-40px'>
            <span className='cursor-pointer' style={{fontSize: '16px'}} onClick={() => closeSide()}>
              <GrClose />
            </span>
          </div>
          {/* cross icon end */}
          {loggedIn && (
            <div className='w-100 d-flex justify-content-end pe-10'>
              <span style={{fontSize: '24px', fontWeight: '600'}}>{phoneNumber}</span>
            </div>
          )}

          <div className='h-100 w-100  d-flex flex-column align-items-center active'>
            {loggedIn && (
              <div className='d-flex flex-column w-100'>
                <div
                  onClick={() => {
                    closeSide()
                    dispatch(chooseParkingFormAction('fastPaymentMenu'))
                    navigate('/home-page')
                  }}
                  className='me-2 ps-20 pe-10 mt-10 w-100 cursor-pointer h-40px rounded d-flex align-items-center justify-content-between Aside__div_element'
                >
                  <span className='Aside__navigation_btns'>
                    {intl.formatMessage({id: 'ASIDE.HOME-PAGE'})}
                  </span>
                  <img src={arrowRight} />
                </div>
                <div
                  onClick={() => {
                    closeSide()
                    navigate('/profile')
                  }}
                  className='me-2 ps-20 pe-10 mt-10 w-100 cursor-pointer h-40px rounded d-flex align-items-center justify-content-between Aside__div_element'
                >
                  <span className='Aside__navigation_btns'>
                    {intl.formatMessage({id: 'ASIDE.PROFILE'})}
                  </span>
                  <img src={arrowRight} />
                </div>
                <div
                  onClick={() => {
                    closeSide()
                    navigate('/profile/parking-passes')
                  }}
                  className='me-2 ps-20 pe-10 mt-10 w-100 cursor-pointer h-40px rounded d-flex align-items-center justify-content-between Aside__div_element'
                >
                  <span className='Aside__navigation_btns'>
                    {intl.formatMessage({id: 'ASIDE.ABONEMENT'})}
                  </span>
                  <img src={arrowRight} />
                </div>
              </div>
            )}
            {/* LOGIN/LOGOUT BUTTON START*/}
            {loggedIn ? (
              <div
                onClick={() => {
                  closeSide()
                  axiosLogout()
                  dispatch(accessTokenAction(''))
                }}
                className='ms-2 me-2 ps-20 pe-10 mt-10 w-100 cursor-pointer h-40px rounded d-flex align-items-center justify-content-between Aside__div_element'
              >
                <span className='Aside__navigation_btns'>
                  {intl.formatMessage({id: 'AUTH.SIGNOUT'})}
                </span>
                <img src={arrowRight} />
              </div>
            ) : (
              <div
                onClick={() => {
                  navigate('/auth')
                }}
                className='ms-2 me-2 ps-20 pe-10 mt-10 w-100 cursor-pointer h-40px rounded d-flex align-items-center justify-content-between Aside__div_element'
              >
                <span className='Aside__navigation_btns'>
                  {intl.formatMessage({id: 'AUTH.LOGIN'})}
                </span>
                <img src={arrowRight} />{' '}
              </div>
            )}
            {/* LOGIN/LOGOUT BUTTON END*/}
          </div>

          {/* languages select start */}
          <div className='w-100 h-50px d-flex justify-content-center mb-5'>
            <Languages onLanguage={toggleModal} />
          </div>
          {/* languages select end */}
          <div className='d-flex flex-row w-100 justify-content-center mb-5'>
            <PrivacyModal headerContent={'PRIVACY.PRIVACY-ASIDE'} />
            <span className='ms-2 me-2'>{intl.formatMessage({id: 'PRIVACY.AND'})}</span>
            <OfferModal headerContent={'OFFER.OFFER-ASIDE'} />
          </div>
        </div>
      </div>

      {/* aside menu end */}
    </>
  )
}

export {MyAside}
