import {FC} from 'react'

import './SliderComponent.css'

type Props = {
  imgSrc: any
  textContent: any
  headerContent: any
}

const SliderComponent: FC<Props> = ({imgSrc, headerContent, textContent}) => {
  return (
    <div
      className=' w-100 d-flex flex-column justify-content-center'
    >
      <div className='d-flex flex-column w-100' style={{height: '90%'}}>
        <img alt='slider image' src={imgSrc} style={{maxWidth: '320px', maxHeight:'250px', objectFit:"contain", margin:"0 auto"}} />
        <div className='d-flex flex-column w-100 justify-content-start'>
          <span className='SliderComponent__header '>{headerContent}</span>
          <p className='SliderComponent__content w-100 d-flex justify-content-center mt-3'>
            {textContent}
          </p>
        </div>
      </div>
    </div>
  )
}

export {SliderComponent}
