import {useEffect, useRef, useState} from 'react'
import mapboxgl from 'mapbox-gl'
import {useDispatch, useSelector} from 'react-redux'
import MapboxLanguage from '@mapbox/mapbox-gl-language';

import {
  addParkingGuidAction, addParkingNameAction, citySelectAction, countryIdAction,
  currentLocationAction,
  setParkingInfo,
  showParkingInfoAction
} from '../../../setup/redux/actions/actions'
import Supercluster from 'supercluster'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import FreehandMode from 'mapbox-gl-draw-freehand-mode'
import * as turf from '@turf/turf'
import {pointsWithinPolygon} from '@turf/points-within-polygon'
import locationIcon from './assets/locationIcon.png'
import carIcon from './assets/carIcon.png'
import searchIcon from './assets/search_icon.svg'
import filterIcon from './assets/filterIcon.png'

import './Mapbox.css'
import { isMobile } from 'react-device-detect'
import {showParkingInfoReducer} from "../../../setup/redux/reducers/showParkingInfoReducer";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/all";
import {ArrowRight} from "@material-ui/icons";
import {useNavigate} from "react-router-dom";
import {Filter} from "./Filter";
import {useIntl} from "react-intl";
import {useLang} from "../../../_metronic/i18n/Metronici18n";
import {RootState} from "../../../setup";
import {getCityList, getParkingListCity} from "../../../setup/axios/AxiosParking";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../App";
import tags from "../../assets/eventTags";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

const supercluster = new Supercluster({
  radius: 75,
  maxZoom: 20,
})

const MapboxMain = ({
  mapParkingLocation,
  parkingData,
  setParking,
  setMapParkingLocation,
  selected,
  setSelected,
  cityName,
  center,
  setCenter,
  userPositionParkingList,
  setUserPositionMapInfo,
  abonement,
  setGeolocateControlCoordinates,
}) => {
  const map = useRef(null)
  const mapContainer = useRef(null)
  const [selectedParking, setSelectedParking] = useState([{name: 'Test', tariffRate: 100, address: "Test", parkingCapacity: 9999}])
  const [points, setPoints] = useState([])
  const [clusters, setClusters] = useState([])
  const [clusterMarkers, setClusterMarkers] = useState([])
  const intl = useIntl()
  const lang = useLang()
  const currentLocation = useSelector((state) => state.currentLocation)
  const userLatitude = currentLocation[0]
  const userLongitude = currentLocation[1]
  const [zoom, setZoom] = useState(4)
  const [parkingMarkers, setParkingMarkers] = useState([])
  const [geofencePolygon, setGeofencePolygon] = useState([])
  const [drawStarted, setDrawStarted] = useState(false)
  const [openFilterSettings, setOpenFilterSettings] = useState(true)
  const storeCityLat = useSelector((state) => state.cityLat)
  const storeCityLng = useSelector((state) => state.cityLng)
  const filters = useSelector((state) => state.filters.filters)
  const storeCityId = useSelector((state) => state.cityId)
  const currencySymbols = {
    "KZT": '₸',
    "KGS": 'с',
    "UZS": 'soʻm',
    "USD": '$',
    "RUB": '₽',
    "EUR": '€'
  };
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const navControl = new mapboxgl.NavigationControl({
    showCompass: false,
    showZoom: true,
  })

  const geolocateControl = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: true,
    showUserHeading: true,
  })

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: true,
      trash: true,
    },
    modes: Object.assign(MapboxDraw.modes, {
      draw_polygon: FreehandMode,
    }),
  })
  const languageControl = new MapboxLanguage({defaultLanguage: 'ru'});

  //initialize map
  useEffect(() => {
    if (map.current) return // initialize map only once
    let initCenter = []
    if (!storeCityLat && !storeCityLng) {
      initCenter = [center.lng, center.lat]
    }
    if (storeCityLat && storeCityLng) {
      initCenter = [storeCityLng, storeCityLat]
    }
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v11',
      center: initCenter,
      zoom: zoom,
      attributionControl: false,
      layers: [
        {
          id: 'background',
          type: 'background',
          paint: {
            'background-color': '#FFFFFF'
          }
        },
        {
          id: 'satellite',
          type: 'raster',
          source: 'raster-tiles',
          layout: {
            visibility: 'none'
          },
          minzoom: 0,
          maxzoom: 22
        }
      ]

    })

    map.current.addControl(languageControl);

    // map.current.setStyle(languageControl.setLanguage(map.current.getStyle(), 'de'));

    map.current.addControl(navControl, 'bottom-right')
    map.current.addControl(geolocateControl, 'bottom-right')
    //map.current.addControl(draw, 'bottom-left')
  })

  useEffect(() => {
    let isMounted = true;
    if (!map.current) return; // wait for map to initialize

    //on load event handler
    map.current.on("load", () => {
      if (isMounted) {
        geolocateControl.trigger();
      }
      handleLg();
    });

    if (isMounted && storeCityId !== null) {
      geolocateControl.trigger();
    }

    return () => {
      isMounted = false;
    };
  }, [storeCityId]);

  useEffect(() => {
    let isMounted = true;
    let isFirstLoad = true;

    if (!map.current) return; // wait for map to initialize

    const onLoad = () => {
      if (isMounted && isFirstLoad) {
        geolocateControl.trigger();
        isFirstLoad = false;
      }
      handleLg();
    };

    map.current.on("load", onLoad);

    return () => {
      isMounted = false;
      map.current.off("load", onLoad);
    };
  }, []);

  useEffect(() => {
    if (storeCityId !== null) {
      geolocateControl.trigger();
    }
  }, [storeCityId]);
  // useEffect(() => {
  //   if (!map.current) return // wait for map to initialize
  //
  //   //on load event handler
  //   map.current.on('load', () => {
  //     geolocateControl.trigger()
  //     handleLg()
  //   })
  //
  // }, [])
  const handleLg = () => {
    map.current.setStyle(languageControl.setLanguage(map.current.getStyle(), lang !== 'kk' ? lang : 'ru'));
  }

  //useEffect for creating parking markers array from parkingData
  useEffect(() => {
    const markerArray = []
    parkingData.map((item) => {
      const {latitude, longitude, name, parkingUid, tariffRate} = item
      if(filters.includes('all') || filters.length === 0){
        markerArray.push(item)
      }else{
        filters.map(filterParam => {
          if(item[filterParam]){
            markerArray.push(item)
          }
        })
      }

    })

    //test supercluster
    let pointsData = markerArray.map((item) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        parkingUid: item.parkingUid,
        parkingName: item.name,
        lng: item.longitude,
        lat: item.latitude,
        tariffRate: item.tariffRate,
        currency: item.currency
      },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)],
      },
    }))
    setPoints(pointsData)

  }, [parkingData])

  useEffect(() => {
    supercluster.load(points)
    setClusters(supercluster.getClusters([-180, -85, 180, 85], zoom))
  }, [points, zoom])

  const drawCreateArea = (e) => {
    setDrawStarted(true)
    setGeofencePolygon(e.features[0].geometry.coordinates[0])
  }

  const drawDeleteArea = () => {
    setDrawStarted(false)
    setGeofencePolygon([])
  }
  function calcDistance(lat1, lon1, lat2, lon2) {
    const R = 6371e3; // earth radius in meters
    const phi1 = lat1 * Math.PI / 180;
    const phi2 = lat2 * Math.PI / 180;
    const deltaPhi = (lat2 - lat1) * Math.PI / 180;
    const deltaLambda = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
        Math.cos(phi1) * Math.cos(phi2) *
        Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // distance in meters
    return d;
  }

  useEffect(() => {
    if (!map.current) return // wait for map to initialize
    map.current.on('zoomend', () => {
      setZoom(Math.round(map.current.getZoom()))
    })
    let filteredParkings = []

    geolocateControl.on('geolocate', (e) => {
      let lng = e.coords.longitude
      let lat = e.coords.latitude
      dispatch(currentLocationAction(lat, lng));
      setGeolocateControlCoordinates({lat, lng});

      let parkingList = []
      getParkingListCity(storeCityId, storeCityLat, storeCityLng, lang).then((resp) => {
        if (resp?.data) {
          logEvent(analytics, tags.parking_payment);
          parkingList = resp.data;
          filteredParkings = parkingList.sort((parkingA, parkingB) => {
            const distanceA = calcDistance(lat, lng, parkingA.latitude, parkingA.longitude);
            const distanceB = calcDistance(lat, lng, parkingB.latitude, parkingB.longitude);
            return distanceA - distanceB;
          });
          setSelectedParking(filteredParkings)
          setSelected(true)
        }
      })
    })

  }, [])

  useEffect(() => {
    if (!map.current) return
    map.current.on('draw.create', drawCreateArea)
    map.current.on('draw.delete', drawDeleteArea)
  }, [draw])

  useEffect(() => {
    clusterMarkers.forEach((marker) => marker.remove())
    setClusterMarkers([])

    parkingMarkers.forEach((marker) => marker.remove())
    setParkingMarkers([])

    clusters.map((cluster) => {
      const {cluster: isCluster, point_count, parkingUid, parkingName, tariffRate, currency} = cluster.properties
      const [longitude, latitude] = cluster.geometry.coordinates
      if (isCluster) {
        let el = document.createElement('div')
        el.classList.add('cluster-icon')
        el.id = `cluster-${longitude + latitude}`
        el.append(point_count)

        var newClusterMarker = new mapboxgl.Marker(el)
          .setLngLat([longitude, latitude])
          .addTo(map.current)

        newClusterMarker.getElement().addEventListener('click', () => {
          const zoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20)
          map.current.flyTo({
            center: [longitude, latitude],
            zoom,
            duration: 1000,
          })
        })
        setClusterMarkers((oldArray) => [...oldArray, newClusterMarker])
      } else {
        let wrapper = document.createElement('div')

        let el1 = document.createElement('div')
        el1.style.width = '33px'
        el1.style.height = '45px'
        el1.classList.add('icon-background')
        el1.id = parkingUid

        // create the main div element
        let el = document.createElement('div');
        el.style.width = '86px';
        el.style.height = '36px';
        el.style.borderRadius = '100px';
        el.style.backgroundColor = '#157FFF\n';
        el.style.color = 'white';
        el.style.display = 'flex';
        el.style.alignItems = 'center';
        el.style.justifyContent = 'center';
        el.id = parkingUid

// create the circle element
        let circle = document.createElement('div');
        circle.style.width = '28px';
        circle.style.height = '28px';
        circle.style.borderRadius = '50%';
        circle.style.backgroundColor = 'gray';
        circle.style.display = 'flex';
        circle.style.alignItems = 'center';
        circle.style.justifyContent = 'center';
        el.appendChild(circle);

// create the image element inside the circle
        let image = document.createElement('img');
        let parkingUrl = parkingData.filter(item => item.parkingUid == parkingUid)[0].iconUrl
        image.src = parkingUrl;
        image.style.width = '28px';
        image.style.height = '28px';
        image.style.borderRadius = '32px'
        image.id = 'parkingAvatar'
        circle.appendChild(image);

// create the span tag for the price text
        let price = document.createElement('span');
        price.innerText = `${tariffRate} ${currency}`;
        price.style.marginLeft = '5px';
        el.appendChild(price);

// create the map pin icon
        let pin = document.createElement('div');
        pin.style.position = 'absolute';
        pin.style.bottom = '-14px';
        pin.style.width = '2px';
        pin.style.height = '16px';
        pin.style.backgroundColor = '#157FFF';
        // pin.style.borderLeft = '2px solid transparent';
        // pin.style.borderRight = '2px solid transparent';
        // pin.style.borderTop = '2px solid #157FFF\n';
        el.appendChild(pin);
        el.classList.add('d-none')
        wrapper.appendChild(el)
        wrapper.appendChild(el1)
        var newMarker = new mapboxgl.Marker(wrapper)
          .setLngLat([longitude, latitude])
          .addTo(map.current)

        newMarker.getElement().addEventListener('click', () => {
          setParking(parkingName)
          setSelected(parkingUid)
          dispatch(addParkingGuidAction(parkingUid))
          map.current.flyTo({
            center: [longitude, latitude],
            zoom: 14,
            duration: 1500,
          })
        })
        setParkingMarkers((oldArray) => [...oldArray, newMarker])
      }
    })
  }, [clusters])

  //on parking select center change
  useEffect(() => {
    if (Object.keys(mapParkingLocation).length > 0) {
      setCenter(mapParkingLocation)
    }
  }, [mapParkingLocation])

  //change map center on city change and marker click useEffect
  useEffect(() => {
    map.current.flyTo({
      center: [center.lng, center.lat],
      zoom: 14,
      duration: 2000,
    })
  }, [center])

  //markers reRender after parking select useEffect
  useEffect(() => {
    //creating new array from parkingMarkers state
    const currentParkings = parkingMarkers
    //clearing parkingMarkers state
    setParkingMarkers([])

    //mapping new array to find selected parking
    currentParkings.map((marker) => {
      let currentMarker = marker._element.firstElementChild
      let prevMarker = marker._element.childNodes[1]
      //changing styles of marker if parking is selected
      if (selected == currentMarker.id) {
        currentMarker.classList.remove('d-none')
        currentMarker.classList.add('show')
        prevMarker.classList.add('d-none')
        marker._element.style.marginBottom = '10px';
        dispatch(showParkingInfoAction(true, currentMarker.id))
        const element = document.querySelector('.mapboxgl-ctrl-bottom-right');
        element.style.display = 'none';
        setSelectedParking(parkingData.filter(item => item.parkingUid == currentMarker.id))
        //opening popup if parking was selected from list
      } else {
        //setting default settings to marker if parking is not selected
        // marker._element.classList.remove('selected-icon-background')
        // marker._element.classList.add('icon-background')

        //closing popup if another parking is selected
        // marker._popup.remove()
      }
      // marker.addTo(map.current)
    })
    setParkingMarkers(currentParkings)
  }, [selected])


  return (
    <div style={{width: '100%', height: '100%'}}>
              {
                isMobile && (

             <div style={{
               display: 'flex',
               justifyContent: 'space-between',
               background: 'transparent',
               position: 'fixed',
               top: '30px',
               width:'100%',
               padding: '10px',
               zIndex: '100',
               gap: '10px'
             }}>
               <div className="search-container" >
                 <input type="text" placeholder={intl.formatMessage({id: 'SEARCH'})} className="search-input" onClick={() => navigate('/search')} style={{backgroundImage: `url(${searchIcon})`}} />
               </div>
               <div className='filter'>
                 <img src={filterIcon} onClick={() => navigate('/parking-filter', {state: {isFromSearch: false}})} />
               </div>
             </div>
             )
            }


             <div ref={mapContainer} style={{width: '100%', height: '100%'}} />
             {
                 selected &&
                 <div className='modal-info'>
                   <div className={'modal-info-content'}>
                     <div className={'content-text'}>
                       <div className={'parking-info'}>
                         <span className={'title_parking'}>{selectedParking[0].name}</span>
                         {/*<span className={'name'}>{selectedParking[0].name}</span>*/}
                         <span className={'info'}><img src={locationIcon} style={{marginRight:'5px'}} />{selectedParking[0].address}</span>
                         <span className={'info'}><img src={carIcon} style={{marginRight:'5px'}} />{selectedParking[0].parkingCapacity} {intl.formatMessage({id: 'MAIN.PLACES'})}</span>

                       </div>
                       <div className={'additional-info'}>
                         <div style={{display: 'flex', flexDirection: 'column'}}>
                           <span className={'price'} style={{textAlign: 'end'}}>{selectedParking[0].tariffRate} {selectedParking[0].currency}</span>
                           <span className={'price'}>{intl.formatMessage({id: 'HOURS'})}</span>
                         </div>

                         <span className={'more'} onClick={() => {
                           dispatch(setParkingInfo(selectedParking[0]))
                           navigate('/parking-info')
                         }}>{intl.formatMessage({id: 'MORE'})} {<ArrowRight />}</span>
                       </div>
                     </div>
                     <button className={'pay-button'} onClick={() => {
                       dispatch(setParkingInfo(selectedParking[0]))
                       dispatch(addParkingGuidAction(selectedParking[0].parkingUid))
                        addParkingNameAction(selectedParking[0].name)
                        navigate('/parking-payment')
                     }
                     }>{intl.formatMessage({id: 'BTNS.PAY'})}</button>
                   </div>
                 </div>
             }

    </div>
  )
}

export {MapboxMain}
