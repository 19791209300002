import { logEvent } from 'firebase/analytics'
import {FC, useEffect, useState} from 'react'
import {BsExclamationCircle, BsFillExclamationCircleFill, BsFillExclamationTriangleFill} from 'react-icons/bs'
import {useIntl} from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup'
import { showPlateNumberHint } from '../../../../../../setup/redux/actions/actions'
import { analytics } from '../../../../../App'
import tags from '../../../../../assets/eventTags'
import PlateNumberModule from '../../../../modal/plate-number/PlateNumberModule'


import './CarInput.css'

type Props = {
  plateNumber: string
  setPlateNumber: any
  placeholder?: any
}

const AddCarInput: FC<Props> = ({plateNumber, setPlateNumber, placeholder}) => {
  const [popup, setPopup] = useState(false)
  const [click, setClick] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl()

  useEffect(() => {
    if (plateNumber.length > 0 && plateNumber.length < 4) {
      setPopup(true)
    } else {
      setPopup(false)
    }
  }, [plateNumber])
  const handleClick = () => {
    if(!click){
      setClick(true);
      dispatch(showPlateNumberHint(true));
    }
    
  }
  return (
    <div className='d-flex flex-row w-100 h-100 align-items-center position-relative rounded ps-3 pe-3'>
      <input
        className='w-100 h-100 fs-1 ps-2 CarInput__border'
        placeholder={placeholder}
        onClick={() => handleClick()}
        onChange={(e) => {
          if (e.target.value.match(/^$|^[0-9a-zA-Zа-яА-Я\s]+$/)) {
            const validatedNumber = e.target.value.replace(/\s+/g, '')
            // if(validatedNumber.length >= 8){
            //   logEvent(analytics, tags.enter_number_plate);
            //   console.log("PLAYE NUMBER: ", validatedNumber)
            // }
            
            setPlateNumber(validatedNumber)
          }
        }}
        value={plateNumber.toUpperCase()}
      />
      {!popup && (
        <div
          className='position-absolute d-flex flex-column justify-content-center align-items-center'
          style={{right: '21px', cursor: 'pointer'}}
          onClick={() => {
            console.log('CLicked DISPATCH')
            dispatch(showPlateNumberHint(true))
          }}
        >
          
            <BsExclamationCircle size={22} />
          
          
        </div>
      )}
      
      {popup && (
        <div
          className='position-absolute d-flex flex-column justify-content-center'
          style={{right: '10px'}}
        >
          <span className='text-danger fs-4'>
            <BsFillExclamationTriangleFill />
          </span>
          <div className='position-absolute d-flex justify-content-center CarInput__tooltip_parent_div'>
            <div className='tooltip-arrow'></div>
            <div className='bg-dark text-white p-2 shadow CarInput__tooltip d-flex justify-content-center'>
              {intl.formatMessage({id: 'PAYMENT.CHOOSE-PARKING.ADD-CAR-INPUT.TOOLTIP'})}
            </div>
          </div>
        </div>
      )}
      
    </div>
  )
}

export {AddCarInput}
