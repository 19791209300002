const firstLoad = localStorage.getItem('onboarding')

const onboardingReducer = (
    state:any = firstLoad ? firstLoad : '',
    action:any
) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_ONBOARDING_SHOW":
            return payload;
        default:
            return state;
    }
};

export {onboardingReducer}