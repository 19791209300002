export const showPlateNumberHint = (show: boolean) => {
    return {
        type: "SHOW_PLATE_NUMBER_HINT",
        payload: show
    }
}
export const unauthCarAdd = (carNumber: string) => {
    if (carNumber) {
        localStorage.setItem("carNumber", carNumber);
    } else {
        localStorage.removeItem("carNumber");
    }
    return {
        type: "SET_UNAUTH_CAR",
        payload: carNumber,
    };
};

export const addParkingNameAction = (parking: string) => {
    if (parking) {
        localStorage.setItem("parkingName", parking);
    } else {
        localStorage.removeItem("parkingName");
    }
    return {
        type: "SET_PARKING_NAME",
        payload: parking
    }
}

export const addParkingGuidAction = (parkingGuid: string) => {
    if (parkingGuid) {
        localStorage.setItem("parkingGuid", parkingGuid)
    } else {
        localStorage.removeItem("parkingGuid")
    }
    return {
        type: "SET_PARKING_GUID",
        payload: parkingGuid
    }
}

export const cityIdAction = (cityId: string) => {
    if (cityId) {
        localStorage.setItem("cityId", cityId)
    } else {
        localStorage.removeItem("cityId")
    }
    return {
        type: "SET_CITY_ID",
        payload: cityId
    }
}


export const bearerToken = (token:string) => {
    if (token) {
        localStorage.setItem("billingToken", token);
    } else {
        localStorage.removeItem("billingToken");
    }
    return {
        type: "SET_BILLING_BEARER_TOKEN",
        payload: token,
    };
};

export const accessTokenAction = (token:string) => {
    if (token) {
        localStorage.setItem("accessToken", token)
    } else {
        localStorage.removeItem("accessToken")
    }
    return {
        type: "SET_ACCESS_TOKEN",
        payload: token
    }
}

export const citySelectAction = (city: string) => {
    if (city) {
        localStorage.setItem('city', city)
    } else {
        localStorage.removeItem('city')
    }
    return {
        type: "SET_LOCATION_CITY",
        payload: city
    }
}

export const billingFormAction = (form: string) => {
    return {
        type: "SET_BILLING_FORM",
        payload: form
    }
}
const SET_FILTERS = 'SET_FILTERS';

export const setFilters = (filters: any) => {
    return {
        type: SET_FILTERS,
        filters
    }
};
export const userLocationAction = (location: any) => {
    return {
        type: "SET_USER_LOCATION",
        payload: location
    }
}

export const serverErrorAction = (error: any) => {
    return {
        type: 'SET_SERVER_ERROR',
        payload: error
    }
}
export const showParkingInfoAction = (show: boolean, parkingUid: number) => {
    console.log("SHOW PARKING INFO MODAL")
    return {
        type: 'SHOW_PARKING_INFO_MODAL',
        payload: {
            show: show,
            parkingUid: parkingUid
        }
    }
}
export const setParkingInfo = (parking: any) => {
    return {
        type: 'SET_PARKING_INFO',
        payload: parking
    }
}
export const setCar = (car: any) => {
    return {
        type: 'SET_CAR',
        payload: car
    }
}
export const setDisplayCar = (selectCar: boolean) => {
    return {
        type: 'SET_DISPLAY_CARS',
        payload: selectCar
    }
}
// export const onboardingAction = (firstLoad: any) => {
//     if (firstLoad) {
//         localStorage.setItem('onboarding', firstLoad)
//     } else {
//         localStorage.removeItem('onboarding')
//     }
//     return {
//         type: 'SET_ONBOARDING_SHOW',
//         payload: firstLoad
//     }
// }
export const onboardingAction = (firstLoad: any) => {
    console.log("AAAA", firstLoad)
    if (firstLoad) {
        localStorage.setItem('onboarding', firstLoad.onboard)
    } else {
        localStorage.removeItem('onboarding')
    }
    return {
        type: 'SET_ONBOARDING_SHOW',
        payload: firstLoad
    }
}

export const chooseParkingFormAction = (form: any) => {
    return {
        type: "SET_CHOOSE_PARKING_FORM",
        payload: form
    }
}

export const serviceIdAction = (serviceId: string) => {
    if (serviceId) {
        localStorage.setItem("serviceId", serviceId)
    } else {
        localStorage.removeItem("serviceId")
    }
    return {
        type: "SET_SERVICE_ID",
        payload: serviceId
    }
}

export const abonStartDateAction = (abonStartDate: string) => {
    if (abonStartDate) {
        localStorage.setItem("abonStartDate", abonStartDate)
    } else {
        localStorage.removeItem("abonStartDate")
    }
    return {
        type: "SET_ABON_START_DATE",
        payload: abonStartDate
    }
}

export const abonEndDateAction = (abonEndDate: string) => {
    if (abonEndDate) {
        localStorage.setItem("abonEndDate", abonEndDate)
    } else {
        localStorage.removeItem("abonEndDate")
    }
    return {
        type: "SET_ABON_END_DATE",
        payload: abonEndDate
    }
}

export const abonStartTimeAction = (abonStartTime: string) => {
    if (abonStartTime) {
        localStorage.setItem("abonStartTime", abonStartTime)
    } else {
        localStorage.removeItem("abonStartTime")
    }
    return {
        type: "SET_ABON_START_TIME",
        payload: abonStartTime
    }
}

export const userInfoPhoneNumberAction = (userInfoPhoneNumber: string) => {
    if (userInfoPhoneNumber) {
        localStorage.setItem('userInfoPhoneNumber', userInfoPhoneNumber)
    } else {
        localStorage.removeItem('userInfoPhoneNumber')
    }

    return {
        type: "SET_INFO_USER_PHONE_NUMBER",
        payload: userInfoPhoneNumber
    }
}

export const cityLatAction = (cityLat: any) => {
    if (cityLat) {
        localStorage.setItem('cityLat', cityLat)
    } else {
        localStorage.removeItem('cityLat')
    }

    return {
        type: 'SET_CITY_LAT',
        payload: cityLat
    }
}

export const cityLngAction = (cityLng: any) => {
    if (cityLng) {
        localStorage.setItem('cityLng', cityLng)
    } else {
        localStorage.removeItem('cityLng')
    }

    return {
        type: 'SET_CITY_LNG',
        payload: cityLng
    }
}

export const paymentAmountAction = (paymentAmount: any) => {
    if (paymentAmount) {
        localStorage.setItem('paymentAmount', paymentAmount)
    } else {
        localStorage.removeItem('paymentAmount')
    }

    return {
        type: 'SET_PAYMENT_AMOUNT',
        payload: paymentAmount
    }
}
export const currentLocationAction = (lat: any, lng: any) => {
    return {
        type: 'SET_CURRENT_LOCATION',
        payload: [lat, lng]
    }
}
export const countryIdAction = (countryId: any) => {
    return {
        type: 'SET_COUNTRY_ID',
        payload: countryId
    }
}
export const currentPaymentMethod = (paymentInfo: any) => {
    return {
        type: 'SET_PAYMENT_METHOD',
        payload: paymentInfo
    }
}
export const geolocateControlTrigger = (geolocate: any) => {
    return {
        type: 'SET_TRIGGER_GEOLOCATION',
        payload: geolocate
    }
}