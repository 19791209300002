
const showParkingInfoReducer = (
    state = {
        show: false,
        parkingUid: 1
    },
    action: any
) => {
    const {type, payload} = action
    switch (type) {
        case 'SHOW_PARKING_INFO_MODAL':
            return payload
        default:
            return state
    }
}

export {showParkingInfoReducer}