import React, {useState} from "react";
import arrowLeft from "../../assets/ArrowLeft.svg";
import './Main.css'
import {CurrentParking} from "./components/current-parking/CurrentParking";
import {History} from "./components/history/History";
import {useIntl} from "react-intl";


export const Main: React.FC = () => {
    const [selectedOption, setSelectedOption] = useState('2'); // Set initial value to '1'

    const intl = useIntl();
    const options = {
        1: '',
        2: intl.formatMessage({id: 'HISTORY.TITLE'}),
        3: ''
    };

    const handleOptionSelect = (option: any) => {
        setSelectedOption(option);
    };
    return (
        <>
            <div style={{padding: '20px', height: '100%'}}>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex  align-items-center' style={{padding:'20px 0'}}>
                        <span className='back-header-title'>{intl.formatMessage({id: 'HISTORY.CURRENT'})}</span>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <div className="rectangle">
                        <div className="options">
                            {Object.entries(options).map(([key, option]) => (
                                <button
                                    disabled={key !== '2' ? true : false}
                                    key={key}
                                    className={`option ${selectedOption === key ? 'selected' : ''}`}
                                    onClick={() => handleOptionSelect(key)}
                                >
                                    {option}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                    <History />


            </div>
        </>
    );
};
