/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import { setFilters } from '../../../setup/redux/actions/actions';
import arrowLeft from '../../assets/ArrowLeft.svg'
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useLang} from "../../../_metronic/i18n/Metronici18n";
import './Mapbox.css'
import {RootState} from "../../../setup";
export const Filter: FC = () => {
    const intl = useIntl()
    const lang = useLang()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isFromSearchBar: any = location.state;

    const storeFilterParameters = useSelector((state: RootState) => state.filters)
    const [filterParameters, setFilterParameters] = useState<string[]>(storeFilterParameters.filters)
    console.log("Filter parameters from store: ", storeFilterParameters)
    useEffect(() => {
        if(filterParameters.length > 0 || storeFilterParameters.filters.length > 0){
            dispatch(setFilters(filterParameters))
        }
        console.log("Filters: ", filterParameters)
        return () => {

        };
    }, [filterParameters]);

    const params = ['abonementsEnabled', "all"]


    return (
        <div style={{padding: '20px', display:'flex', flexDirection: 'column', justifyContent:'space-between', height:'100%'}}>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex  align-items-center' style={{padding:'20px 0'}}>
                    <img src={arrowLeft} style={{width:'15px', height:'21px', marginRight:'10px'}} onClick={() => navigate('/home-page')} />
                    <span className='back-header-title'>{intl.formatMessage({id: 'FILTERS.TITLE'})}</span>
                </div>
            </div>
            <div style={{height:'80%'}}>
                <label className={'find_title'}>{intl.formatMessage({id: 'PARKING.PAYMENT_TYPE'})} </label>
                <div style={{marginTop: '20px'}}>
                    {
                        params.map(param => (
                            <label className="container">{intl.formatMessage({id: param})}
                                <input type="checkbox"  checked={storeFilterParameters.filters.includes(param)}
                                       value={param} onChange={(e) =>  {
                                    if (filterParameters.includes(param)) {
                                        setFilterParameters((prevState) =>
                                            prevState.filter((value) => value !== param)
                                        );
                                    } else {
                                        setFilterParameters((prevState) => [...prevState, param]);
                                    }
                                }
                                    } />
                                <span className="checkmark"></span>
                            </label>
                        ))
                    }
                </div>

            </div>
            <button
                className={'primary-button'}
                onClick={() => isFromSearchBar.isFromSearch ? navigate('/search') : navigate('/home-page')}
            >
                {intl.formatMessage({id: 'CHOOSE'})}
            </button>


        </div>

    )
}
