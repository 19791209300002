import { initializeApp, getApps, getApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'
import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import axios from 'axios'
import { useLang, setLanguage } from '../_metronic/i18n/Metronici18n'
import { useSelector } from 'react-redux'
import { RootState } from '../setup'
import { getAnalytics} from "firebase/analytics";
import { isIOS, isMobile } from 'react-device-detect'
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import '../app/assets/fonts.css';

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

const firebaseConfig = {
   apiKey: "AIzaSyCCw9f87sjkllw79Zb3NFoVK7zuV1YX5AM",
   authDomain: "parqour-d8ad8.firebaseapp.com",
   projectId: "parqour-d8ad8",
   storageBucket: "parqour-d8ad8.appspot.com",
   messagingSenderId: "215493594262",
   appId: "1:215493594262:web:571b0fce3ab5a97cd246b7",
   measurementId: "G-3JQCJ34B83",
   messageSenderId: "215493594262"
  }
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const App = () => {
  let access = localStorage.getItem("accessToken");
  let phoneNumber = localStorage.getItem("userInfoPhoneNumber");
  const currentLocation = useSelector((state: RootState) => state.currentLocation);
  let lang = useLang();
  const fcmVapidKey = process.env.REACT_APP_FCM_VAPID_KEY
  const [fcmPermission, setFcmPermission] = useState(false)
  
  // const firebaseConfig = {
  //   apiKey: "AIzaSyCCw9f87sjkllw79Zb3NFoVK7zuV1YX5AM",
  //   authDomain: "parqour-d8ad8.firebaseapp.com",
  //   projectId: "parqour-d8ad8",
  //   storageBucket: "parqour-d8ad8.appspot.com",
  //   messagingSenderId: "215493594262",
  //   appId: "1:215493594262:web:a7772c59182a8832d246b7",
  //   measurementId: "G-BXF4Z5NTFT"
  // };
  // const app = initializeApp(firebaseConfig)
  // const analytics = getAnalytics(app)

  function postRequest(config: any){
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  } 
  

  
  
  const show = () => {
   console.log('Requesting permission...');
   !isMobile &&  
   Notification.requestPermission().then(async (permission) => {
     if (permission === 'granted') {
       console.log('Notification permission granted.');
       setFcmPermission(true)
     }
   });
  }
  const tryGetToken = async () => {
   console.log("this")
   const messaging = await getMessaging();
   const token = await getToken(messaging, {
     vapidKey: fcmVapidKey
   });
   if (token) {
    let data = access ? JSON.stringify({
    "key": token,
    "user": {
      "phoneNumber": phoneNumber
    },
    "appInfo": {
      "locale": lang,
      "platform": "web",
      "lat": currentLocation[0],
      "lng": currentLocation[1]
    }
    }) : JSON.stringify({
      "key": token,
      "appInfo": {
        "locale": lang, 
        "platform": "web",
        "lat": currentLocation[0],
        "lng": currentLocation[1]
      }
    });
    var config = {
      method: 'post',
      url: 'https://api.dev.parqour.app/fcm/api/tokens',
      headers: { 
        'Authorization': 'Basic dmFkaW0xOnRlc3Q=', 
        'Content-Type': 'application/json'
      },
      data : data
    };
    //  console.log("currentToken : " + token)
    //  console.log("Data: ",data);
    //  console.log(config)
     postRequest(config)
     
   } else {
     console.log('No registration token available. Request permission to generate one.');
   }
  }
  
  useEffect(() => {
    //  const app = initializeApp(firebaseConfig)
  //  const analytics = getAnalytics(app)
  if(!isIOS){
    show()
  }
   
  }, [])

  useEffect(() => {
   if (fcmPermission) { 
     tryGetToken()
   }
  }, [fcmPermission])
  console.log("Access :",localStorage.getItem("accessToken"))
    const queryClient = new QueryClient();
  return (
      <QueryClientProvider client={queryClient}>
          <Suspense fallback={<LayoutSplashScreen />}>
              <I18nProvider>
                  <LayoutProvider>
                      <Outlet />
                      <MasterInit />
                  </LayoutProvider>
              </I18nProvider>
          </Suspense>
      </QueryClientProvider>


  )
}

export {App, analytics}
