/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Outlet, Route, Routes, useLocation, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {LoginRegistration} from './components/login-registration/LoginRegistration'
import {Languages} from '../../modules/languages/Languages'
import {LanguagePickerModal} from '../../modules/modal/language-picker/LanguagePickerModal'
import './AuthPage.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import ReactDOM from "react-dom";

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
    const [showModal, setShowModal] = useState(false);

    function toggleModal() {
        setShowModal(!showModal);
    }

  return (
    <div className='AuthPage__parent__div d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-column flex-column-fluid '>

        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '30px'
        }}>
          <Languages onLanguage={toggleModal} />
        </div>
          {showModal &&
              ReactDOM.createPortal(
                  <LanguagePickerModal onToggle={toggleModal} />,
                  document.body
              )}
        <div className='w-100 h-100 p-10 '>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

type Props = {
  form?: string
}

const AuthPage: FC<Props> = ({form}) => {
    const onboardingShow = useSelector((state: RootState) => state.onboarding)
    console.log(onboardingShow)
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route index element={<LoginRegistration form={form} city={onboardingShow.city} parkingUid={onboardingShow.parkingUid} />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
