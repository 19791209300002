/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {isMobile} from "react-device-detect";

import {axiosAuthCode, axiosPhoneAuth} from '../../../../../setup/axios/AxiosAuth'
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  accessTokenAction,
  billingFormAction,
  chooseParkingFormAction, onboardingAction,
} from '../../../../../setup/redux/actions/actions'
import {useIntl} from 'react-intl'
import {PhoneInputComponent} from '../phone-input/PhoneInputComponent'
import {LoaderForm} from '../../../../modules/forms/billing-form/components/BillingLoaderForm'
import { analytics } from '../../../../App';
import './LoginRegistration.css'
import '../../../../../index.css'
import {RootState} from "../../../../../setup";

type Props = {
  form?: string,
  qr?: boolean,
  city?: any,
  parkingUid?: any
}

export const LoginRegistration: FC<Props> = ({form,qr, city, parkingUid}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  console.log("QR code: ", city, parkingUid)
  const intl = useIntl()

  const [phone, setPhone] = useState('')
  const [phoneLength, setPhoneLength] = useState(11)
  const [errorMessage, setErrorMessage] = useState('empty')

  const [timer, setTimer] = useState(60)
  const [timerStart, setTimerStart] = useState(false)
  const [sendBtn, setSendBtn] = useState(false)
  const [showCodeInput, setShowCodeInput] = useState(true)
  const [codeSend, setCodeSend] = useState(false)
  const [codeReSend, setCodeReSend] = useState(false)
  const [code, setCode] = useState('')
  const [codeStatus, setCodeStatus] = useState('code')


  useEffect(() => {
    let newsInterval = setInterval(() => {
      if (timerStart) {
        if (timer === 0) {
          setTimerStart(false)
          setCodeReSend(true)
        } else {
          setTimer(timer - 1)
        }
      }
    }, 1000)
    return () => {
      clearInterval(newsInterval)
    }
  })

  const authFunc = () => {
    axiosPhoneAuth(phone).then((resp) => {

      setTimer(60)
      if (resp.code === 400) {
        if (resp.message === "Can't send sms, number is incorrect!") {
          setErrorMessage('invalid number')
        }
        setTimerStart(false)

      }
      if (resp.code === 200) {
        if (resp.message === 'Code sent') {
          setErrorMessage('empty')
          setCodeSend(true)
        }else {
          setTimeout(() => {
            setCode(resp.message)
          }, 2000)
        }
      }
    })
  }
  const onboardingShow = useSelector((state: RootState) => state.onboarding)

  const verifyFunc = () => {
    setCodeStatus('loader')

    axiosAuthCode(phone, code).then((resp) => {
      setCodeStatus('code')
      if (resp.code === 200) {
        dispatch(accessTokenAction(resp.message.access))
        localStorage.setItem('refreshToken', resp.message.refresh)
        console.log("FOOOORM:", form)
        if(city && parkingUid){
            navigate(`/qr/${city}/${parkingUid}`, {replace: true})
        }
         if (form === 'authPage') {
           !onboardingShow ? navigate('/boarding') : navigate('/home-page')
           console.log("Auth function")
          }
          if (form === 'billingPage') {
            dispatch(billingFormAction('payed'))
          }
          if (form === 'abonementPage') {
            dispatch(chooseParkingFormAction('abonementNumberParkingMenu'))
          }
          else{
            !onboardingShow ? navigate('/boarding') : navigate('/home-page')
          }
      }
      if (resp.code === 400) {
        setCode('')
        if (resp.message === 'Code expired!') {
          setErrorMessage('Code expired')
        }
        if (resp.message === "Code don't match!") {
          setErrorMessage('Invalid code')
        }
      }
    })
  }
  console.log("PHONE: ", phone)
  useEffect(() => {
    if (code.length === 4) {
      setCodeStatus('loader')
      verifyFunc()
    }
  }, [code])


  return (
    <div className='LoginRegistration__parent__div'>
      <div className={`LoginRegistration__content__div position-relative ${!isMobile && 'p-10'}`}>
        <div className='w-100 d-flex flex-column' style={{height: '40%', justifyContent:'center'}}>
          <div className='w-100 d-flex flex-column' style={{height: '0%'}}>
            <div className='mb-5 w-100 d-flex flex-column' style={{margin: !isMobile && codeSend ? '60px 10px' : ''}}>
              <div className='d-flex flex-column align-items-center'>
                <label className='form-label fs-3 fw-bolder text-dark d-flex justify-content-center authorization_title'>
                  {!showCodeInput ? intl.formatMessage({id: 'AUTH.LOGIN.CHECK.TITLE'})  :  intl.formatMessage({id: 'LOGIN.USERNAME'})}
                </label>
                <label className='authorization_description mb-3' style={{maxWidth: !showCodeInput ? '261px' : '215px'}}>
                  {!showCodeInput ? intl.formatMessage({id: 'AUTH.LOGIN.CHECK.DESCRIPTION'}) : intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}

                </label>
              </div>

              {showCodeInput && <PhoneInputComponent phone={phone} setPhone={setPhone} sendBtn={sendBtn} setSendBtn={setSendBtn} setCodeSend={setCodeSend} codeSend={codeSend} setPhoneLength={setPhoneLength} />}
              <div className="d-flex justify-content-center mt-20" >
                {
                    (timer > 0 && timer !== 60) &&

                        <span className='text-primary fs-4' style={{marginTop: !isMobile ? '' : errorMessage === 'empty' ? '6.5rem' : '10rem'}}
>
                          {intl.formatMessage({id: 'AUTH.LOGIN.RESEND.SMS'}, {second: timer})}
                        </span>
                }
                {
                  codeReSend &&
                    <span
                        className={
                          phone.length >= phoneLength
                              ? 'cursor-pointer text-primary fs-4'
                              : 'text-gray-500 fs-4'
                        } 
                        style={{marginTop: !isMobile ? '' : errorMessage === 'empty' ? '6.5rem' : '10rem'}}
                        onClick={() => {
                          if (phone.length >= phoneLength) {
                            setTimer(60)
                            setTimerStart(true)
                            setSendBtn(true)
                            setCodeReSend(false)
                            authFunc()
                          }
                        }}
                          >
                      {intl.formatMessage({id: 'AUTH.LOGIN.SMS'})}
                    </span>
                }
              </div>

            </div>

            {errorMessage === 'invalid number' && (
              <span className='text-danger fs-4' style={{textAlign: 'center'}}>
                {intl.formatMessage({id: 'AUTH.LOGIN.INVALID.PHONE'})}
              </span>
            )}

            {/*{sendBtn ? (*/}
            {/*  <span*/}
            {/*    className={*/}
            {/*      phone.length >= 11*/}
            {/*        ? 'cursor-pointer text-primary fs-4 mt-2'*/}
            {/*        : 'text-gray-500 fs-4 mt-2'*/}
            {/*    }*/}
            {/*    onClick={() => {*/}
            {/*      if (phone.length >= 11) {*/}
            {/*        setTimer(60)*/}
            {/*        setTimerStart(true)*/}
            {/*        setSendBtn(false)*/}
            {/*        authFunc()*/}
            {/*      }*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {intl.formatMessage({id: 'AUTH.LOGIN.SMS'})}*/}
            {/*  </span>*/}
            {/*) : (*/}
            {/*  <span className='text-primary fs-4 mt-2'>*/}
            {/*    {intl.formatMessage({id: 'AUTH.LOGIN.RESEND.SMS'})} {timer}*/}
            {/*  </span>*/}
            {/*)}*/}
          </div>

          {/* code input start */}
          {codeSend && (
            <div className='w-100 d-flex justify-content-center' style={{marginTop: '10rem'}}>
              {/*codeStatus === 'code' && <SmsCodeInput otp={otp} setOtp={setOtp} />*/}
              {codeStatus === 'code' && (
                <div className='w-100 d-flex flex-column align-items-center'>
                  <input
                    maxLength={4}
                    style={{backgroundColor: 'inherit'}}
                    type='tel'
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/

                      // if value is not blank, then test the regex

                      if (e.target.value === '' || re.test(e.target.value)) {
                        setCode(e.target.value)
                      }
                    }}
                    value={code}
                    className={'InputStyle'}
                  />
                  <div className='d-flex flex-row w-250px h-5px justify-content-between'>
                    <span className={errorMessage === 'Invalid code' ? 'error digit_line' : code.length >= 1 ? 'primary digit_line' : 'secondary digit_line'} ></span>
                    <span className={errorMessage === 'Invalid code' ? 'error digit_line' : code.length >= 2 ? 'primary digit_line' : 'secondary digit_line'} ></span>
                    <span className={errorMessage === 'Invalid code' ? 'error digit_line' : code.length >= 3 ? 'primary digit_line' : 'secondary digit_line'} ></span>
                    <span className={errorMessage === 'Invalid code' ? 'error digit_line' : code.length >= 4 ? 'primary digit_line' : 'secondary digit_line'} ></span>
                  </div>
                </div>
              )}
              {codeStatus === 'loader' && isMobile && (
                <div className='mt-5'>
                  <LoaderForm />
                </div>
              )}
            </div>
          )}
          {/* code input end */}
          {errorMessage === 'Invalid code' && (
            <span className='text-danger fs-4 mt-6' style={{textAlign: 'center'}}>
              {intl.formatMessage({id: 'AUTH.LOGIN.INVALID.CODE'})}
            </span>
          )}
          {errorMessage === 'Code expired' && (
            <span className='text-danger fs-4 mt-6' style={{textAlign: 'center'}}>
              {intl.formatMessage({id: 'AUTH.LOGIN.INVALID.CODE.EXPIRED'})}
            </span>
          )}
        </div>
        <div className='text-center LoginRegistration__btn mb-2 mt-14'>
          <button
              disabled={(phone.length < phoneLength || sendBtn ||  errorMessage !== 'empty') && true}
            className={`btn btn-lg btn-light-primary w-100 ${phone.length < phoneLength || sendBtn ? 'disabled secondary-button' : 'primary-button'}` }
            onClick={() => {
              setTimer(60)
              setTimerStart(true)
              setSendBtn(true)
              setShowCodeInput(false)
              authFunc()
            }}
          >
            {intl.formatMessage({id: 'BTNS.CONTINUE'})}
          </button>
        </div>
      </div>
    </div>
  )
}
