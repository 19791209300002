import React from "react";
import arrowLeft from "../../assets/ArrowLeft.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
interface CurrencySymbols {
    [key: string]: string;
}
export const PaymentInformation: React.FC<{  }> = props => {
    const navigate = useNavigate()
    const intl = useIntl()
    const location = useLocation()
    const state: any = location.state
    const parking = state.parking
    const currencySymbols: CurrencySymbols = {
        "KZT": '₸',
        "KGS": 'с',
        "UZS": 'soʻm',
        "USD": '$',
        "RUB": '₽',
        "EUR": '€'
    };
    function getCurrencySymbol(code: string) {
        return currencySymbols[code];
    }
    console.log("Parking: ", parking)
    return (
        <>
            <div style={{padding: '20px',height: '100%', display: 'flex', flexDirection: 'column'}}>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src={arrowLeft} style={{width:'15px', height:'21px',position: 'absolute', left: '10px'}}
                         onClick={() => navigate('/home-page', {state: {returnTo: 'History'}})}
                          />

                    <div className='d-flex  align-items-center ' style={{padding:'10px 0'}}>
                        <span className='back-header-title'>{intl.formatMessage({id: 'PAYMENT.INFO'})}</span>
                    </div>
                </div>

                <div className='d-flex flex-column justify-content-between mt-3'>
                    <label className='authorization_description text-start'>{intl.formatMessage({id: 'CAR.PLATE_NUMBER'})}</label>
                    <span className='authorization_title fs-1 fw-400'>{parking.plateNumber}</span>
                </div>
                <div className='d-flex flex-column justify-content-between mt-5'>
                    <label className='authorization_description text-start'>{intl.formatMessage({id: 'PARKING.NAME'})}</label>
                    <span className='authorization_title fs-1 fw-400'>{parking.parkingName}</span>
                </div>
                <div className='d-flex mt-5' style={{gap: '50px'}}>
                    <div className='d-flex flex-column'>
                        <label className='authorization_description text-start'>{intl.formatMessage({id: 'PARKING.PAYMENT_DATE'})}</label>
                        <span className='authorization_title fs-1 fw-400'>{new Date(parking.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '.')}
                        </span>
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='authorization_description text-start'>{intl.formatMessage({id: 'PARKING.PAYMENT_TIME'})}</label>
                        <span className='authorization_title fs-1 fw-400'>{new Date(parking.createdAt).toLocaleTimeString('en-US', { hour12: false }).slice(0, -3)}</span>
                    </div>
                </div>
                <div className='d-flex flex-column justify-content-between mt-5'>
                    <label className='authorization_description text-start'>{intl.formatMessage({id: 'PROFILE.PAYMENT-AMOUNT'})} </label>
                    <span className='authorization_title fs-1 fw-400'>{parking.amount} {getCurrencySymbol(parking.currency)}</span>
                </div>
                <div className='d-flex flex-column justify-content-end h-100'>
                    <button className='primary-button' onClick={() => navigate('home-page')}>
                        {intl.formatMessage({id: 'BTNS.HOME_PAGE'})}
                    </button>
                </div>
            </div>
        </>
    );
};