import {FC, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {onboardingAction} from '../../../setup/redux/actions/actions'

import firstSlide from '../../assets/onboarding/firstPage.png'
import secondSlide from '../../assets/onboarding/secondPage.png'
import thirdSlide from '../../assets/onboarding/thirdPage.png'

import {SwiperSlide, Swiper} from 'swiper/react'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/effect-flip/effect-flip.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

import {ImCross} from 'react-icons/im'
// import Swiper from 'swiper';

import SwiperCore, {Navigation, Pagination} from 'swiper'
import {SliderComponent} from './components/SliderComponent'
import {useNavigate, useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {Languages} from '../../modules/languages/Languages'
import {LastSlide} from './components/LastSlide'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../App'
import tags from '../../assets/eventTags'
import {isMobile} from "react-device-detect";
import './components/Onboarding.css'
import ReactDOM from "react-dom";
import {LanguagePickerModal} from "../../modules/modal/language-picker/LanguagePickerModal";
SwiperCore.use([Navigation, Pagination])

type Props = {};

const Onboarding: FC<Props> = () => {
  const dispatch = useDispatch()
  const {city, parkingUid} = useParams()
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeStep, setActiveStep] = useState(4)
  console.log("Params: ", city, parkingUid)
  console.log("That's onboarding page!")
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false);
    function toggleModal() {
        setShowModal(!showModal);
    }


  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(onboardingAction('true'))
  //   }, 5000)
      
    
  // }, [])
  useEffect(() => {
    console.log(activeStep)
  }, [activeStep]);

  return (
      <>
        {
          isMobile ?
              <div className='d-flex flex-column justify-content-between' style={{height: '100%', padding:' 0 10px'}}>
                <div className='d-flex justify-content-end'>
                  <Languages onLanguage={toggleModal} />
                </div>
                {showModal &&
                    ReactDOM.createPortal(
                        <LanguagePickerModal onToggle={toggleModal} />,
                        document.body
                    )}
                <div>
                  <Swiper
                      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                      style={{
                        maxHeight: '550px'
                      }}
                      pagination= {{
                        el: '.slider__pagination',
                        clickable: true,
                      }}
                      navigation={{
                    nextEl: '.slider__button-next',
                    prevEl: '.slider__button-prev',
                  }}>
                    <SwiperSlide  style={{overflowY: 'auto'}}>
                      <div
                          className='w-100 h-100 d-flex justify-content-center align-items-center flex-column'
                          style={{overflowY: 'auto'}}
                      >
                        <SliderComponent
                            imgSrc={firstSlide}
                            headerContent={intl.formatMessage({id: 'ONBOARDING.FIRST-SLIDE.HEADER'})}
                            textContent={intl.formatMessage({id: 'ONBOARDING.FIRST-SLIDE.CONTENT'})}
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide  style={{overflowY: 'auto'}}>
                      <div
                          className='w-100 h-100 d-flex justify-content-center align-items-center '
                          // style={{overflowY: 'auto',display: activeStep != 2 ? 'none' : 'flex'}}

                      >
                        <SliderComponent
                            imgSrc={secondSlide}
                            headerContent={intl.formatMessage({id: 'ONBOARDING.SECOND-SLIDE.HEADER'})}
                            textContent={intl.formatMessage({id: 'ONBOARDING.SECOND-SLIDE.CONTENT'})}
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide  style={{overflowY: 'auto'}}>
                      <div
                          className='w-100 h-100 d-flex justify-content-center align-items-center '
                          // style={{overflowY: 'auto', display: activeStep != 3 ? 'none' : 'flex'}}
                          style={{overflowY: 'auto', display: 'none'}}

                      >
                        <LastSlide
                            imgSrc={thirdSlide}
                            headerContent={intl.formatMessage({id: 'ONBOARDING.THIRD-SLIDE.HEADER'})}
                            textContent={intl.formatMessage({id: 'ONBOARDING.THIRD-SLIDE.CONTENT'})}

                            city={city}
                            parkingUid={parkingUid}
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className='d-flex justify-content-between' style={
                  {maxHeight: '30px', marginBottom:'20px',}
                }>
                      <span
                          style={{
                            color: 'rgba(18, 127, 238, 0.4)',
                            fontSize: '16px',
                            marginBottom: "36px",
                            marginLeft: "16px",
                          }}
                          onClick={() =>{
                            logEvent(analytics, tags.onboarding_skipped);
                            dispatch(onboardingAction({onboard: 'true', city: city, parkingUid: parkingUid}))
                            navigate('/add-city')
                            // city && parkingUid ? navigate("/auth", {state: {city: city, parkingUid: parkingUid}}) : navigate("/auth")

                          }}
                          className='cursor-pointer me-4 d-flex justify-content-center align-items-center'
                      >
                        {intl.formatMessage({id: 'AUTH.BTN.SKIP'})}
                      </span>


                    <div className="slider__controls">

                      <div className="slider__pagination"></div>

                      <div className={'slider__button-next'} onClick={() => {
                        console.log("Active index: ", activeIndex)
                        if(activeIndex === activeStep){
                          dispatch(onboardingAction({onboard: 'true', city: city, parkingUid: parkingUid}))
                          navigate('/add-city')
                        }
                        setActiveStep(activeIndex)

                      }}></div>
                      {/*<div className="slider__button-prev"></div>*/}
                    </div>
                  </div>
              </div>
          :
              <div
                  className='d-flex flex-column justify-content-between'
                  style={{height: '90vh', width: '99vw'}}
              >
                <div
                    className='w-100 h-50px d-flex ms-2 pt-4 d-flex justify-content-between align-items-center'
                    style={{zIndex: '2'}}
                >
                  <Languages onLanguage={toggleModal} />
                  <span
                      style={{
                        color: 'rgba(18, 127, 238, 0.4)',
                        fontSize: '16px',
                      }}
                      onClick={() =>{
                        logEvent(analytics, tags.onboarding_skipped);
                        dispatch(onboardingAction({onboard: 'true', city: city, parkingUid: parkingUid}))
                        city && parkingUid ? navigate("/auth", {state: {city: city, parkingUid: parkingUid}}) : navigate("/auth")

                      }}
                      className='skip-btn cursor-pointer me-4 d-flex justify-content-center align-items-center'
                  >
                    {intl.formatMessage({id: 'AUTH.BTN.SKIP'})}
                  </span>
                </div>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{clickable: true}}
                    className='w-100'
                    style={{height: '100%'}}
                >
                  <SwiperSlide className='w-100 h-100' style={{overflowY: 'auto'}}>
                    <div
                        className='w-100 h-100 d-flex justify-content-center align-items-center flex-column'
                        style={{overflowY: 'auto'}}
                    >
                      <SliderComponent
                          imgSrc={firstSlide}
                          headerContent={intl.formatMessage({id: 'ONBOARDING.FIRST-SLIDE.HEADER'})}
                          textContent={intl.formatMessage({id: 'ONBOARDING.FIRST-SLIDE.CONTENT'})}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className='w-100 h-100' style={{overflowY: 'auto'}}>
                    <div
                        className='w-100 h-100 d-flex justify-content-center align-items-center '
                        style={{overflowY: 'auto'}}
                    >
                      <SliderComponent
                          imgSrc={secondSlide}
                          headerContent={intl.formatMessage({id: 'ONBOARDING.SECOND-SLIDE.HEADER'})}
                          textContent={intl.formatMessage({id: 'ONBOARDING.SECOND-SLIDE.CONTENT'})}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className='w-100 h-100' style={{overflowY: 'auto'}}>
                    <div
                        className='w-100 h-100 d-flex justify-content-center align-items-center '
                        style={{overflowY: 'auto'}}
                    >
                      <LastSlide
                          imgSrc={thirdSlide}
                          headerContent={intl.formatMessage({id: 'ONBOARDING.THIRD-SLIDE.HEADER'})}
                          textContent={intl.formatMessage({id: 'ONBOARDING.THIRD-SLIDE.CONTENT'})}

                          city={city}
                          parkingUid={parkingUid}
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
        }
      </>

  )
}

export {Onboarding}
