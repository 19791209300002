import React from "react";

export const LoadingAnim: React.FC = () => {
    return (
        <>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
};