import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {App} from '../App'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {Privacy} from '../pages/privacy/Privacy'
import {Offer} from '../pages/offer/Offer'
import {AuthPage} from '../pages/authorization'
import {ChooseParkingForm} from '../modules/forms/choose-parking-form/ChooseParkingForm'
import {PaymentSuccessForm} from '../modules/forms/payment-methods-form/components/PaymentSuccessForm'
import {PaymentErrorForm} from '../modules/forms/payment-methods-form/components/PaymentErrorForm'
import {Onboarding} from '../pages/onboarding/Onboarding'
import {AbonementMobileKz} from '../pages/abonement-information-mobile/AbonementMobileKz'
import {AbonementMobileRu} from '../pages/abonement-information-mobile/AbonementMobileRu'
import {AbonementMobileEng} from '../pages/abonement-information-mobile/AbonementMobileEng'
import {ProfileAbonementList} from '../pages/profile/components/profile-abonement-list/ProfileAbonementList'
import {ProfilePage} from '../pages/profile/ProfilePage'
import { ProfilePaymentHistory } from '../pages/profile/components/profile-payment-history/ProfilePaymentHistory'
import {AddCity} from "../pages/add-city/AddCity";
import {AddCar} from "../pages/add-car/AddCar";
import {SearchParking} from "../pages/search-parking/SearchParking";
import {ParkingInfo} from "../pages/parking-info/ParkingInfo";
import {Filter} from "../modules/mapbox/Filter";
import ParkingPayment from "../pages/payment/ParkingPayment";
import {Cards} from "../pages/payment/components/Cards";
import {MyCars} from "../pages/my-cars/MyCars";
import {EditCar} from "../pages/my-cars/EditCar";
import {PaymentCoupon} from "../pages/payment-coupon/PaymentCoupon";
import {ShowCityList} from "../pages/add-city/components/ShowCityList";
import {PaymentInformation} from "../pages/payment/PaymentInformation";
import {ProfileEdit} from "../pages/profile/ProfileEdit";
import {SaveCardSuccessForm} from "../modules/forms/payment-methods-form/components/SaveCardSuccessForm";
import {SaveCardErrorForm} from "../modules/forms/payment-methods-form/components/SaveCardErrorForm";
import {isMobile} from "react-device-detect";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const loggedIn = useSelector((state: RootState) => state.token)
  const onboardingShow = useSelector((state: RootState) => state.onboarding)
  console.log("Onboard: ", onboardingShow)
  const storeCity = useSelector((state: RootState) => state.city)
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='privacy' element={<Privacy />} />
          <Route path='offer' element={<Offer />} />
          <Route path='payment-success' element={<PaymentSuccessForm />} />
          <Route path='payment-error' element={<PaymentErrorForm />} />
          <Route path='saveCard-success' element={<SaveCardSuccessForm />} />
          <Route path='saveCard-error' element={<SaveCardErrorForm />} />

          <Route path={`abonement-information/kz`} element={<AbonementMobileKz />} />
          <Route path={`abonement-information/cis`} element={<AbonementMobileRu />} />
          <Route path={`abonement-information/eng`} element={<AbonementMobileEng />} />
          {onboardingShow ? (
            <>
               <Route path='boarding' element={<Onboarding />} />
              {loggedIn ? (
                <>
                {
                  onboardingShow.city ? (
                    <Route path="qr/:city/:parkingUid" element={<ChooseParkingForm qr={true} />} />
                  ) : (
                    <>
                      <Route path='/*' element={<Navigate to='/home-page/' />} />
                      <Route path='auth/*' element={<Navigate to='/home-page/' />} />
                      <Route path='add-city/' element={<AddCity />} />
                      <Route path='add-car/' element={<AddCar />} />
                      <Route path='search/' element={<SearchParking />} />
                      <Route path='parking-info/' element={<ParkingInfo />} />
                      <Route path='parking-filter/' element={<Filter />} />
                      <Route path='parking-payment/' element={<ParkingPayment />} />
                      <Route path='parking-payment/:return' element={<ParkingPayment />} />
                      <Route path='payment-card/' element={<Cards />} />
                      <Route path='my-cars/' element={<MyCars />} />
                      <Route path='edit-car/' element={<EditCar />} />
                      <Route path='payment/coupon' element={<PaymentCoupon />} />
                      <Route path='profile/cityList' element={<ShowCityList setPrevPage={''} /> } />
                      <Route path='payment/info' element={<PaymentInformation />} />
                      <Route path='profile/edit' element={<ProfileEdit />} />
                    </>
                  )
                }
                  {/* <Route path='/*' element={<Navigate to='/home-page/' />} />
                  <Route path='auth/*' element={<Navigate to='/home-page/' />} /> */}
                  
                  <Route element={<MasterLayout />}>
                    <Route path='/profile/parking-passes' element={<ProfileAbonementList />} />
                    <Route path='/profile/payment-history' element={<ProfilePaymentHistory />} />
                    <Route path='/profile' element={<ProfilePage pages={{}} setSelectedPage={{}} />} />
                  </Route>
                </>
              ) : (
                <>
                  <Route path='/*' element={<Navigate to='/auth' />} />
                  <Route path='/auth' element={<AuthPage form={'authPage'} />} />
                </>
              )}
              <Route element={<MasterLayout />}>
               
                <Route path="qr/:city/:parkingUid" element={<ChooseParkingForm qr={true} />} />
                <Route path="home-page" element={<ChooseParkingForm qr={false} />} />
                <Route path="home-page:Bishkek" element={<ChooseParkingForm cityId={"7"} currentCity={"Бишкек"} lat={42.874622} lon={74.569763} />} />
                
              </Route>
            </>
          ) : (
            <>
              {['qr/:city/:parkingUid', '/*'].map(path => <Route path={path} element={<AuthPage />} />)}
              <Route path='boarding/*' element={isMobile ? <Onboarding /> : <ChooseParkingForm qr={false} />} />

              <Route path="home-page:Bishkek" element={<ChooseParkingForm cityId={"7"} currentCity={"Бишкек"} lat={42.874622} lon={74.569763} />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
