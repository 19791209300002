let parkingInfo = localStorage.getItem('parkingInfo');
const defaultState = parkingInfo ? JSON.parse(parkingInfo) : {};

const storeParkingInfoReducer = (
    state = defaultState,
    action: any
) => {
    const {type, payload} = action
    switch (type) {
        case 'SET_PARKING_INFO':
            localStorage.setItem('parkingInfo', JSON.stringify(payload))
            return payload
        default:
            return state
    }
}

export {storeParkingInfoReducer}