import axios from "axios"
import {
    editUser,
    paymentHistory,
    payzeCardSave, userAddCar,
    userCardDelete,
    userCarDelete,
    userCards,
    userCardSave,
    userCars,
    userDelete,
    userInfo,
    userLanguageEdit, userPayzeCardDelete
} from "../endpoints"
import customAxios from "./CustomAxios"

const apiBaseUrl = process.env.REACT_APP_API_PARQOUR


export const axiosUserInfo = () => {
    let config: any = {
        method: 'get',
        url: userInfo,
    }

    return customAxios(config).then((resp) => {
        return {code: resp.status, message: resp.data}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}
export const axiosEditUser = (name: string, email: string) => {
    let config: any = {
        method: 'post',
        url: editUser,
        data: {
            name, email
        }
    }
    return customAxios(config).then((resp) => {
        return {code: resp.status, message: resp.data}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}
export const axiosUserCars = () => {
    let config: any = {
        method: 'get',
        url: userCars,
    }

    return customAxios(config).then((resp) => {
        return {code: resp.status, message: resp.data}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}
export const axiosUserCarAdd = (plateNumber: string, carName: string, defaultCar: boolean, carId: number) => {
    return axios.post(apiBaseUrl + userAddCar, {
        plateNumber: plateNumber,
        carName: carName,
        defaultCar: defaultCar,
        carId: carId
    }, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
    }).then((resp) => {
        return {code: resp.status, message: resp.data.message}

    }).catch((err) => {
        return {code: err.response.status, message: 'error'}

    })
}
export const axiosUserCards = () => {
    let config:any = {
        method: 'get',
        url: userCards,
      }

    return customAxios(config).then((resp) => {
        return {code: resp.status, message: resp.data}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}

export const axiosUserCardSave = (userId:any,successReturnUrl:string, errorReturnUrl:string, payze:boolean) => {
    const payzeData:any = {
        userId,
        successUrl: successReturnUrl,
        errorUrl: errorReturnUrl
    }
    const data:any = {
        successReturnUrl,
        errorReturnUrl
    }

    let config:any = {
        method: 'post',
        url: !payze ? userCardSave : payzeCardSave,
        data: payze ? payzeData : data
    }

    if(!payze){
        return customAxios(config).then((resp) => {
            return {code: resp.status, message: resp.data.message}
        }).catch((err) => {
            return {code: err.response.status, message: 'error'}
        })
    }else{
        return axios.post(apiBaseUrl + '/uz/payze/saveCard', payzeData, {
            // headers: {
            //     'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            // }
        }).then((resp) => {
            return {code: resp.status, message: resp.data.message}

        }).catch((err) => {
            return {code: err.response.status, message: 'error'}

        })
    }
    
}

export const axiosUserCardDelete = (cardId: string) => {
    
    let data:any = {
        cardId
    }
    
    let config:any = {
        method: 'post',
        url: userCardDelete,
        data
    }

    return customAxios(config).then((resp) => {
        return {code: resp.status, message: resp.data}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}
export const axiosUserPayzeCardDelete = (cardId: string) => {

    let data:any = {
        cardId
    }

    let config:any = {
        method: 'post',
        url: userPayzeCardDelete,
        data
    }

    return customAxios(config).then((resp) => {
        return {code: resp.status, message: resp.data}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}

export const axiosUserLaguageEdit = (locale:string) => {
    let data:any = {
        locale
    }

    let config:any = {
        method: 'post',
        url: userLanguageEdit,
        data
    }

    return customAxios(config).then((resp) => {
        return {code: resp.status, message: resp.data}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}

export const axiosUserDelete = () => {
    let config:any = {
        method: 'post',
        url: userDelete
    }

    return customAxios(config).then((resp) => {
        return {code: resp.status, message: resp.data.message}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}

export const axiosUserCarDelete = (plateNumber:string) => {

    let config:any = {
        method: 'post',
        url: userCarDelete + plateNumber,
    }

    return customAxios(config).then((resp) => {
        return {code: resp.status, message: resp.data.message}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}

export const axiosPaymentHistory = (page: number) => {
    let config:any = {
        method: 'get',
        url: `${paymentHistory}?page=${page}`,
    }

    return customAxios(config).then((resp) => {
        return {code: resp.status, message: resp.data}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}